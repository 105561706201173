import { Log, Size, getId, Color } from '@stellacontrol/utilities'
import Konva from 'konva'
import { Layer } from './layer'
import { TransparencyFilter } from '../utilities'

/**
 * Image shape cache, for quick reloading of images
 * as user switches between floors
 */
const ImageCache = {

}

/**
 * Background layer renderer
 */
export class BackgroundLayer extends Layer {
  constructor (data) {
    super(data)
  }

  /**
   * Grid shape
   * @type {Konva.Group}
   */
  grid

  /**
   * Background image
   * @type {Konva.Image}
   */
  imageShape

  /**
   * Original image size, before any zoom applied
   * @type {Size}
   */
  imageSize

  /**
   * Hash of the recently loaded background image
   * @param {String}
   */
  imageHash

  /**
   * Creates own shapes of the layer
   * @return {Promise<Array[Shape|Konva.Shape]>}
   */
  async createOwnShapes () {
    const { content } = this

    // Background layer doesn't trigger events
    content.listening(false)

    const imageShape = await this.createImageShape()
    const gridShape = await this.createGridShape()

    const shapes = [
      imageShape,
      gridShape
    ].filter(s => s != null)


    return shapes
  }

  /**
   * Creates the image shape
   * @return {Promise<Konva.Shape>}
   */
  async createImageShape () {
    const { floor } = this
    if (!floor.background) return


    const { background, background: { image, imagePosition, imageScale, imageRotation } } = floor

    // Reuse cached image shape if unchanged
    if (image?.id) {
      const imageShape = ImageCache[image.id]
      if (imageShape) {
        imageShape.remove()
        this.imageShape = imageShape
        return Promise.resolve(imageShape)
      }
    }

    this.destroy(this.imageShape)
    this.imageShape = null

    if (image) {
      const imageUrl = image.downloadUrl || image.dataUrl || (image.external ? image.reference : null)
      if (imageUrl) {
        return new Promise(resolve => {

          const { floor } = this
          floor.loadImage()

          const imageFailed = error => {
            Log.error('Floor image could not be loaded', imageUrl)
            Log.exception(error)
            floor.loadImage(false)
            this.destroy(this.imageShape)
            this.imageShape = null
            background.image = null
            resolve(null)
          }

          const imageLoaded = async img => {
            this.imageShape = img

            floor.loadImage(false)
            img.draggable(false)
            img.listening(false)

            // Apply transparency filters.
            // Once the image is saved, transparency will be persisted,
            // and transparent colors will be removed from background settings.
            if (floor.background.hasTransparentColors) {
              img.filters([TransparencyFilter({
                tolerance: 15,
                colors: floor.background.transparentColors
              })])
              Log.debug('Image transparency applied', floor.background.transparentColors.map(c => Color.toHex(c)))
            }

            // Apply image scale
            if (imageScale) {
              img.scale({ x: imageScale, y: imageScale })
            }

            // Apply image rotation
            // We only allow rotations by multiplies of 90, no arbitrary rotations
            // as this would require the user to be able to edit the offset - too complicated
            if (imageRotation) {
              if (imageRotation === 90) {
                img.offsetY(img.height())
                img.offsetX(0)
                img.rotation(90)

              } else if (imageRotation === 180) {
                img.offsetX(img.width())
                img.offsetY(img.height())
                img.rotation(180)

              } else if (imageRotation === 270) {
                img.offsetX(img.width())
                img.offsetY(0)
                img.rotation(270)
              }
            }

            // Render
            img.visible()
            this.renderShape(img)

            // Adjust background size to the size of the loaded image,
            // take into consideration the rotation
            const isOnTheSide = imageRotation === 90 || imageRotation === 270
            const { width, height } = img.size()
            const size = isOnTheSide
              ? new Size({ width: height, height: width })
              : new Size({ width, height })
            this.imageSize = Size.from(size)
            this.floor.background.imageSize = this.imageSize

            // Shift the image to accomodate for margins
            imagePosition.moveTo({
              x: floor.margin.left,
              y: floor.margin.top
            })
            img.x(imagePosition.x)
            img.y(imagePosition.y)

            // Adjust stage size to that of the image
            const stageSize = Size.from(this.imageSize)
            floor.setSize(stageSize)
            this.stage.size(floor.canvasDimensions)

            // Memoize the image and cache in Konva renderer
            ImageCache[image.id] = img
            img.cache(true)

            resolve(img)
          }

          const url = new URL(imageUrl)
          if (image.downloadUrl) {
            url.searchParams.append('no-cache', getId())
          }
          Konva.Image.fromURL(url.toString(), imageLoaded, imageFailed)
        })
      } else {
        // Invalid image, remove from the floor
        background.clearImage()
      }
    }

    return Promise.resolve()
  }

  /**
   * Creates the grid shape
   * @return {Promise<Konva.Shape>}
   */
  async createGridShape () {
    const { floor } = this
    if (!floor.background) return

    this.destroy(this.gridShape)
    this.gridShape = null

    const { layout: { gridSize, showGrid }, floor: { dimensions, margin } } = this

    // Calculate the area over which the grid spans - image size plus margin
    const size = Size
      .from(dimensions)
      .growBy(margin)

    // Grid lines
    const gridShape = new Konva.Group({
      draggable: false,
      listening: false
    })
    const stroke = '#f2f2f2'
    const strokeWidth = 1

    let x = gridSize
    while (x < size.width) {
      const points = [x, 0, x, size.height]
      const line = new Konva.Line({
        points,
        stroke,
        strokeWidth,
        draggable: false,
        listening: false
      })
      gridShape.add(line)
      x += gridSize
    }

    let y = gridSize
    while (y < size.height) {
      const points = [0, y, size.width, y]
      const line = new Konva.Line({
        points,
        stroke,
        strokeWidth,
        draggable: false,
        listening: false
      })
      gridShape.add(line)
      y += gridSize
    }

    gridShape.visible(showGrid)

    this.gridShape = gridShape

    return gridShape
  }

  /**
   * Clears the layer
   */
  clear () {
    super.clear()
    this.gridShape = null
    this.imageShape = null
    this.clearImageCache()
  }

  /**
   * Clears the cached images
   */
  clearImageCache () {
    for (const [key, image] of Object.entries(ImageCache)) {
      image.destroy()
      delete ImageCache[key]
    }
  }

  /**
   * Removes the current image of the background or the specified image from the image cache
   * @param {Attachment} image Image to remove, optional
   */
  clearCachedImage (image) {
    image = image || this.floor?.background?.image
    if (image?.id) {
      delete ImageCache[image.id]
    }
  }

  /**
   * Redraws the layer
   */
  async refresh () {
    await super.refresh()
    await this.refreshGrid()
    await this.refreshImage()
  }

  /**
   * Assigns correct z-order to shapes on the layer
   * @returns {Layer} Self-reference if reordering was performed
   */
  reorder () {
    if (super.reorder()) {
      const { content, gridShape } = this

      // Reordering is not possible when layer is still not at the stage
      if (content.getParent() && gridShape) {
        gridShape.moveToBottom()
      }
      return this
    }
  }

  /**
   * Refreshes the grid
   * @returns {Promise}
   */
  async refreshGrid () {
    this.gridShape = await this.createGridShape()
    this.ownShapes = [
      this.imageShape,
      this.gridShape
    ].filter(s => s != null)

    const { gridShape, layout: { showGrid } } = this

    if (gridShape) {
      this.renderShape(gridShape, true)
      gridShape.visible(showGrid)
      gridShape.cache()
      this.reorder()
    }
  }

  /**
   * Refreshes the background image
   * @returns {Promise}
   */
  async refreshImage () {
    this.clearCachedImage()
    this.imageShape = await this.createImageShape()
    this.ownShapes = [
      this.imageShape,
      this.gridShape
    ].filter(s => s != null)

    await this.refreshGrid()
  }

  /**
   * Returns the background image data URL, after applying transparency and other filters
   * @param {Object} options Export options, as per https://konvajs.org/api/Konva.Image.html#toDataURL__anchor
   * @returns {Promise<String>}
   */
  async getImageData ({
    x,
    y,
    width,
    height,
    mimeType = 'image/png',
    quality = 1,
    pixelRatio = 1,
    imageSmoothingEnabled = false
  } = {}) {
    const { imageShape } = this

    if (imageShape?.visible()) {
      const image = await imageShape.toDataURL({
        x: x || imageShape.x(),
        y: y || imageShape.y(),
        width: width || imageShape.width(),
        height: height || imageShape.height(),
        mimeType,
        quality,
        pixelRatio,
        imageSmoothingEnabled
      })

      return image
    }
  }
}
