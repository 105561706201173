import { distinctValues } from '@stellacontrol/utilities'
import { Place, getPlaceLabel } from '@stellacontrol/model'

export const actions = {
  /**
   * Initializes the buildings view
   */
  async initializeInstallationsView ({ commit, dispatch, getters }) {
    // Restore pinned buildings,
    // Clean up non-existent buildings from pinned
    const { allPlaces } = getters
    const pinnedList = await dispatch('getUserPreference', { name: 'buildings-pinned', defaultValue: '' })
    const pinned = pinnedList
      .split(',')
      .filter(id => allPlaces.find(p => p.id === id))
    commit('pinFavouriteBuildings', { pinned })

    // Restore other user preferences
    const filter = await dispatch('getUserPreference', { name: 'buildings-filter' })
    const exact = await dispatch('getUserPreference', { name: 'buildings-filter-exact' })
    const sortBy = await dispatch('getUserPreference', { name: 'buildings-sort-by', defaultValue: 'name' })
    const sortDescending = await dispatch('getUserPreference', { name: 'buildings-sort-descending', defaultValue: false })

    commit('filterBuildings', { filter, exact })
    commit('sortBuildings', { sortBy, sortDescending })

    // Populate the buildings
    await dispatch('loadBuildings')
  },

  /**
   * Populates the list of buildings
   */
  async loadBuildings ({ commit, getters, rootState }) {
    // Create the list of places to show
    const { organizations, organizationHierarchy, currentOrganization, users, guardian } = getters
    const places = rootState.places.items
    const devices = getters.devices.filter(d => !d.isDecommissioned)
    const canManageChildOrganizations = guardian.canUse('child-organizations')

    // Returns the ownership organizations for the device
    const ownership = organizationId => {
      return organizationId === currentOrganization.id
        ? [currentOrganization]
        : [
          ...organizationHierarchy.getParentsOf(organizationId).reverse().slice(1),
          organizations.find(o => o.id === organizationId)
        ]
    }

    // Collect organization details
    let superOrganization
    const organizationDetails = {}
    for (const organization of organizations) {
      if (!organization.parentOrganizationId) superOrganization = organization
      organizationDetails[organization.id] = {
        id: organization.id,
        name: organization.name,
        path: ownership(organization.id).map(o => o.name).join(' > ')
      }
    }

    // Collect user details
    const userDetails = {}
    for (const userId of distinctValues(places, 'updatedBy')) {
      userDetails[userId] = users.find(user => user.id === userId)
    }

    // Group devices by place / stock
    const placeDevices = {}
    const stockDevices = {}
    for (const device of devices) {
      if (device.placeId) {
        if (!placeDevices[device.placeId]) placeDevices[device.placeId] = []
        placeDevices[device.placeId].push(device.serialNumber)
      } else {
        const ownerId = device.ownerId || superOrganization?.id
        if (!stockDevices[ownerId]) stockDevices[ownerId] = []
        stockDevices[ownerId].push(device.serialNumber)
      }
    }

    // Create virtual places representing stock devices for each organization
    // Don't show stocks of child organizations unless there's a permission granted!
    const stockPlaces = Object
      .entries(stockDevices)
      .filter(([organizationId]) =>
        canManageChildOrganizations
          ? true
          : organizationId === currentOrganization.id)
      .map(([organizationId, devices = []]) => {
        const organization = organizationDetails[organizationId]
        const organizationName = organization.name
        const organizationPath = organization.path
        const isMyBuilding = organizationId === currentOrganization.id
        return {
          id: organizationId,
          name: Place.NAME_NOPLACE,
          isStock: true,
          isMyBuilding,
          organizationId,
          organizationName,
          organizationPath,
          devices,
          deviceCount: devices.length,
          hasPlan: false,
          hasNotes: false
        }
      })

    // Create real places.
    // Don't show places of child organizations unless there's a permission granted!
    const realPlaces = places
      .filter(place => canManageChildOrganizations
        ? true
        : place.organizationId === currentOrganization.id)
      .map(place => {
        const { id, organizationId, name, hasAttachments, hasNotes, planId } = place
        const organization = organizationDetails[organizationId]
        const organizationName = organization.name
        const organizationPath = organization.path
        const isMyBuilding = place.organizationId === currentOrganization.id
        const devices = placeDevices[id] || []
        const hasPlan = planId != null

        // Show place update time or plan update time, whichever is newer
        const updatedAt = place.planUpdatedAt
          ? (place.updatedAt > place.planUpdatedAt ? place.updatedAt : place.planUpdatedAt)
          : place.updatedAt
        // Show place updater time or plan updater, whichever was updated more recently
        const updatedById = place.planUpdatedAt
          ? (place.updatedAt > place.planUpdatedAt ? place.updatedBy : place.planUpdatedBy)
          : place.updatedABy
        const updatedBy = userDetails[updatedById]?.name

        return {
          id,
          name,
          isStock: false,
          isMyBuilding,
          organizationId,
          organizationName,
          organizationPath,
          devices,
          deviceCount: devices.length,
          updatedBy,
          updatedAt,
          hasNotes: hasAttachments || hasNotes,
          hasPlan
        }
      })

    const dashboardPlaces = [
      ...stockPlaces,
      ...realPlaces
    ]

    commit('loadBuildings', { places: dashboardPlaces })
    commit('sortBuildings')
  },

  /**
   * Filters the places view by the specified conditions
   * @param {String} filter Free-text filter
   * @param {Boolean} exact If true, `equals` string comparisons are performed, otherwise `includes`
   */
  async filterBuildings ({ commit, dispatch }, { filter, exact = false } = {}) {
    filter = filter?.trim()
    commit('filterBuildings', { filter, exact })
    await dispatch('storeUserPreference', { name: 'buildings-filter', value: filter, exact })
    await dispatch('storeUserPreference', { name: 'buildings-filter-exact', value: exact })
  },

  /**
   * Sorts the buildings by the specified field and order
   * @param {String} sortBy Field to sort by
   * @param {Boolean} sortDescending Descending sort order
   */
  async sortBuildings ({ commit, dispatch, state }, { sortBy, sortDescending } = {}) {
    commit('sortBuildings', { sortBy, sortDescending })
    await dispatch('storeUserPreferences', {
      items: [
        { name: 'buildings-sort-by', value: state.sortBy },
        { name: 'buildings-sort-descending', value: state.sortDescending }
      ]
    })
  },

  /**
   * Pins or unpins the specified place
   * @param {String} placeId Place to pin/unpin
   * @param {Boolean} pin If specified, defines whether the place should be pinned.
   * If not specified, the current `pinned` state of the place is toggled
  */
  async pinFavouriteBuilding ({ commit, dispatch, state }, { id, pin }) {
    commit('pinFavouriteBuilding', { id, pin })
    commit('sortBuildings')
    await dispatch('storeUserPreference', { name: 'buildings-pinned', value: state.pinnedBuildings.join(',') })
  },

  /**
   * Shows dialog for adding notes and documents to place
   * @param {Place} place Place to show notes for
   */
  async showPlaceNotes ({ dispatch, getters }, { place } = {}) {
    if (!place) throw new Error('Place is required')

    const { name, placeType } = place
    const title = `Documents associated with ${getPlaceLabel(placeType)} ${name}`

    const { isOk, data } = await dispatch('showDialog', {
      dialog: 'document-upload',
      data: {
        title,
        okLabel: 'Save',
        selectFilesLabel: 'Add documents ...',
        notes: place.note || '',
        documents: [...place.ownAttachments],
        allowMultiple: true,
        maxFiles: 100
      }
    })

    const { notes, newDocuments, removedDocuments } = data || {}
    const storingDocuments = newDocuments?.length > 0 || removedDocuments?.length > 0

    if (isOk) {
      await dispatch('busy', { message: storingDocuments ? `Storing documents of ${place.name} ...` : `Saving notes of ${place.name} ...` })
      place.note = notes
      place.attachments = place.attachments || []
      await dispatch('savePlaceNotes', { place })

      try {
        if (newDocuments?.length) {
          const saved = await dispatch('saveAttachments', {
            entity: place,
            owner: getters.organizations.find(o => o.id === place.organizationId),
            attachments: newDocuments,
            silent: true
          })
          place.attachments = [
            ...place.attachments,
            ...saved
          ]
        }

        if (removedDocuments?.length) {
          await dispatch('deleteAttachments', { attachments: removedDocuments, silent: true, confirm: false })
          place.attachments = place.attachments.filter(a => !removedDocuments.find(r => r.id === a.id))
        }

        await dispatch('storePlace', { place })
        await dispatch('done', { message: storingDocuments ? 'Documents stored' : 'Notes saved' })

      } catch (error) {
        await dispatch('done', { error: error.message })
      }

    }
  },

  /**
   * Stores the last opened route under buildings menu
   * @param {Route} route Last opened route
   */
  async storeBuildingsRoute ({ commit }, { route }) {
    if (route && !route.query.restore) {
      const { name, params, query } = route
      commit('storeBuildingsRoute', {
        route: { name, params, query }
      })
    }
  },

  /**
   * Returns the last opened route under buildings menu
   * @returns {Route}
   */
  async getLastBuildingsRoute ({ state }) {
    return state.lastRoute
  },
}
