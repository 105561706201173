import Konva from 'konva'
import { Log, sortItems, getNearbyPoint, Point, Rectangle, Size, Color, throttle, debounce, delay, getId } from '@stellacontrol/utilities'
import { Confirmation, Notification } from '@stellacontrol/client-utilities'
import { PlanCable, PlanLayers, PlanState, PlanEditMode, PlanScale, PlanLineStyle, PlanLineType, PlanFloors, getPlanItemTooltip, planItemToEditMode } from '@stellacontrol/planner'
import { createLayer } from './layers'
import { PlanActions, executePlanAction } from './actions'
import { PlanEvent, KeyboardEvents, MouseEvents, PlanEventEmitter } from './events'
import { ContextMenu } from './menus'
import { moveToTop, moveToBottom, PlanActionHistory } from './utilities'

/**
 * Plan renderer
 */
export class PlanRenderer {
  static counter = 0

  /**
   * Creates a new plan renderer
   * @param {PlanLayout} layout Layout to start with
   * @param {String|Element} container Container selector or HTML element
   * @param {PlanFloor} floor Floor to show. If specified, the plan view for the specified floor is shown
   * @param {Boolean} debug Start the renderer in debug mode
   * @param {Function} onData Callback for fetching additional data for the specified plan item
   * @param {Function} onSave Callback for saving of the plan layout
   * @param {Function} onSaveImage Callback for saving of floor images
   * @param {Function} onSnapshotSave Callback for saving plan snapshots
   * @param {Function} onSnapshotDelete Callback for deleting plan snapshots
   * @param {Function} onSnapshotRestore Callback for restoring plan snapshots
   * @param {Function} onChanged Callback for receiving notifications about changes to the plan layout
   * @param {Function} onSelectFloor Callback for requesting navigation to another floor
   * @param {Function} onSelectCrossSection Callback for requesting navigation to cross-section
   * @param {Number} changeInterval Interval in milliseconds for notifications about changes to the layout.
   * This is used to trigger auto-save, so we don't want to do it while the user keeps clicking around.
   * Instead, we notify about changes only after the specified period of inactivity.
   */
  constructor ({
    layout,
    container,
    floor,
    debug,
    onData,
    onSave,
    onSaveImage,
    onSnapshotSave,
    onSnapshotDelete,
    onSnapshotRestore,
    onChanged,
    onSelectFloor,
    onSelectCrossSection,
    changeInterval = 5000
  } = {}) {
    if (!layout) throw new Error('Plan layout is required')
    if (!container) throw new Error('Container is required')
    if (!onData) throw new Error('Data callback required')

    const containerElement = (typeof container === 'string')
      ? document.querySelector(container)
      : container
    if (!containerElement) throw new Error(`Plan container [${container.toString()}] not found`)

    this.__uuid = getId()

    this.layout = layout
    this.container = containerElement
    this.base = null
    this.floor = floor
    this.onData = onData
    this.onSave = onSave
    this.onSaveImage = onSaveImage
    this.onSnapshotSave = onSnapshotSave
    this.onSnapshotDelete = onSnapshotDelete
    this.onSnapshotRestore = onSnapshotRestore
    this.onSelectFloor = onSelectFloor
    this.onSelectCrossSection = onSelectCrossSection
    this.onChanged = debounce(onChanged, changeInterval)
    this.layers = []
    this.isRendering = false
    this.isRendered = false
    this.isMovingItems = false
    this.keyboardEvents = new KeyboardEvents(this)
    this.mouseEvents = new MouseEvents(this)
    this.events = new PlanEventEmitter()
    this.history = new PlanActionHistory()

    this.itemsMenu = new ContextMenu()
    this.isDrawingMapScale = false
    this.mapScalePoints = 0

    if (this.isCrossSection) {
      // If on cross-section view, mark the main floor as selected
      layout.selectFloor(layout.getMainFloor())
    } else {
      // Otherwise mark the currently viewed floor as selected
      layout.selectFloor(floor)
    }

    // Detailed debugging?
    this.debug(Boolean(debug))
    Log.debug(`Renderer [${this.__uuid}] ready`)
    if (floor) {
      this.log('Edit floor', { floor: floor.id })
    } else {
      this.log('Edit cross-section', { renderer: this.__uuid })
    }
  }

  __uuid
  __debug
  __isFocused = false
  __position = Point.Zero
  __eventsBound = false
  __lastClick = null
  __draggingFrom = null
  __pointedItem = null
  __selectionBox = null
  __selectedPort = null
  __selectedPoint = null
  __selectedPosition = null
  __recentItemPosition = null
  __transformedItem = null
  __isCreatingPoint = false
  __pointCreated = null
  __helpVisible = false
  __confirmationHandler = null
  __isAddingItem = null
  __itemToAdd = null
  __addedItem = null
  __markers = []
  __distanceLine = null
  __distanceLabels = []
  __changeNotifications = 0

  /**
   * Instance identifier
   * @type {String}
   */
  get uuid () {
    return this.__uuid
  }

  /**
   * Indicates that we're currently in DEBUG mode
   * @type {Boolean}
   */
  get isDebugging () {
    return Boolean(this.__debug)
  }

  /**
   * Logs a message
   * @param {String} message Message to log
   * @param {any} data Additional data to log
   */
  log (message, data) {
    if (this.isDebugging) {
      Log.debug(`[Renderer] ${message}`, data ? JSON.parse(JSON.stringify(data)) : undefined)
    }
  }

  /**
   * Logs a conditional message
   * @param {Boolean} condition Condition to check
   * @param {String} yesMessage Message to log, when condition is true
   * @param {String} noMessage Message to log, when condition is false
   * @param {any} yesData Additional data to log, when condition is true
   * @param {any} noData Additional data to log, when condition is false
   */
  logIf (condition, yesMessage, noMessage, yesData, noData) {
    if (this.isDebugging) {
      const message = condition ? yesMessage : noMessage
      const data = condition ? yesData : noData
      Log.debug(`[Renderer] ${message}`, data ? JSON.parse(JSON.stringify(data)) : undefined)
    }
  }

  /**
   * Logs stack trace with a message
   * @param {String} message Message to log
   * @param {any} data Data to log
  */
  trace (message, data) {
    if (this.isDebugging) {
      Log.trace(`[Renderer] ${message}`, data)
    }
  }

  /**
   * Logs stack trace with a conditional message
   * @param {Boolean} condition Condition to check
   * @param {String} yesMessage Message to log, when condition is true
   * @param {String} noMessage Message to log, when condition is false
   * @param {any} yesData Additional data to log, when condition is true
   * @param {any} noData Additional data to log, when condition is false
   */
  traceIf (condition, yesMessage, noMessage, yesData, noData) {
    if (this.isDebugging) {
      const message = condition ? yesMessage : noMessage
      const data = condition ? yesData : noData
      Log.trace(`[Renderer] ${message}`, data)
    }
  }

  /**
   * Turns detailed debugging on/off
   * @param {Boolean} status Debugging status
   * @returns {Boolean} The current status of debugging
   */
  debug (status = true) {
    if (Boolean(status) !== this.__debug) {
      Log.info('Debugging', status ? 'ON' : 'OFF')
      this.__debug = Boolean(status)
      if (this.__debug) {
        Log.debug(this.layout.toString())
      }
    }
    return this.__debug
  }

  /**
   * Should be called before the class is destroyed
   */
  unmount () {
    // Unlink event handlers
    this.events.destroy()
    this.keyboardEvents.unmount()
    this.mouseEvents.unmount()

    // Remove the cached shape reference
    for (const item of this.items) {
      item.shape = null
    }
  }

  /**
   * Event emitter
   * @type {PlanEventEmitter}
   */
  events

  /**
   * Handler for keyboard events
   * @type {KeyboardEvents}
   */
  keyboardEvents

  /**
   * Handler for mouse events
   * @type {MouseEvents}
   */
  mouseEvents

  /**
   * Callback for fetching additional data for the specified plan item
   * @type {Function<PlanItem, any,any>}
   */
  onData

  /**
   * Callback for saving of the plan layout
   * @type {Function}
   */
  onSave

  /**
   * Callback for saving of floor images
   * @type {Function>}
   */
  onSaveImage

  /**
   * Callback for saving plan snapshots
   * @type {Function>}
   */
  onSnapshotSave

  /**
   * Callback for deleting plan snapshots
   * @type {Function>}
   */
  onSnapshotDelete

  /**
   * Callback for restoring plan snapshots
   * @type {Function>}
   */
  onSnapshotRestore

  /**
   * Callback for requesting navigation to another floor
   * @type {Function}
   */
  onSelectFloor

  /**
   * Callback for requesting navigation to cross-section view
   * @type {Function}
   */
  onSelectCrossSection

  /**
   * Callback for receiving notifications about changes to the plan layout
   * which usually results in saving
   * @type {Function<{action:PlanAction},any>}
   */
  onChanged

  /**
   * Current state of the plan renderer
   */
  get state () {
    if (this.isRendering) return PlanState.Rendering
    if (this.layout?.isSaving) return PlanState.Saving
    if (this.floor?.isLoadingImage) return PlanState.LoadingImage

    return this.isRendered
      ? PlanState.Editing
      : PlanState.Initializing
  }

  /**
   * Label representing the current state of the plan
   * @type {String}
   */
  get stateLabel () {
    const { state } = this
    switch (state) {
      case PlanState.Initializing:
        return 'Loading ...'
      case PlanState.Saving:
        return 'Saving ...'
      case PlanState.LoadingImage:
        return 'Loading floor image ...'
    }
    return ''
  }

  /**
   * Allow/disallow dragging of shapes on the plan
   * @param {Boolean} on If `true`, dragging is allowed
   */
  allowDragging (on = true) {
    for (const shape of this.shapes) {
      shape.allowDragging(on)
    }
  }

  /**
   * Plan edit mode.
   * Indicates what kind of editing the user is currently doing, `undefined` if user is not doing any changes.
   * @type {PlanEditMode}
   */
  editMode

  /**
   * Indicates whether there's any ongoing editing
   */
  get isEditing () {
    return this.editMode != null
  }

  /**
   * Changes the current edit mode
   * @param {PlanEditMode} mode New edit mode
   * @param {Boolean} force If true, the mode is set again even if renderer is already in that mode
   * @returns {Boolean} `true` when mode has been set
   */
  setEditMode (mode, force) {
    if (this.editMode !== mode || force) {
      this.editMode = mode

      // KONVA BUG WORKAROUND
      // When editing connectors, we need to prevent dragging of elements.
      // While elements are no longer selectable or draggable using `Transformer` ,
      // their `draggable` property still allows them to be dragged on their own.
      // This leads to messed up layout, when devices can be detached from cables.
      // By disabling `draggable` we prevent this behaviour.
      // Unfortunately, we cannot just set `dragging` to `false` at all times,
      // because this prevents dragging items with `Transformer`.
      if (mode === PlanEditMode.DrawingCable) {
        this.allowDragging(false)
      }

      // If editing finished, cancel any unfinished connectors, items etc.
      if (!mode) {
        this.cancelConnector()
        this.stopAddingItem()
        this.allowDragging(true)

        // Purge any orphaned shapes
        for (const layer of this.layers) {
          for (const shape of layer.shapes || []) {
            if (shape.item && !this.getItem(shape.id)) {
              layer.remove(shape)
            }
          }
        }
      }

      return true
    }
  }

  /**
   * Checks whether the current edit mode is equal to one of the specified ones
   * @param {Array[PlanEditMode]} mode Edit mode to check
   * @returns {Boolean}
   */
  isEditMode (...modes) {
    return modes.some(mode => this.editMode === mode)
  }

  /**
   * Determines whether user can now interact with the plan
   * @type {Boolean}
   */
  get isEditable () {
    return this.state === PlanState.Editing
  }

  /**
   * Indicates that we're now rendering the plan
   * @type {Boolean}
   */
  isRendering

  /**
   * Indicates that the plan has been rendered
   * @type {Boolean}
   */
  isRendered

  /**
  * Rendered layout
  * @type {PlanLayout}
  */
  layout

  /**
   * Container element to render the plan in
   * @type {Element}
   */
  container

  /**
   * Floor to show. Can be a regular {@link PlanFloor}
   * or {@link PlanCrossSection} definition
   * @type {PlanFloor|PlanCrossSection}
   */
  floor

  /**
   * Checks if we're looking at the cross-section view,
   * which is indicated by {@link floor} not being set
   * @returns {Boolean}
   */
  get isCrossSection () {
    return this.floor?.id === PlanFloors.CrossSection && this.floor != null
  }

  /**
   * Checks if we're looking at the floor view,
   * which is indicated by {@link floor} being set
   * @returns {Boolean}
   */
  get isFloor () {
    return this.floor?.id !== PlanFloors.CrossSection && this.floor != null
  }

  /**
   * Checks if {@link floor} is the first one
   * @returns {Boolean}
   */
  get isFirstFloor () {
    return this.layout.isFirstFloor(this.floor?.id)
  }

  /**
   * Checks if {@link floor} is the last one
   * @param {String} floorId Floor identifier
   * @returns {Boolean}
   */
  get isLastFloor () {
    return this.layout.isLastFloor(this.floor?.id)
  }

  /**
   * Currently selected floor.
   * When on cross-section view, the {@link floor} is the cross-section,
   * while this property stores the floor in the cross-section
   * which has been activated by the user
   * @type {PlanFloor}
   */
  get selectedFloor () {
    return this.isCrossSection ? this.layout.selectedFloor : this.floor
  }

  /**
   * Checks whether the specified floor is currently selected
   * @type {PlanFloor}
   * @returns {Boolean}
   */
  isFloorSelected (floor) {
    return this.layout.isFloorSelected(floor)
  }

  /**
   * Indicates whether any item on the current {@link floor}
   * has connections to floors above this floor
   * @type {Boolean}
   */
  get hasConnectionsToUpperFloors () {
    return true
  }

  /**
   * Indicates whether any item on the current {@link floor}
   * has connections to floors below this floor
   * @type {Boolean}
   */
  get hasConnectionsToLowerFloors () {
    return true
  }

  /**
   * Indicates whether we're currently drawing a shape built of points
   * @type {Boolean}
   */
  get isDrawingLine () {
    return this.isEditMode(
      PlanEditMode.DrawingLine,
      PlanEditMode.DrawingPolygon,
      PlanEditMode.DrawingWalls
    )
  }

  /**
   * Indicates whether we're currently drawing the building outline
   * for masking radiation patterns
   * @type {Boolean}
   */
  get isDrawingBuildingWalls () {
    return this.isEditMode(PlanEditMode.DrawingWalls)
  }

  /**
   * Equipment hierarchy, clustered around repeaters
   * @type {Object}
   */
  equipmentHierarchy

  /**
   * Traverses the {@link equipmentHierarchy} and finds all equipment items which are connected to the specified item,
   * optionally only those matching the specified predicate
   * @param {PlanItem} item Plan item
   * @param {Function<PlanHierarchyItem, Boolean>} predicate Predicate to check on traversed hierarchy items
   * @returns {Array[PlanItem]}
   */
  findConnectedEquipment (item, predicate) {
    const { equipmentHierarchy, layout } = this
    if (item && equipmentHierarchy) {
      const hierarchyItem = equipmentHierarchy
        .flatMap(root => root.id === item.id ? root : root.findDescendant(d => d.id === item.id))
        .find(i => i != null)
      if (hierarchyItem) {
        return hierarchyItem
          .allDescendants
          .filter(d => !predicate || predicate(d))
          .map(d => layout.getItem(d.id))
      }
    }

    return []
  }

  /**
   * Cancels any ongoing editing
   */
  async cancelEditing () {
    await this.stopAddingItem()
    await this.cancelConnector()
    await this.setTransparentColors(false)
    await this.drawMapScale(false)
    await this.selection.finishMoving()
  }

  /**
   * Finishes any ongoing editing
   * @return {Boolean} Returns `true` if there was any editing ongoing
   */
  async finishEditing () {
    let wasEditing

    // Finish adding items
    if (this.isAddingItem) {
      const item = await this.finishAddingItem()
      await this.selectItem({ item })
      wasEditing = true
    }

    // Finish setting transparent colors
    if (this.isSettingTransparentColors) {
      await this.setTransparentColors(false)
      wasEditing = true
    }

    return wasEditing
  }

  /**
   * Changes the scale of the plan items
   * @param {Number} scale Plan items scale
   */
  setScale (scale) {
    const { layout } = this
    layout.setScale(scale)
    this.refresh()
    this.changed()
  }

  /**
   * Changes the dimensions of the current view
   * @param {Size} size View size
   * @param {PlanFloor} floor Plan floor to set the margin, optional.
   * If not specified, the currently displayed floor will be used.
   */
  setSize (size, floor) {
    const { backgroundLayer, layout, stage } = this

    floor = floor || this.floor
    layout.setFloorSize(floor, size)

    backgroundLayer.refresh(this)
    stage.size(floor.canvasDimensions)
    this.changed()
  }

  /**
   * Changes the margin around the current view
   * @param {Number} margin Margin to apply
   * @param {PlanFloor} floor Plan floor to set the margin, optional.
   * If not specified, the currently displayed floor will be used.
   */
  setMargin (margin, floor) {
    const { isCrossSection, backgroundLayer, itemLayer, layout } = this

    floor = floor || this.floor
    layout.setFloorMargin(floor, margin)

    this.refresh()
    backgroundLayer.refreshImage()
    if (isCrossSection) {
      itemLayer.refresh()
    }

    this.changed()
  }

  /**
   * Returns the current zoom value
   * @type {Number}
   */
  get zoom () {
    return this.floor.zoom.value
  }

  /**
   * Changes the view zoom
   * @param {Number} value Zoom factor.
   * If not specified, the current zoom level is applied to the stage
   * @param {PlanFloor} floor Plan floor to set the zoom, optional.
   * If not specified, the currently displayed floor will be used.
  */
  setZoom (value, floor) {
    const { stage, base } = this
    floor = floor || this.floor

    if (value != null) {
      floor.setZoom(value)
    }
    base.content.scale(floor.zoom)

    // Resize the stage to accomodate for the zoomed layers
    stage.size(floor.canvasDimensions)
  }

  /**
   * Changes the plan zoom by the specified delta
   * @param {Number} value Zoom delta
   * @param {Boolean} keepPosition If true, the cursor position is maintained
   * @param {PlanFloor} floor Plan floor to set the zoom, optional.
   * If not specified, the currently displayed floor will be used.
   */
  zoomBy (value, keepPosition = true, floor) {
    const { stage, base, container } = this
    floor = floor || this.floor

    const cursorAt = this.currentPosition

    floor.zoomBy(value)
    stage.size(floor.canvasDimensions)
    base.content.scale(floor.zoom)

    if (keepPosition && cursorAt) {
      const cursorShouldBeAt = Point.from(cursorAt).scale(1 + value)
      const pan = cursorShouldBeAt.delta(cursorAt)
      container.scrollBy(pan.x, pan.y)
    }
  }

  /**
   * Changes the plan zoom to fit the entire image in sight
   * @param {PlanFloor} floor Plan floor to set the zoom, optional.
   * If not specified, the currently displayed floor will be used.
   */
  zoomToFit (floor) {
    const { container } = this
    floor = floor || this.floor

    // Get the size of the canvas and the size of the container
    const size = Size
      .from(floor.dimensions)
      .growBy(floor.margin.leftTop)
      .growBy(floor.margin.rightBottom)
    const originalSize = Size.from(size)
    const containerSize = Size.from({
      width: container.parentElement.offsetWidth,
      height: container.parentElement.offsetHeight
    })

    if (!(size.width > 0 && size.height > 0)) return
    if (!(containerSize.width > 0 && containerSize.height > 0)) return

    // Fit width, scale height proportionally
    let ratio = (containerSize.width / originalSize.width)
    size.width = containerSize.width
    size.height = ratio * size.height

    // Check if height fits
    // If height exceeds, expand height to container and scale the width to fit
    ratio = size.height / containerSize.height
    if (ratio > 1) {
      size.height = containerSize.height
      size.width = size.width / ratio
    }

    // Calculate the final scale and zoom in/out
    size.round()
    const zoom = Math.floor(10 * (size.width / originalSize.width)) / 10

    this.setZoom(zoom)
  }

  /**
   * Indicates whether the layer and its shapes can be scaled
   * when plan is being scaled
   * @param {String} id Layer identifier
   * @returns {Boolean}
   */
  isLayerScaled (id) {
    return !id || id === PlanLayers.Items
  }

  /**
   * Assigns a handler for confirmations of user actions,
   * async function receiving a message to show and returning
   * a boolean when action is confirmed
   * @param {Function<Promise<String, Boolean>>} handler Handler to assign
   */
  setConfirmationHandler (handler) {
    this.__confirmationHandler = handler
  }

  /**
   * Asks user for confirmation of some action
   * @param {String} message Message to ask
   * @returns {Promise<Boolean>} True if user has confirmed to perform the action
   */
  async confirm (message) {
    const { __confirmationHandler: handler } = this
    if (handler) {
      const yes = await handler(message)
      return yes
    }
  }

  /**
   * Marks the plan as focused / unfocused
   * @param {Boolean} state Focus state
   */
  focus (state = true) {
    this.__isFocused = Boolean(state)
    if (state) {
      this.container.focus()
    } else {
      this.container.blur()
    }
  }

  /**
   * Defocuses the plan
   */
  blur () {
    this.focus(false)
  }

  /**
   * Returns the focused state of the plan
   * @type {Boolean}
   */
  get isFocused () {
    return this.__isFocused
  }

  /**
   * Rendering stage
   * @type {Konva.Stage}
   */
  stage

  /**
   * Plan layers.
   * @type {Array[PlanLayer]}
   * @description Infact, there's only one {@link layer} on the stage,
   * while those are instances of {@link Konva.Group}, for performance reasons
   */
  layers

  /**
   * Notifies all or specified layers
   * @param  {Function} callback Callback to call on each of the layers
   * @param  {...any} identifiers Layers to notify. If not specified, all layers are notified
   */
  notifyLayers (callback, ...identifiers) {
    if (callback) {
      for (const layer of this.layers) {
        if (identifiers.length === 0 || identifiers.includes(layer.id))
          callback(layer)
      }
    }
  }

  /**
   * All shapes on the plan
   * @type {Array[Shape]}
   */
  get shapes () {
    return this.layers.flatMap(layer => layer.shapes || [])
  }

  /**
   * All shapes on the plan which can be selected
   * @type {Array[Shape]}
   */
  get selectableShapes () {
    return this.layers
      .filter(layer => !layer.isLocked)
      .flatMap(layer => layer.shapes || [])
      .filter(s => s.item.canSelect && !s.item.isLocked)
  }

  /**
   * All non-connector shapes rendered by renderer
   * @type {Array[Shape]}
   */
  get elementShapes () {
    return (this.shapes || []).filter(s => !s.isConnector)
  }

  /**
   * All connector shapes
   * @type {Array[Shape]}
   */
  get connectorShapes () {
    return (this.shapes || []).filter(s => s.isConnector)
  }

  /**
   * All items on the current view
   * @type {Array[PlanItem]}
   */
  get items () {
    return this.floor?.items
  }

  /**
   * All items on the plan which can be selected
   * @type {Array[PlanItem]}
   */
  get selectableItems () {
    return this
      .selectableShapes
      .map(s => s.item)
      .filter(i => i)
  }

  /**
   * Returns item with the specified id
   * @param {String} id Item identifier
   * @returns {PlanItem}
   */
  getItem (id) {
    return this.floor?.getItem(id)
  }

  /**
   * Finds the item related to the specified primitive shape
   * @param {Konva.Shape} shape
   * @returns {PlanItem}
   */
  getItemOf (shape) {
    if (shape) {
      let itemId
      do {
        itemId = shape.itemId
        shape = shape.parent
      } while (!itemId && shape)
      if (itemId) {
        return this.getItem(itemId)
      }
    }
  }

  /**
   * Checks whether the specified item can be rendered on the currently shown floor
   * @param {PlanItem} item Item to check
   * @returns {Boolean}
   */
  canRenderOnTheFloor (item) {
    if (item) {
      // If we're looking at the cross-section, the items must be visible on it.
      // For example, riser plugs aren't visible on the cross section
      if (this.isCrossSection) return item.showOnCrossSection

      // If we're on a normal floor:

      // Ignore items visible ONLY on the cross-section,
      // such as shapes added directly to the cross-section
      if (item.showOnlyOnCrossSection) return false

      // If item belongs to a cross-floor only, such as cross-floor cables, ignore
      if (item.isCrossFloor) return false

      // If item belongs to another floor than the currently shown one, ignore
      if (item.floorId !== this.floor.id) return false

      return true
    }
  }

  /**
   * Indicates that user is moving items and shapes
   * @type {Boolean}
   */
  isMovingItems

  /**
   * Indicates that user has clicked something but didn't release the mouse yet,
   * so he might be dragging it, resizing etc
   * @type {Boolean}
   */
  isUserActive

  /**
   * Takes notice of the interactive user being active now
   */
  userActive () {
    this.isUserActive = true
  }

  /**
   * Notifies whoever might be interested in layout changes
   * @param {Action} action Action that triggered the change
   * @returns {Promise}
   */
  async changed ({ action } = {}) {
    // Ignore initial changes of plan properties during loading
    if (this.isRendering) return

    // Take notice of the changes
    const { layout, onChanged } = this
    layout.changed()
    if (!onChanged) return

    // Prevent nesting change callbacks while previous ones are still unhandled
    this.__changeNotifications++
    if (this.__changeNotifications > 1) return

    try {
      await onChanged({ action })
    } finally {
      // If there were any change notifications queued up while we were busy here,
      // trigger another notification
      if (this.__changeNotifications > 1) {
        try {
          await onChanged({ action })
        } catch {
          // lint-disable-line no-empty
        }
      }
      this.__changeNotifications = 0
    }
  }

  /**
   * Requests immediate saving of the layout
   * @returns {Promise}
   */
  save () {
    // Ignore initial changes of plan properties during loading
    if (this.isRendering) return

    if (this.onSave) {
      this.layout.changed()
      return this.onSave({ renderer: this })
    }
  }

  /**
   * Requests saving the floor image
   * @param {Attachment} file Image data, `null` if image has been cleared
   */
  async saveFloorImage ({ file } = {}) {
    // Ignore initial changes of plan properties during loading
    if (this.isRendering) return

    if (this.onSaveImage) {
      // If file is set, gather the file image after rendering.
      // It might be not the same as the original image,
      // after applying transparency, transformations etc.
      if (file) {
        file.mimeType = 'application/base64'
        file.content = await this.getBackgroundImageData()
      }
      const { floor } = this
      await this.onSaveImage({ renderer: this, floor, file })
    }
  }

  /**
   * Requests creating a plan snapshot
   */
  async saveSnapshot () {
    if (this.onSnapshotSave) {
      await this.onSnapshotSave({ renderer: this })
    }
  }

  /**
   * Requests deleting the specified plan snapshot
   * @param {Plan} snapshot Snapshot to delete
  */
  async deleteSnapshot ({ snapshot } = {}) {
    if (snapshot && this.onSnapshotDelete) {
      await this.onSnapshotDelete({ renderer: this, snapshot })
    }
  }

  /**
   * Requests restoring the specified plan snapshot
   * @param {Plan} snapshot Snapshot to restore
   */
  async restoreSnapshot ({ snapshot } = {}) {
    if (snapshot && this.onSnapshotRestore) {
      await this.onSnapshotRestore({ renderer: this, snapshot })
    }
  }

  /**
   * Requesting navigation to another floor
   * @param {PlanFloor} floor Floor to navigate to
   */
  async selectFloor ({ floor } = {}) {
    // Ignore initial changes of plan properties during loading
    if (this.isRendering) return

    if (this.onSelectFloor) {
      await this.onSelectFloor({ renderer: this, floor })
    }
  }

  /**
   * Requesting navigation to cross-section view
   */
  async selectCrossSection () {
    // Ignore initial changes of plan properties during loading
    if (this.isRendering) return

    if (this.onSelectCrossSection) {
      await this.onSelectCrossSection({ renderer: this })
    }
  }

  /**
   * Requests clearing the floor image
   * @param {Attachment} file File data, `null` if image has been cleared
   */
  async clearFloorImage () {
    this.floor?.clearImage()
  }

  /**
   * Toggles setting of transparent colors on and off
   * @param {Boolean} on State to set
   * @type {Boolean}
   */
  async setTransparentColors (on) {
    // Toggle the edit mode
    if (this.setEditMode(on ? PlanEditMode.SelectingTransparentColors : undefined)) {

      // Make sure that only the background layer receives mouse events
      this.radiationLayer.isListening = !on
      this.itemLayer.isListening = !on
      this.selection.isListening = !on
      this.backgroundLayer.isListening = on

      // If finished setting transparent colors, save the modified image
      if (!this.isSettingTransparentColors) {
        const { floor: { background } } = this
        const file = background.image
        if (file) {
          await this.saveFloorImage({ file })
          await delay(1000)
          await this.backgroundLayer.refreshImage()
        } else {
          this.changed()
        }
      }
    }
  }

  /**
   * Adds the specified color to transparent colors
   * @param {String} color
   */
  async addTransparentColor (color) {
    if (color) {
      await executePlanAction({
        renderer: this,
        action: PlanActions.SetBackgroundTransparency,
        color
      })
    }
  }

  /**
   * Checks whether we're currently setting the image transparency
   * @type {Boolean}
   */
  get isSettingTransparentColors () {
    return this.editMode === PlanEditMode.SelectingTransparentColors
  }

  /**
   * Returns the data of the background image, after applying transparency
   * and other filters
   * @param {Object} options Export options, as per https://konvajs.org/api/Konva.Image.html#toDataURL__anchor
   * @returns {Promise<String>}
   */
  async getBackgroundImageData (options) {
    const scale = this.base.content.scale()

    try {
      // Before getting the image data we must return to 1:1 scale
      // otherwise the image will be inadvertently shrunk
      this.base.content.scale({ x: 1, y: 1 })
      const data = await this.backgroundLayer.getImageData(options)
      return data

    } finally {
      this.base.content.scale(scale)
    }
  }

  /**
   * Returns a screenshot of the entire stage image
   * @param {Object} options Export options, as per https://konvajs.org/api/Konva.Image.html#toDataURL__anchor
   * @returns {Promise<String>}
   */
  async getStageImageData ({
    x,
    y,
    width,
    height,
    mimeType = 'image/png',
    quality = 1,
    pixelRatio = 1,
    imageSmoothingEnabled = false
  } = {}) {
    const { stage, base, floor } = this

    if (stage, base) {
      const previousZoom = floor.zoom.value

      try {
        // Zoom in to double size for better quality
        this.setZoom(2)

        // Take the snapshot
        const image = await stage.toDataURL({
          x: x || 0,
          y: y || 0,
          width: width || stage.width(),
          height: height || stage.height(),
          mimeType,
          quality,
          pixelRatio,
          imageSmoothingEnabled
        })

        return image

      } finally {
        // Zoom back to previous scale
        this.setZoom(previousZoom)
      }
    }
  }

  /**
   * Toggles drawing the map scale
   * @param {Boolean} on State to set
   * @type {Boolean}
   */
  drawMapScale (on) {
    this.isDrawingMapScale = on
    this.mapScalePoints = 0

    // Make sure that only the base layer receives mouse events
    this.radiationLayer.isListening = !on
    this.itemLayer.isListening = !on
    this.backgroundLayer.isListening = !on
    this.selection.isListening = !on

    this.removeMarkers()
    this.hideDistanceLine()

    this.setEditMode(on ? PlanEditMode.DrawingMapScale : undefined)
  }

  /**
   * Adds a point to the map scale line
   * @param {Point} position Clicked point
   */
  async addScalePoint (position) {
    const { floor } = this

    if (!(floor && position)) return

    // Add two distance markers if first point added
    if (this.mapScalePoints === 0) {
      this.mapScalePoints++
      this.addMarker(position, 4, 'red', false)
      this.addMarker(position, 4, 'red', false)
    }

    // Get point distance, prevent double-clicks in the same spot
    const start = this.getMarkerPosition(0)
    const end = this.getMarkerPosition(1)
    this.showDistanceLine([start, end])
    if (start.distance(end) >= 10) {
      this.mapScalePoints++

      // If two points selected, calculate and set the scale
      if (this.mapScalePoints === 2) {
        // Update the distance line, as how many meters does it represent
        const length = await Confirmation.prompt({
          title: 'Length in meters',
          message: 'What is the length of the line in meters?',
          number: true,
          min: 1,
          max: 1000,
          step: 0.1
        })

        if (length > 0) {
          const scale = Math.round(start.distance(end) / length)
          await executePlanAction({
            renderer: this,
            action: PlanActions.SetMapScale,
            floor,
            scale
          })
          Notification.success({
            title: 'Map scale set',
            message: `Map scale has been set to 1m = ${scale}px`
          })
          await delay(500)
        }

        this.drawMapScale(false)
      }
    }
  }

  /**
   * Indicates than the currently selected item is being transformed
   * @type {PlanItem}
   */
  get transformedItem () {
    return this.__transformedItem
  }

  /**
   * Indicates start/end of transforming an item
   * @param {PlanItem} item
   */
  itemTransforming (item) {
    this.logIf(
      item,
      'TRANSFORMING.START',
      'TRANSFORMING.FINISH',
      { item },
      { item: this.__transformedItem })

    // Update any related shapes accordingly
    this.updateLinkedItems(item || this.__transformedItem)
    this.__transformedItem = item
  }

  /**
   * Triggered when item has been transformed - scaled or rotated.
   * @param {PlanItem} item Transformed item
   */
  itemTransformed (item) {
    if (!item) return

    // Update related items - cable lengths might have changed due to resizing or rotation of the item etc.
    const shape = this.getItemShape(item)
    this.updateLinkedItems(item)

    // Refresh the shape
    if (item.refreshOnMove) {
      shape.render(this)
    }

    // Notify other layers, they might have elements associated with the moved item which need to be updated
    this.changed()
    this.notifyLayers(layer => layer.itemMoved(item))

    // Refresh legends, as cable lengths might have changed
    this.refreshLegends()
  }

  /**
   * Last-clicked position on the plan
   * @type {Point}
   */
  get selectedPosition () {
    return this.__selectedPosition
  }

  /**
   * Current position of the pointer
   * @type {Point}
   */
  get currentPosition () {
    const { zoom } = this
    const position = this.stage.getPointerPosition()
    if (position) {
      return new Point(position)
        .scale(PlanScale.from(zoom).inverted())
        .round()
    } else {
      return null
    }

    // TODO: reuse point instance to prevent avalanche of objects in mousemove events
    // this.__position.x = x
    // this.__position.y = y
    // return this.__position
  }

  /**
   * Current scroll position of the container element
   * @type {Point}
   */
  get currentScroll () {
    const { scrollLeft: x, scrollTop: y } = this.container
    return Point.from({ x, y })
  }

  /**
   * Time since the last click.
   * Can be used to throttle certain events - for example very fast clicking
   * while drawing polygons can lead to unintentionally messy shapes
   * @type {Number}
   */
  get timeSinceLastClick () {
    return this.__lastClick
      ? new Date().getTime() - this.__lastClick.getTime()
      : 0
  }

  /**
   * Color at the specified or the current position
   * @param {Point} at Position at which to get the color.
   * If not specified, the color under the current mouse position over the canvas is returned.
   * @type {String}
   */
  getColorAt (at) {
    const { stage, base } = this
    at = at || stage.getPointerPosition()
    const canvas = base.content.getCanvas()
    const ctx = canvas.getContext('2d')
    const px = ctx.getImageData(at.x * Konva.pixelRatio, at.y * Konva.pixelRatio, 1, 1)
    const data = px.data
    const color = Color.toHex({
      r: data[0],
      g: data[1], b: data[2]
    })
    return color
  }

  /**
   * Color at the specified or current position on the background layer
   * @param {Point} at Position at which to get the color.
   * If not specified, the color under thje current mouse position over the canvas is returned.
   * @type {String}
   */
  getBackgroundColor (at) {
    return this.getColorAt(at)
  }

  /**
   * Color at the specified or current position on the items layer
   * @param {Point} at Position at which to get the color.
   * If not specified, the color under thje current mouse position over the canvas is returned.
   * @type {String}
   */
  getColor (at) {
    return this.getColorAt(this.itemLayer, at)
  }

  /**
   * Current position of the pointer inside the container,
   * taking into consideration eventual scroll
   * @type {Point}
   */
  getAbsolutePosition () {
    const position = new Point(this.stage.getPointerPosition())
    const { scrollLeft, scrollTop } = this.container
    return position.moveBy({ x: -scrollLeft, y: -scrollTop })
  }

  /**
   * Item currently selected on the plan by clicking on items
   * @type {PlanItem}
   */
  get selectedItem () {
    return this.selection.items[0]
  }

  /**
   * Multiple items currently selected on the plan
   * using drag selector
   * @type {Array[PlanItem]}
   */
  get selectedItems () {
    return this.selection.items || []
  }

  /**
   * Indicates whether any items are currently selected
   * @type {Boolean}
   */
  get hasSelectedItems () {
    return this.selectedItems.length > 0
  }

  /**
   * Indicates that the specified item is selected
   * @param {PlanItem} item
   * @type {Boolean}
   */
  isItemSelected (item) {
    return this.selection.isItemSelected(item)
  }

  /**
   * Checks whether items can be selected at this moment
   * @param {PlanItem} item Optional item to be selected
   * @type {Boolean}
   */
  canSelect (item) {
    // Don't allow selecting items if adding a new item and clicking its points now
    if (this.__addedItem?.isPointBased) return false

    // Don't allow selecting individual items in the multi-select group
    if (item) {
      return !(this.selection.manySelected && this.selection.isItemSelected(item))
    }

    return true
  }

  /**
   * Marks the specified item as selected
   * @param {PlanItem} item Item to select
   * @param {Point} position Position at which the cursor was when item was selected
   * @param {Number} point Point on the item clicked when item was selected
   * @param {PlanPort} port Optional port to select
   * @param {Boolean} allowNewPoint If true and line was clicked, new point may be created at the point
   * @param {Boolean} multiSelect If true, we're in multi-select mode
   * @param {Event} e Stage event which triggered item selection/deselection
   */
  selectItem ({ item, position, point, port, allowNewPoint, multiSelect, e } = {}) {
    // Some items are fixed and cannot be selected
    if (!this.canSelect(item)) return

    // If drawing cables, don't allow selecting items
    if (this.isAddingConnector) return

    const { layout, equipmentHierarchy, selectedItems: previousSelection, pointedItem: previousPointedAt, selection, isCreatingPoint, zoom, isCrossSection } = this
    this.logIf(
      item,
      'SELECT',
      'DESELECT',
      { item, position, point, port, allowNewPoint, multiSelect },
      { item: (previousSelection || [])[0] })

    // Show cable stats for the selected equipment
    if (item && item.isAntenna) {
      const cableStats = layout.getCableStats(item, equipmentHierarchy)
      if (cableStats) {
        this.log('Cable Stats', cableStats)
      }
    }

    // Forget any previous port selections, pointed items etc.
    this.selectPort()
    this.pointAtItem()
    this.selection.clear()

    // Refresh deselected/depointed items
    const itemsToRefresh = [
      ...(previousSelection || []),
      previousPointedAt
    ].filter(i => i?.refreshOnSelect)

    for (const i of itemsToRefresh) {
      const shape = this.getItemShape(i)
      if (shape) {
        shape.isPointedAt = false
        this.refreshItem(i)
      }
    }

    // If deselecting ...
    if (!item) {
      // Cancel any added/dragged point
      if (isCreatingPoint) {
        this.cancelPoint(item, this.__selectedPoint)
      }

      // And leave
      return
    }

    // Don't allow selecting items on locked layers
    if (this.isItemLocked(item)) return

    // Evaluate item details
    item.details = item.getDetails(layout, equipmentHierarchy)

    // Mark item as selected
    const shape = this.getItemShape(item)
    selection.select([shape], multiSelect)
    this.selectPosition(position)
    this.selectPort(port)
    this.selectPoint(point)

    // Remember where the item was, in case it will be moved soon
    this.__recentItemPosition = item.getCoordinates(isCrossSection)

    // Redraw the item - some items render differently when their selection status changes
    if (item.refreshOnSelect) {
      this.refreshItem(item)
    }

    // If element with editable points has been selected,
    // maybe a new point should be created at the clicked point?
    if (allowNewPoint && this.canCreatePoint(item, position, point)) {
      if (this.createPoint(item, position)) {
        this.changed()
      }
    }

    // Show context menu of the item/selected point
    if (item && !this.isAddingPoints) {
      // Get menu position from item bounds and apply item scale.
      // For lines, show the menu where the user has clicked.
      const scale = this.getEquipmentScale(item)
      const bounds = item.getScaledBounds(scale, isCrossSection)
      const menuPosition = item.isLine && position
        ? position.scale(scale)
        : bounds.rightTop

      // Move a bit, apply global zoom
      if (menuPosition) {
        menuPosition
          .moveBy({ x: 20 })
          .scale(zoom)

        this.showItemsMenu({
          item,
          point,
          position: menuPosition,
          e
        })
      }
    }

    // For some edited items we want to clearly indicate they're being edited
    if (item.isWall || item.isYard) {
      this.setEditMode(PlanEditMode.EditingWalls)
    }

    // Notify layers about item selection
    this.notifyLayers(layer => layer.itemSelected(item))
  }

  /**
   * Deselects any selected items
   */
  deselect () {
    this.selectItem()
    this.selection.clear()
  }

  /**
   * Marks the specified items as selected
   * @param {Array[PlanItem]} items Items to select
   * @param {Boolean} showMenu Indicates whether to show the context menu for the selected items
   */
  async selectItems ({ items, showMenu = true } = {}) {
    if (!this.canSelect()) return

    // Deselect the previously selected item
    const hasItems = items?.length > 0
    const { selectedItems: previousSelection, pointedItem: previousPointedAt } = this

    this.logIf(
      hasItems,
      'SELECT.MANY',
      'DESELECT.MANY',
      { items },
      { items: previousSelection })

    // Forget any previous port selections, pointed items etc.
    this.selectPort()
    this.pointAtItem()

    // Refresh deselected/depointed items
    await this.refreshItem(previousPointedAt)
    if (hasItems) {
      this.selection.deselect()
      await this.refreshItems({
        items: previousSelection,
        condition: item => item.refreshOnSelect
      })
    }

    // Mark shapes as selected
    const shapes = items.map(item => this.getItemShape(item))
    this.selection.select(shapes)

    // Refresh the shapes, to indicate that they're selected
    await this.refreshItems({
      items: this.selectedItems,
      condition: item => item.refreshOnSelect
    })

    // Show context menu for the selected the items
    if (hasItems && showMenu) {
      const bounds = this.selection.bounds || this.floor.getItemsBounds(items).scale(this.zoom)
      const position = bounds.leftTop.moveBy({ x: -10, y: -50 })
      this.showItemsMenu({ items, position })
    }
  }

  /**
   * Marks the specified position as selected.
   * Deselects any current position if no position specified
   * @param {Point} position Position
   */
  selectPosition (position) {
    this.__selectedPosition = position
    if (!position) {
      this.__recentItemPosition = null
    }
  }

  /**
   * Marks the specified shape point as selected.
   * Deselects any current point if no point specified
   * @param {Number} point Point index
   */
  selectPoint (point) {
    this.__isCreatingPoint = false
    this.__selectedPoint = point
  }

  /**
   * Marks the specified port as selected.
   * Deselects any current port if no port specified
   * @param {PlanPort} port Port to select
   */
  selectPort (port) {
    this.__selectedPort = port
  }

  /**
  * Item currently pointed at
  * @type {PlanItem}
  */
  get pointedItem () {
    return this.__pointedItem
  }

  /**
   * Points at the specified item
   * @param {PlanItem} item Pointed item
   */
  pointAtItem (item) {
    // Don't do anything with items on locked layers!
    if (this.isItemLocked(item)) return

    // If drawing cables, don't allow activating items on hover
    if (this.isAddingConnector) return

    // If different item is pointed at ...
    const { __pointedItem: previous } = this
    if (previous !== item) {
      // Mark the specified item as pointed at
      this.__pointedItem = item
      if (item?.refreshOnHover) {
        this.refreshItem(item)
      }

      // Hide indication of the previously pointed item
      if (previous?.refreshOnHover) {
        this.refreshItem(previous)
      }

      // Show tooltip if any
      if (item) {
        this.showItemTooltip({
          item,
          position: this.currentPosition
        })
        if (item.canMove) {
          this.container.classList.add('pointing')
        }
      } else {
        this.hideTooltip()
        this.container.classList.remove('pointing')
      }
    }
  }

  /**
   * Returns true if mouse is over the specified item
   * @param {PlanItem} item
   * @returns {Boolean}
   */
  isItemPointedAt (item) {
    return item && this.pointedItem?.id === item.id
  }

  /**
   * Item port currently selected on the plan
   * @type {PlanPort}
   */
  get selectedPort () {
    return this.__selectedPort
  }

  /**
   * Line point currently selected on the selected shape
   * @type {Number}
   */
  get selectedPoint () {
    return this.__selectedPoint
  }

  /**
   * Indicates that we're creating a new line point
   * @type {Boolean}
   */
  get isCreatingPoint () {
    return this.__isCreatingPoint
  }

  /**
   * Position at which the currently selected item
   * was at the moment when selection took place
   * @type {Point}
   */
  get recentItemPosition () {
    return this.__recentItemPosition
  }

  /**
   * Newly created connector
   * @type {PlanConnector}
   */
  newConnector

  /**
   * Checks whether we're adding a new connector
   * @type {Boolean}
   */
  get isAddingConnector () {
    return this.newConnector != null
  }

  /**
   * Finds the layer where the item belongs
   * @param {PlanItem} item Plan item
   * @returns {Layer}
   */
  getItemLayer (item) {
    if (item) {
      if (item.layer) {
        return this.layers.find(l => l.id === item.layer)
      } else {
        return this.itemLayer
      }
    }
  }

  /**
   * Finds shape associated with the specified item
   * @param {PlanItem} item Plan item
   * @returns {Shape}
   */
  getItemShape (item) {
    if (item) {
      if (item.shape) {
        return item.shape
      } else {
        const { shapes } = this
        item.shape = shapes.find(shape => shape.item?.id === item.id)
        return item.shape
      }
    }
  }

  /**
   * Shape associated with the currently selected item
   * @type {Shape}
   */
  get selectedShape () {
    const item = this.selectedItem
    return item ? this.getItemShape(item) : null
  }


  /**
   * Returns the actual equipment scale, applicable for the current floor.
   * @param {PlanItem} item Item to determine the scale for, optional.
   * If specified, the returned scale will include any further
   * scaling introduced by the item itself.
   * @returns {PlanScale}
   */
  getEquipmentScale (item) {
    const { layout, floor } = this
    const floorScale = layout.getScale(floor)

    if (item) {
      const scale = new PlanScale(item.scale)
      if (item.layer === PlanLayers.Items && item.canScale) {
        scale.add(floorScale).round(1)
      }
      return scale

    } else {
      return new PlanScale(floorScale)
    }
  }

  /**
   * Checks whether the item is locked and cannot be moved, edited, selected etc.
   * @param {PlanItem} item Item to check
   * @returns {Boolean}
   */
  isItemLocked (item) {
    if (item) {
      if (item.isLocked) {
        return true
      }
      return this.itemLayer.isLocked
    }
  }

  /**
   * Returns the specified layer
   * @param {String} id Layer identifier
   * @returns {Layer}
   */
  getLayer (id) {
    return this.layers.find(l => l.id === id)
  }

  /**
   * Radiation layer
   * @type {Layer}
   */
  radiationLayer

  /**
   * Background layer
   * @type {Layer}
   */
  backgroundLayer

  /**
   * Items layer
   * @type {Layer}
   */
  itemLayer

  /**
   * Selection layer
   * @type {SelectionLayer}
   */
  selection

  /**
   * Finds all items which belong to the specified layer
   * @param {PlanLayer} layer Plan layer
   * @returns {Array[PlanItem]}
   */
  getLayerItems (layer) {
    if (layer) {
      const { items } = this
      return items.filter(item => layer.id === item.layer)
    }
  }

  /**
   * Finds all shapes which belong to the specified layer
   * @param {PlanLayer} layer Plan layer
   * @returns {Array[Shape]}
   */
  getLayerShapes (layer) {
    if (layer) {
      const { items } = this
      return items.filter(item => layer.id === item.layer)
    }
  }

  /**
   * Finds out whether the specified layer is empty
   * @param {PlanLayer} layer Plan layer
   * @returns {Boolean}
   */
  isEmptyLayer (layer) {
    if (layer) {
      const { items } = this
      return !items.some(item => layer.id === item.layer)
    }
  }

  /**
   * Toggles layer visibility
   * @param {id} id Identifier of the layer
   * @param {Boolean} visibility Layer visibility. If not specified, the current visibility are toggled.
   */
  toggleLayer (id, visibility) {
    const layer = this.getLayer(id)
    if (layer) {
      visibility = visibility == null ? !layer.isVisible : Boolean(visibility)
      layer.setVisibility(visibility)
      this.reset()
    }
  }

  /**
   * Re-draw the specified plan item
   * @param {PlanItem} item Item to redraw
   * @returns {Promise<Shape>} The updated shape
   */
  async refreshItem (item) {
    if (!item) return

    const shape = this.getItemShape(item)
    if (shape) {
      await shape.render(this)
      return shape
    }
  }

  /**
   * Re-draw the specified plan items
   * @param {Array[PlanItem]} items Items to redraw
   * @param {Function<PlanItem, Boolean>} condition Optional condition to check for refreshing the item
   */
  async refreshItems ({ items, condition } = {}) {
    if (!items) return
    for (const item of items) {
      if (!condition || condition(item)) {
        await this.refreshItem(item)
      }
    }
  }

  /**
   * Re-evaluates and draws legend items
   */
  async refreshLegends () {
    for (const item of this.items) {
      if (item.isLegend) {
        await this.refreshItem(item)
      }
    }
  }

  /**
   * Redraws cables and their lengths
   */
  async refreshCables () {
    for (const item of this.items) {
      if (item.isCable) {
        await this.refreshItem(item)
      }
    }
    // Refresh radiation layer - antenna radiation beams could've changed
    // due to changed cable lengths
    this.radiationLayer.refresh(this)
  }

  /**
   * Refreshes the equipment hierarchy
   * @param {Boolean} redraw If true, the stage is redrawn after refreshing the hierarchy
   */
  async refreshEquipmentHierarchy (redraw) {
    const { layout, floor } = this
    const hierarchy = layout.getEquipmentHierarchy()
    this.equipmentHierarchy = hierarchy

    if (redraw) {
      await this.refreshItems()
    }

    // Notify about the change
    this.events.notifyEvent('hierarchy-changed', { renderer: this, hierarchy, layout, floor })
  }

  /**
   * Determines whether we can move a point on the specitied item
   * @param {PlanItem} item Item
   * @param {Number} index Index of the point to move
   * @returns {Boolean}
   */
  canMovePoint (item, index) {
    if (!item) return false
    if (!item.canEditPoints) return false
    return index >= 0
  }

  /**
   * Update all other items which are somehow related to the specified item
   * @param {PlanItem} item
   * @param {Object} properties Modified properties
   */
  updateLinkedItems (item, properties) {
    if (!item) return

    const { layout } = this

    // Update all connectors linked to the item
    const { floor } = this
    const connectors = floor?.getConnectorsOf(item) || []
    for (const connector of connectors) {
      const connectorShape = this.getItemShape(connector)
      if (connectorShape) {
        connectorShape.render(this)
      }
    }

    // If item is cross-floor connector or part of it, update all other parts accordingly
    const allConnectors = layout.getConnectorGroup(item)
    if (allConnectors.length > 1) {
      for (const connector of allConnectors) {
        connector.setProperties(properties)
      }
      layout.refreshRisers()
    }
  }

  /**
   * Checks whether any linked items are now invalid as a result of changes to the specified item,
   * removes them if so
   */
  async validateLinkedItems (item) {
    const { floor } = this

    // If item has ports, check if ports to which connectors are linked, still exist.
    // If not, delete such connectors.
    if (item.hasPorts) {
      const connectors = floor.getConnectorsOf(item) || []
      for (const connector of connectors) {
        const portId = connector.start.item.id === item.id
          ? connector.start.id
          : connector.end.id

        if (!item.hasPort(portId)) {
          await executePlanAction({
            renderer: this,
            action: PlanActions.RemoveItems,
            items: [connector]
          })
        }
      }
    }
  }

  /**
   * Triggered when moving items has started
   * @param {Array[PlanItem]} items Items about to be moved
   */
  movingItems (items) {
    this.selectedShape?.startMoving({ renderer: this })

    const { layout, floor } = this
    this.history.push({
      action: PlanActions.MoveItems,
      layout,
      floor,
      items
    })
  }

  /**
   * Triggered when item has been moved on the plan.
   * Updates positions of connectors associated with items etc.
   * @param {PlanItem} item Moved item
   * @param {Point} delta Movement delta
   */
  moveItem (item, delta) {
    if (!(item)) return

    // Hide the context menus if visible
    this.hideContextMenus()

    const shape = this.getItemShape(item)
    const movedTo = shape.position

    // Update item position
    shape.moveTo(movedTo, delta)
    item.moveTo(movedTo, this.isCrossSection, delta)

    // Update all connectors leading to the moved items
    // and any other linked elements
    this.updateLinkedItems(item)

    this.changed()

    // Notify other layers, they might have elements associated with the moved item which need to be updated
    this.notifyLayers(layer => layer.itemMoved(item))
  }

  /**
   * Triggered when item has been moved on the plan.
   * Applies grid snapping if required, then
   * Updates positions of connectors associated with items etc.
   * @param {PlanItem} item Moved item
   */
  itemMoved (item) {
    if (!item) return

    const { layout, isCrossSection } = this

    // Apply grid snap
    const shape = this.getItemShape(item)
    const movedTo = isCrossSection ? layout.snapToGrid(shape.position) : shape.position
    const delta = movedTo.delta(shape.position)

    // Update shape position and linked items
    shape.moveTo(movedTo, delta)
    item.moveTo(movedTo, isCrossSection, delta)
    this.updateLinkedItems(item)
    // Refresh the shape
    if (item.refreshOnMove) {
      shape.render(this)
    }
    this.changed()

    // Notify other layers, they might have elements associated with the moved item which need to be updated
    this.notifyLayers(layer => layer.itemMoved(item, true))

    // Refresh legends, as cable lengths might have changed
    this.refreshLegends()
  }

  /**
   * Moves shape point to the specified new coordinates.
   * @param {PlanItem} item Item whose point is being moved
   * @param {Number} index Point index
   * @param {Point} position Position to which the shape point is to be moved
   */
  movePoint (item, index, point) {
    if (item && this.canMovePoint(item, index) && point) {
      const { layout } = this
      const align = layout.showGrid
      const shape = this.getItemShape(item)
      item.movePoint(index, point, align, this.isCrossSection)
      shape.render(this)

      // Hide the context menu if visible
      this.hideContextMenus()

      // Notify the radiation layer
      if (item.isOnRadiationLayer) {
        this.radiationLayer.itemMoved(item)
      }

      this.changed()
    }
  }

  /**
   * Moves the item on z axis to the top
   * @param {PlanItem} item
   * @returns {Number} Ordinal index of the item on z axis
   */
  moveToTop (item) {
    const { floor } = this
    const shape = this.getItemShape(item)
    if (shape) {
      floor.moveToTop(item)
      moveToTop(shape)
      this.changed()
    }
  }

  /**
   * Moves the item on z axis to the bottom
   * @param {PlanItem} item
   * @returns {Number} Ordinal index of the item on z axis
  */
  moveToBottom (item) {
    const { floor } = this
    const shape = this.getItemShape(item)
    if (shape) {
      floor.moveToBottom(item)
      moveToBottom(shape)
      this.changed()
    }
  }

  /**
   * Creates a new connector starting at the specified item and port
   * @param {PlanItem} item Item at which the connector starts
   * @param {PlanPort} port Port at which the connector starts
   */
  async startConnector (item, port) {
    this.cancelConnector()

    if (item && port) {
      // Determine to which floor to add the connector:
      // it should be the same as that of the start item.
      const floor = this.layout.getFloorOf(item)

      // Create the connector starting at the port
      const connector = new PlanCable({ inProgress: true })
      connector.start.id = port.id
      connector.start.type = port.type
      connector.start.itemId = item.id
      connector.start.item = item

      await executePlanAction({
        renderer: this,
        action: PlanActions.AddItem,
        items: [connector],
        floor,
        select: false,
        // Don't store in the history yet!
        // This is done only when the connector is actually finished in `endConnector` method
        noHistory: true
      })

      // Mark the item as selected
      const shape = this.getItemShape(item)
      const scale = this.getEquipmentScale(item)
      const position = shape.getConnectionPoint({
        renderer: this,
        port,
        scale
      })
      this.selectItem({ item, position, port })
      this.hideContextMenus()

      // Add ending point to the connector, so we can see it as mouse is being dragged
      if (this.isCrossSection) {
        connector.crossSection.turns = [position]
      } else {
        connector.turns = [position]
      }

      this.newConnector = connector

      this.setEditMode(PlanEditMode.DrawingCable)
    }
  }

  /**
   * Checks whether the user is now drawing a connector starting from the specified item
   * @param {PlanItem} item Item to check
   * @returns {Boolean}
   */
  isDrawingConnectorFrom (item) {
    const { newConnector } = this
    return item && newConnector && newConnector.start.itemId === item.id
  }

  /**
   * Updates the end point of a connector currently being created
   */
  async updateConnector () {
    const { newConnector, currentPosition, selectedPosition, isCrossSection } = this

    if (newConnector) {
      // We move the end point away from the mouse pointer by a few pixels,
      // as the line sometimes intercepts mouse events of the target port
      const point = currentPosition
      const endPoint = getNearbyPoint(point, selectedPosition, 3)
      if (newConnector.getLastTurn(isCrossSection)?.moveTo(endPoint)) {
        const shape = await this.refreshItem(newConnector)
        if (shape) {
          shape.moveToTop()
        }
      }
    }
  }

  /**
   * Adds new point to connector turns
   * @param {Point} position Position to add
   * @param {Number} index Index at which to add the turn, optional. If not specified, the turn is added at the end.
  */
  async addConnectorTurn (position, index) {
    const { newConnector, isCrossSection } = this
    if (newConnector) {
      const lastTurn = newConnector.getLastTurn(isCrossSection)

      // Prevent accidental clicks in the same spot
      if (position.distance(lastTurn) > 2) {
        newConnector.addPoint(position, index, false, isCrossSection)
        await this.refreshItem(newConnector)
      }
    }
  }

  /**
   * Removes the last added connector turn
   */
  removeLastConnectorTurn () {
    const { isAddingConnector, newConnector, isCrossSection } = this
    if (isAddingConnector) {
      if (newConnector?.getTurns(isCrossSection).length > 1) {
        // Remove the last turn
        newConnector.removeLastPoint(isCrossSection)
        this.refreshItem(newConnector)
        Notification.success({ message: 'Cable point cancelled' })
      } else {
        this.cancelConnector()
        Notification.success({ message: 'Cable cancelled' })
      }
    }
  }

  /**
   * Finishes the recently created connector, ending it at the specified item and port
   * @param {PlanItem} item Item at which the connector ends
   * @param {PlanPort} port Port at which the connector ends
  */
  async endConnector (item, port) {
    const { newConnector, isCrossSection, layout, floor } = this

    if (!(item && port && newConnector)) return

    // Revert the ports of the start item to normal state
    const connectorStart = this.getItemShape(newConnector.start.item)
    connectorStart?.renderPorts({ renderer: this })

    // Store connector end
    this.newConnector = null
    const shape = this.getItemShape(newConnector)
    if (shape) {
      // Link the connector end to the port
      newConnector.inProgress = false
      newConnector.end.id = port.id
      newConnector.end.type = port.type
      newConnector.end.itemId = item.id
      newConnector.end.item = item

      // Remove the last turn as it was there only temporarily,
      // to guide the drawn line onto the target.
      // Once the connector has been joined with the end port,
      // this temporary turn can be removed.
      newConnector.removeLastPoint(isCrossSection)

      // Update the hierarchy as it might have changed
      await this.refreshEquipmentHierarchy()

      // Make sure that risers are present for all cross-floor connections
      layout.refreshRisers()

      // Redraw the canvas
      await this.refresh()

      // Bring the connected items to the top, so the connecting cables are under
      await executePlanAction({
        action: PlanActions.BringToTop,
        renderer: this,
        items: [
          item,
          newConnector.start.item
        ],
        noHistory: true
      })

      // Add to UNDO history
      this.history.push({
        action: PlanActions.AddItem,
        layout,
        floor,
        items: [newConnector]
      })

      this.setEditMode()

      Notification.success({
        message: `Cable ${newConnector.toString()} added`
      })
    }
  }

  /**
   * Cancels the recently created connector
   */
  async cancelConnector () {
    const { newConnector } = this
    if (newConnector) {
      // Remove the unfinished connector from the plan
      this.newConnector = null
      await executePlanAction({
        renderer: this,
        action: PlanActions.RemoveItems,
        items: [newConnector]
      })
      this.setEditMode()
    }
  }

  /**
   * Determines turns, depending on sides on which connector ends are
   * @param {PlanConnector} connector
   * @return {Array[Point]}
   */
  getConnectorTurns (connector) {
    if (!connector) return
    return []
  }

  /**
   * Checks whether we can create a new point on the shape
   * representing the specified item
   * @param {PlanItem} item Item to check
   * @param {Point} position Position at which to create the point
   * @param {Number} point Point index, specified if clicked position already is a shape point,
   * in which case there's nothing more to create, the point is already there
   * @returns {Boolean}
   */
  canCreatePoint (item, position, point) {
    return item && item.canEditPoints && position != null && point == null
  }

  /**
   * Creates a new point on the specified selected item
   * @param {PlanItem} item Item to add point to
   * @param {PlanPoint} position Position at which to add the point
   * @returns {Boolean} True, if point has been created
   */
  createPoint (item, position) {
    if (!item) return

    const { layout, isCrossSection } = this
    const shape = this.getItemShape(item)
    const points = shape.getShapePoints({ renderer: this })
    const fuzzy = item.lineStyle ? Math.ceil(item.lineStyle.width) || 0 : 0
    const index = item.findPointIndex(position, points, fuzzy) + 1
    const align = layout.showGrid

    if (index > 0) {
      item.addPoint(position, index, align, isCrossSection)
      this.selectPoint(index)
      this.refreshItem(item)
      this.__isCreatingPoint = true
      this.__pointCreated = {
        item,
        position: new Point(position),
        index
      }
      this.changed()
      return true
    }
  }

  /**
   * The number of markers
   * @type {Number}
   */
  get markerCount () {
    return this.__markers.length
  }

  /**
   * Adds a marker
   * @param {PlanPoint} position Position at which to add the marker
   * @param {Number} size Marker size
   * @param {String} color Marker color
   * @param {Boolean} filled If true, the marker is filled, otherwise an empty circle is drawn
   */
  addMarker (position, size = 8, color = 'red', filled = true) {
    if (!position) return

    const { backgroundLayer } = this
    const marker = new Konva.Circle({
      draggable: false,
      listening: false
    })
    marker.radius(size)
    if (filled) {
      marker.fill(color)
    } else {
      marker.stroke(color)
    }
    marker.position(position)
    backgroundLayer.renderShape(marker, true)

    this.__markers = [...this.__markers, marker]
  }

  /**
   * Returns the specified marker
   * @param {Number} index Marker index
   * @returns {Konva.Shape}
   */
  getMarker (index) {
    const marker = this.__markers[index]
    return marker
  }

  /**
   * Returns the position of the specified marker
   * @param {Number} index Marker index
   * @returns {Point}
   */
  getMarkerPosition (index) {
    const marker = this.__markers[index]
    return marker ? Point.from(marker.position()) : null
  }

  /**
   * Returns the position of all markers
   * @returns {Array[Point]}
   */
  getMarkerPositions () {
    return this.__markers.map(marker => Point.from(marker.position()))
  }

  /**
   * Updates the position of the specified marker
   * @param {Number} index Marker index
   * @param {Point} position New position of the marker
   */
  updateMarker (index, position) {
    const marker = this.__markers[index]
    if (marker) {
      marker.position(position)
    }
  }

  /**
   * Removes the last added marker
   */
  removeLastMarker () {
    const { __markers: markers } = this
    const count = markers.length
    if (count > 0) {
      markers[count - 1].destroy()
      this.__markers = this.__markers.slice(0, count - 1)
    }
  }

  /**
   * Removes all markers
   */
  removeMarkers () {
    const { __markers } = this
    for (const marker of __markers) {
      marker.destroy()
    }
    this.__markers = []
  }

  /**
   * Draws/updates a distance line
   * @param {Array[Point]} points Line points
   * @param {Boolean|Function} label If true, distance in pixels is displayed. If `function`, the label shows whatever the function returns.
   * @param {String} color Line color
   * @param {Number} width Line width
   * @param {Boolean} dashed If true, dash line is drawn
   */
  showDistanceLine (points, label, width = 2, color = 'red', dashed = true) {
    if (points?.length > 1) {
      let lineShape = this.__distanceLine
      let labelShapes = this.__distanceLabels

      // Draw the line
      if (!lineShape) {
        lineShape = new Konva.Line({ lineCap: 'round' })
        this.__distanceLine = lineShape
        this.backgroundLayer.renderShape(lineShape, true)
      }
      lineShape.stroke(color)
      lineShape.strokeWidth(width)
      lineShape.dash(dashed ? PlanLineStyle.getLineDash(PlanLineType.Dotted, 4) : null)
      lineShape.points(points.flatMap(p => p.toArray()))

      // Create label shapes for every line leg
      // The amount of labels must match the amount of line legs
      // We reuse previously created labels for performance reasons
      const labelCount = points.length - 1
      if (labelShapes.length > labelCount) {
        // There are more labels than line legs, remove the excess
        for (let i = labelCount - 1; i < labelShapes.length; i++) {
          labelShapes[i].destroy()
        }
        labelShapes = labelShapes.slice(0, labelCount)

      } else if (labelShapes.length < labelCount) {
        // There are fewer labels than line legs, create more
        for (let i = labelShapes.length; i < labelCount; i++) {
          const labelShape = new Konva.Text()
          labelShapes.push(labelShape)
          this.backgroundLayer.renderShape(labelShape, true)
        }
      }
      this.__distanceLabels = labelShapes

      // Update line labels, showing their current lengths
      if (label) {
        for (let i = 1; i < points.length; i++) {
          const labelShape = labelShapes[i - 1]
          labelShape.visible(false)

          const from = points[i - 1]
          const to = points[i]
          const distance = Math.round(to.distance(from))
          if (distance === 0) {
            continue
          }

          let text
          if (label === true) {
            if (distance >= 10) {
              text = `${distance}px`
            }
          } else {
            text = label(distance, from, to)
          }

          if (text) {
            labelShape.text(text)
            labelShape.fontSize(13)
            labelShape.fill(color)

            // Place the label in the middle of the line
            const position = Rectangle
              .fromPoints([from, to])
              .center
              .moveBy({
                x: -15 - labelShape.width() / 2,
                y: -20 - labelShape.height() / 2
              })

            labelShape.position(position)
            labelShape.visible(true)

          }
        }
      }
    }
  }

  /**
   * Hides the distance line
   */
  hideDistanceLine () {
    if (this.__distanceLine) {
      this.__distanceLine.destroy()
      for (const label of this.__distanceLabels) {
        label.destroy()
      }
      this.__distanceLine = null
      this.__distanceLabels = []
    }
  }

  /**
   * Cancels the currently newly added point
   * @param {PlanItem} item Item on which the point has been added
   * @param {Number} index Index at which the point has been added
   */
  cancelPoint (item, index) {
    this.__isCreatingPoint = false
    this.__pointCreated = null

    if (item && index >= 0) {
      const shape = this.getItemShape(item)
      item.removePoint(index, this.isCrossSection)
      this.refreshItem(item)
      shape.refreshJoints(this)
    }
  }

  /**
   * Removes the specified shape point
   * @param {PlanItem} item Item whose point to remove
   * @param {Number} point Index of the point to remove
   */
  async removePoint ({ item, point }) {
    await executePlanAction({
      renderer: this,
      action: PlanActions.RemovePoint,
      items: [item],
      point
    })
  }

  /**
   * Cancels all editing, selections etc.
   */
  async reset () {
    await this.hideHelp()
    await this.selectItem()
    await this.cancelConnector()
  }

  /**
   * Initializes addding a new item at the specified position
   * @param {PlanPaletteItem} options Details of the item to be added
   * @param {String} layer Layer to which to add the item
   */
  async startAddingItem (options, layer) {
    // Finish any other pending actions
    await this.cancelEditing()
    await this.deselect()

    this.__isAddingItem = options != null
    if (this.__isAddingItem) {
      this.__itemToAdd = options
      this.__itemToAdd.layer = layer

      // Determine the current edit mode based on the added item
      const editMode = planItemToEditMode({
        item: options,
        isNew: true
      })
      this.setEditMode(editMode)
    }
  }

  /**
   * Indicates whether we're currently adding new items to the plan
   * @type {Boolean}
   */
  get isAddingItem () {
    return this.__isAddingItem
  }

  /**
   * Options of a plan item currently being added
   * @type {PlanPaletteItem}
   */
  get itemToAdd () {
    return this.__itemToAdd
  }

  /**
   * Checks whether we're currently adding points of a newly added item
   * @type {Boolean}
   */
  get isAddingPoints () {
    return this.__addedItem?.isPointBased
  }

  /**
   * Creates and adds a plan item to the plan,
   * or adds another point to an item being added
   * @param {Point} position Position of the item
   * @param {Event} e Interactive event which triggered adding the item, optional
   * @returns {PlanItem}
   */
  async addItem (position, e) {
    const { __itemToAdd, __addedItem, layout, isCrossSection } = this
    if (!__itemToAdd) return

    const { x, y } = position
    let floor = this.selectedFloor

    // If item already added and point-based item, just add another point to it
    if (__addedItem?.isPointBased) {
      this.addPointToItem(__addedItem, position)

    } else {
      // Otherwise add a new item at the specified position
      const item = __itemToAdd.factory({ floorId: floor.id })
      if (item.isPointBased) {
        // Set item coordinates for point-based items
        item.points.every(p => p.moveBy({ x, y }))
      } else {
        // Set item coordinates for positioned items
        item.setCoordinates(Point.from({ x, y }), isCrossSection)
        // If item added to cross-section, determine decent coordinates
        // for the item on its respective floor
        if (isCrossSection) {
          layout.placeItem(item)
        } else {
          layout.placeItem(item, layout.crossSection)
        }
      }

      // Check whether the item belongs ONLY to the cross section
      item.showOnlyOnCrossSection = item.canAddToCrossSection && isCrossSection
      if (item.showOnlyOnCrossSection) {
        floor = this.layout.crossSection
      }
      item.layer = __itemToAdd.layer
      item.inProgress = true

      await executePlanAction({
        renderer: this,
        action: PlanActions.AddItem,
        position,
        floor,
        items: [item]
      })

      this.__addedItem = item
    }

    // If item doesn't require multiple points (such as line or polygon),
    // immediately finish adding the line
    const addedItem = this.__addedItem
    if (addedItem?.isPointBased) {
      if (addedItem.showPointMarkers) {
        if (this.isDrawingBuildingWalls) {
          // Drawing building walls
          this.addMarker(position, 8, 'red')
          // Make the polygon walls thicker
        } else {
          // Drawing other point-based elements
          this.addMarker(position)
        }
      }

      // If the maximal allowed amount of points reached,
      // finish the shape.
      if (this.__addedItem.hasAllPoints) {
        await this.finishAddingItem()
      }

      // If drawing polygon and clicked in vicinity of the first point,
      // also finish the shape.
      if (addedItem.points.length > 2 && position.distance(addedItem.points[0]) <= 10) {
        addedItem.points = addedItem.points.slice(0, addedItem.points.length - 1)
        await this.finishAddingItem()
      }

    } else {
      // Finish adding simple items ...
      // ... unless the user kept CTRL pressed, in which case we resume adding!
      const options = this.__itemToAdd
      const layer = options.layer
      await this.finishAddingItem()
      const addMore = PlanEvent.isCtrlKey(e)
      if (addMore && options && layer) {
        this.startAddingItem(options, layer)
      }
    }

    return this.__addedItem
  }

  /**
   * Adds new point to the specified item
   * @param {PlanItem} item Item to add the point to
   * @param {Point} position Position at which to add the point
   */
  addPointToItem (item, position) {
    const { isCrossSection } = this
    if (!(item && position)) return
    if (!item.isPointBased) return

    // Prevent accidental adding points with very fast clicks
    if (item.points.length > 1 && this.timeSinceLastClick < 300) return

    item.addPoint(position, null, true, isCrossSection)
    this.refreshItem(item)
  }

  /**
   * Removes the last added point
   */
  removeLastPoint () {
    if (this.isAddingPoints) {
      const { __addedItem: item, isCrossSection } = this
      if (item.getPoints(isCrossSection).length > 0) {
        // Remove the last point
        item.removeLastPoint(isCrossSection)
        this.removeLastMarker()
        this.refreshItem(item)

        // Cancel the item if all points removed
        if (item.getPoints(isCrossSection).length <= 1) {
          this.stopAddingItem()
        }
      }
    }
  }


  /**
   * Cancels addding a new item
   */
  async stopAddingItem () {
    const { __addedItem } = this
    if (__addedItem) {
      await executePlanAction({
        renderer: this,
        action: PlanActions.RemoveItems,
        items: [__addedItem]
      })
    }
    this.__itemToAdd = null
    this.__addedItem = null
    this.__isAddingItem = false
    this.removeMarkers()
    this.setEditMode()
  }

  /**
   * Finishes addding a new item
   * @returns {PlanItem} Added item, if any
   */
  async finishAddingItem () {
    const item = this.__addedItem
    this.__itemToAdd = null
    this.__addedItem = null
    this.__isAddingItem = false
    this.removeMarkers()

    if (item) {
      // Normalize points for some shapes, for example remove duplicate neighboring points on lines
      if (item.isPointBased) {
        item.normalizePoints()
      }

      // Redraw the item
      item.inProgress = false
      this.refreshItem(item)

      // If walls drawn, lock the walls and refresh the radiation patterns
      if (this.isDrawingBuildingWalls) {
        this.floor.lockWalls(true)
        await this.radiationLayer.refresh()
      }
    }

    this.setEditMode()

    return item
  }

  /**
   * Details of the items menu
   * @type {ItemMenu}
   */
  itemsMenu

  /**
   * Details of the tooltip to show
   * @type {PlanTooltip}
   */
  tooltip

  /**
   * Indicates whether help window should be displayed
   * @type {Boolean}
   */
  get helpVisible () {
    return this.__helpVisible
  }

  /**
   * Displays the help
   */
  showHelp () {
    this.__helpVisible = true
  }

  /**
   * Hides the help
   */
  hideHelp () {
    this.__helpVisible = false
  }

  /**
   * Shows items menu for the specified items
   * @param {Array[PlanItem]} items Items for which to show the menu
   * @param {PlanItem} item Alternatively, single item for which to show the menu
   * @param {Number} point Item point for which to show the menu
   * @param {Point} position Position of the menu
   * @param {Konva.Event} e Stage event which triggered the menu
   */
  showItemsMenu ({ items = [], item, point, position, e } = {}) {
    if (this.itemLayer.isLocked) return
    PlanEvent.cancelEvent(e)

    // If clicked an item while other items were selected, show context menu for the entire selection
    if (item && this.isItemSelected(item) && this.selection.manySelected) {
      items = this.selectedItems
    } else {
      items = item ? [item] : items
    }

    // Filter out items which don't have a context menu
    items = items.filter(item => item.hasContextMenu && !item.isLocked)
    if (items.length === 0) return

    // Correct the position for the scroll offset of the container.
    // If menu item moves outside the visible area, scroll it in sight.
    position.moveBy(this.currentScroll.opposite())
    if (position.x < 5) {
      position.moveTo({ x: 5 })
    }
    if (position.y < 5) {
      position.moveTo({ y: 5 })
    }

    // Show the menu
    this.itemsMenu.show({ items, point, position })
  }

  /**
   * Moves the items menu by the specified delta
   * @param {Point} delta Movement delta
   * @param {Konva.Event} e Stage event which triggered the menu
   */
  moveItemsMenu ({ delta, e } = {}) {
    if (this.itemLayer.isLocked) return
    PlanEvent.cancelEvent(e)
    return this.itemsMenu.moveBy({ delta })
  }

  /**
   * Hides any currently displayed context menus
   */
  hideContextMenus () {
    this.itemsMenu.hide()
    this.hideTooltip()
  }

  /**
   * Shows items menu for the specified items
   * @param {Array[PlanItem]} items Items for which to show the menu
   * @param {PlanItem} item Alternatively, single item for which to show the menu
   * @param {Number} point Item point for which to show the menu
   * @param {Point} position Position of the menu
   * @param {Konva.Event} e Stage event which triggered the menu
   */
  showItemTooltip ({ item, position } = {}) {
    if (!(item && position)) return

    const { itemLayer, layout, floor } = this
    if (itemLayer.isLocked) return

    // Correct the position for the zoom factor
    position.scale(floor.zoom.value)
    // Correct the position for the scroll offset of the container.
    position.moveBy(this.currentScroll.opposite())
    if (position.x < 5) {
      position.moveTo({ x: 5 })
    }
    if (position.y < 5) {
      position.moveTo({ y: 5 })
    }

    // Show the tooltip
    this.tooltip = getPlanItemTooltip({
      item,
      position,
      layout,
      floor
    })
  }

  /**
   * Hides any visible tooltips
   */
  hideTooltip () {
    this.tooltip = null
  }

  /**
   * Renders the current view
   * @returns {Promise}
   */
  async render () {
    if (this.isFloor) return this.renderFloor()
    if (this.isCrossSection) return this.renderCrossSection()
  }

  /**
   * Renders the floor view
   * @returns {Promise}
   */
  async renderFloor () {
    this.isRendering = true
    const { layout, floor, container, onData } = this
    const { width, height } = floor.canvasDimensions
    const stage = new Konva.Stage({
      container,
      width: width || container.offsetWidth,
      height: height || container.offsetHeight
    })

    // Set global pixel ration for new layers
    Konva.pixelRatio = window.devicePixelRatio

    // Add main layer
    const base = await createLayer(PlanLayers.Base, this, layout, floor, onData)
    stage.add(base.content)
    base.render(this)
    this.refreshEquipmentHierarchy()

    // Add default layers (for performance reasons they're Konva.Group instances rather than actual layers!)
    const backgroundLayer = await createLayer(PlanLayers.Background, this, layout, floor, onData)
    const radiationLayer = await createLayer(PlanLayers.Radiation, this, layout, floor, onData)
    const itemLayer = await createLayer(PlanLayers.Items, this, layout, floor, onData)
    const selection = await createLayer(PlanLayers.Selection, this, layout, floor, onData)

    const layers = [
      radiationLayer,
      backgroundLayer,
      itemLayer,
      selection
    ]

    this.base = base
    this.stage = stage
    this.layers = sortItems(layers, ({ item }) => item.order)
    this.radiationLayer = radiationLayer
    this.backgroundLayer = backgroundLayer
    this.itemLayer = itemLayer
    this.selection = selection

    // Render element shapes
    for (const shape of this.elementShapes) {
      await shape.render(this)
    }

    // Render connectors after the shapes, as they require shapes to be in their final positions
    for (const shape of this.connectorShapes) {
      await shape.render(this)
    }

    // Add layers to base layer
    for (const layer of this.layers) {
      layer.render(this)
      base.content.add(layer.content)
      layer.reorder()
    }

    // Wire up event handlers
    this.bindEvents()

    // Apply zoom
    this.setZoom()

    this.isRendering = false
    this.isRendered = true
  }

  /**
   * Renders the cross-section view
   * @returns {Promise}
   */
  async renderCrossSection () {
    this.isRendering = true

    const { layout, floor, container, onData } = this
    const { width, height } = floor.canvasDimensions
    const stage = new Konva.Stage({
      container,
      width: width || container.offsetWidth,
      height: height || container.offsetHeight
    })

    // Set global pixel ration for new layers
    Konva.pixelRatio = window.devicePixelRatio

    // Add main layer
    const base = await createLayer(PlanLayers.Base, this, layout, floor, onData)
    stage.add(base.content)
    base.render(this)
    this.refreshEquipmentHierarchy()

    // Add default layers (for performance reasons they're Konva.Group instances rather than actual layers!)
    const itemLayer = await createLayer(PlanLayers.Items, this, layout, floor, onData)
    const selection = await createLayer(PlanLayers.Selection, this, layout, floor, onData)
    // We still create the other layers for code simplicity, just won't show them
    const backgroundLayer = await createLayer(PlanLayers.Background, this, layout, floor, onData)
    const radiationLayer = await createLayer(PlanLayers.Radiation, this, layout, floor, onData)

    const layers = [
      itemLayer,
      selection
    ]

    this.base = base
    this.stage = stage
    this.layers = sortItems(layers, ({ item }) => item.order)
    this.itemLayer = itemLayer
    this.radiationLayer = radiationLayer
    this.backgroundLayer = backgroundLayer
    this.selection = selection

    // Add layers to base layer
    for (const layer of this.layers) {
      layer.render(this)
      base.content.add(layer.content)
      layer.reorder()
    }

    // Render element shapes
    for (const shape of this.elementShapes) {
      await shape.render(this)
    }

    // Render connectors after the shapes, as they require shapes to be in their final positions
    for (const shape of this.connectorShapes) {
      await shape.render(this)
    }

    // Forcefully recalculate cable lengths for all cross-floor cables
    for (const shape of this.connectorShapes) {
      const { item } = shape
      if (item.isConnector && item.isCrossFloor) {
        // If cable is inside-floor cable, calculate real length
        item.realLength = shape.getLength({ renderer: this, meters: true })
        delete item.defaultLength
        await shape.render(this)
      }
    }

    // Wire up event handlers
    this.bindEvents()

    // Apply zoom
    this.setZoom()

    this.isRendering = false
    this.isRendered = true
  }

  /**
   * Cleans the layers of shapes which are no longer in the layout.
   * Used in situations such as re-rendering automatically created/destroyed items
   * such as risers and their connectors
   */
  cleanup () {
    const { layout, shapes } = this
    for (const shape of shapes) {
      const shapeItem = shape.item
      if (shapeItem) {
        const item = layout.getItem(shapeItem.id)
        if (!item) {
          const layer = this.getItemLayer(shapeItem)
          if (layer) {
            layer.remove(shape)
          }
        }
      }
    }
  }

  /**
   * Updates the data of the specified plan item
   * @param {PlanItem} item Plan item to update
   * @param {Object} data Data to assign to the item
   */
  updateData (item, data) {
    if (item && data) {
      item.setData(data)
      this.refreshItem(item)
      this.log(`[${item.type}:${item.id}] Data updated`, data)
    }
  }

  /**
   * Action history, used for undo and redo
   * @type {PlanActionHistory}
   */
  history

  /**
   * Undoes the last action
   */
  undo () {
    if (this.isAddingConnector) {
      this.removeLastConnectorTurn()
      return
    } else if (this.isAddingPoints) {
      this.removeLastPoint()
      return
    }

    this.history.undo({ renderer: this })
  }

  /**
   * Redoes the last action
   */
  redo () {
    this.history.redo({ renderer: this })
  }


  /**
   * Re-renders the existing plan shapes
   * @param {Boolean} preserveSelection If true, the current selection will be preserved
   */
  async refresh (preserveSelection) {
    const { shapes, selection, radiationLayer } = this

    if (!preserveSelection) {
      selection.deselect()
    }

    for (const shape of shapes) {
      await shape.render(this)
    }

    selection.refresh(this)
    radiationLayer.refresh(this)
  }

  /**
   * Wires up event handlers to {@link shapes}
   */
  bindEvents () {
    if (this.__eventsBound) return
    const { stage, selection, container, layers: layers } = this

    // Bind keyboard and mouse events
    this.keyboardEvents.bindEvents()
    this.mouseEvents.bindEvents()

    // Bind global stage events
    stage.on('mousedown', (e) => {
      this.isUserActive = true
      this.focus()

      if (PlanEvent.isEventHandled(e)) return

      // When left button pressed ...
      if (PlanEvent.isLeftButton(e)) {
        // Hide context menus, selections etc.
        this.hideContextMenus()

        // Store the clicked position
        const position = this.currentPosition
        this.selectPosition(position)

        // If drawing a connector, add the clicked point to connector turns
        if (this.isAddingConnector) {
          this.addConnectorTurn(position)
        }
        // Other actions available when plan is editable
        else if (this.isEditable) {
          // Deselect current selection
          if (!this.selection.isSelecting) {
            this.deselect()
          }

          // Finish any ongoing editing
          this.reset()

          // Add item in the position, if initiated
          if (this.isAddingItem) {
            this.addItem(position, e)

          }

          // We're now selecting transparent colors.
          // Get the color of the clicked point and add to the background image definition.
          if (this.isSettingTransparentColors) {
            this.reset()
            this.addTransparentColor(this.getBackgroundColor())
          }

          // We're now drawing map scale
          if (this.isDrawingMapScale) {
            this.reset()
            this.addScalePoint(this.currentPosition)
          }
        }
      }

      // When right button pressed ...
      if (PlanEvent.isRightButton(e)) {
        // If drawing a connector, line or polygon, cancel the last added point
        if (this.isAddingConnector) {
          this.removeLastConnectorTurn()
          PlanEvent.cancelEvent(e)
          return
        } else if (this.isAddingPoints) {
          this.removeLastPoint()
          PlanEvent.cancelEvent(e)
          return
        }

        // Intercept, to prevent context menu,
        // unless item clicked
        const item = this.getItemOf(e.target)
        if (!item) {
          PlanEvent.cancelEvent(e)
        }
      }

      this.__lastClick = new Date()
    })

    stage.on('mousemove', (e) => {
      const position = this.currentPosition
      if (this.__debug) {
        this.events.notifyEvent('position', { renderer: this, position })
      }

      // If we're drawing the map scale, update the distance line
      if (this.isDrawingMapScale) {
        if (this.markerCount === 2) {
          const from = this.getMarkerPosition(0)
          const to = position
          this.updateMarker(1, to)
          this.showDistanceLine([from, to], distance => `${distance}px = 1m`)
        }
        return
      }

      if (!this.isEditable) {
        return
      }

      // Ignore whenever we're transforming items
      if (this.transformedItem) {
        return
      }

      // If we're dragging a shape point, update the shape
      if (this.selectedPoint != null) {
        this.movePoint(this.selectedItem, this.selectedPoint, position)
        return
      }

      // If we're adding a point-based item, move the last added point
      // to the mouse position, so it follows the mouse
      if (this.isAddingPoints) {
        const item = this.__addedItem
        this.movePoint(item, item.lastPointIndex, position)
        return
      }

      // If selecting, update the selection box
      if (PlanEvent.isLeftButton(e)) {
        if (!selection.isMoving) {
          if (selection.isSelecting) {
            selection.update(position)
            return
          } else {
            // If not selecting, start drawing selection box
            selection.start(position)
          }
        }
      }

      // If we're creating a new connector, update its end point
      if (this.isAddingConnector) {
        this.updateConnector()
      }

      // If dragging with right mouse button, hide context menus
      // and start scrolling the container
      if (PlanEvent.isRightButton(e)) {
        this.hideContextMenus()
        container.classList.add('panning')
        container.scrollBy(-e.evt.movementX * 2, -e.evt.movementY * 2)
      }
    })

    stage.on('mouseup', () => {
      const position = this.currentPosition

      // The user might have been dragging the map scale line
      // with mouse button pressed. In such case, finish the line once the user released the mouse button.
      if (this.isDrawingMapScale) {
        const from = this.getMarkerPosition(0)
        const to = position
        if (from && !from.sameAs(to)) {
          this.addScalePoint(to)
        }
      }

      // Remove panning class from container
      container.classList.remove('panning')

      // Actions not available when plan is editable
      if (!this.isEditable) {
        return
      }

      // Update legends after moving points or items,
      // cable lengths might need an update
      if (this.selectedPoint != null || this.isAddingPoints) {
        this.refreshCables()
        this.refreshLegends()
      }

      // Finish eventual selection by mouse
      if (selection.isSelecting && !selection.isMoving) {
        selection.finish(this, position)
      }

      // Cancel any element selections
      this.selectPoint()
      this.selectPort()

      this.isUserActive = false
    })

    stage.on('dblclick', async () => {
      // Finish lines and polygons
      if (this.isDrawingLine) {
        await this.finishAddingItem()
        return
      }

      // Actions not available when plan is editable
      if (!this.isEditable) {
        return
      }
    })

    // Bind context menu event
    stage.on('contextmenu', (e) => {
      this.hideContextMenus()

      // Actions not available when plan is editable
      if (!this.isEditable) {
        return
      }

      const item = this.getItemOf(e.target)
      if (item) {
        this.selectItem({ item, position: this.currentPosition, e })
      } else {
        PlanEvent.cancelEvent(e)
      }
    })

    // Bind selection layer events
    selection.addEventListener('moving', (e) => {
      const { items } = e.detail || {}
      this.movingItems(items)
    })

    selection.addEventListener('move', (e) => {
      const { items, delta } = e.detail || {}
      this.isMovingItems = true
      for (const item of items) {
        this.moveItem(item, delta)
      }
      this.isMovingItems = false
    })

    selection.addEventListener('moved', (e) => {
      const { items } = e.detail || {}
      for (const item of items) {
        this.itemMoved(item)
      }
      this.selection.refresh()
    })

    // Bind shape events
    for (const layer of layers) {
      for (const shape of layer.shapes) {
        this.bindShapeEvents(layer, shape)
      }
    }

    // Bind scroll event of the container
    this.container.addEventListener('scroll', throttle(() => {
      this.hideContextMenus()
    }, 50))

    this.__eventsBound = true
  }

  /**
   * Wires up event handlers to the specified shape
   * @param {PlanLayer} layer Layer to which the shape belongs
   * @param {Shape} shape Shape to bind events to
  */
  bindShapeEvents (layer, shape) {
    if (layer.isLocked) return

    shape.addEventListener('enter', (e) => {
      if (!this.isEditable) return
      if (this.transformedItem) return
      if (this.isAddingPoints) return

      this.pointAtItem(e.detail.item)
    })

    shape.addEventListener('exit', () => {
      if (!this.isEditable) return
      if (this.transformedItem) return
      if (this.isAddingPoints) return

      this.pointAtItem()
    })

    shape.addEventListener('select', (e) => {
      if (!this.isEditable) return

      const { item, multiSelect, allowNewPoint } = e.detail

      this.focus()
      this.hideContextMenus()

      // If adding connector, pass through,
      // no other interactions must be allowed
      if (this.isAddingConnector) {
        return
      }

      // If adding items, just add the item or point
      // at the clicked place, regardless whether there is already something else.
      // This is complementary to `stage.mousedown` which handles adding on an empty spot.
      if (this.isAddingItem) {
        this.addItem(this.currentPosition, e)
        return
      }

      // If clicked on a selected group, show the context menu for the entire selection
      if (this.isItemSelected(item)) {
        const position = this.currentPosition
        const items = this.selectedItems
        this.showItemsMenu({ items, position, e })

      } else {
        // ...otherwise select the clicked item
        this.selectItem({
          item,
          position: this.currentPosition,
          allowNewPoint: !multiSelect && allowNewPoint !== false,
          multiSelect,
          e
        })
      }
    })

    shape.addEventListener('deselect', async () => {
      if (!this.isEditable) return

      // Deselect any selected items
      this.selectItem()

      // If adding points to shape, finish the shape now.
      // This is needed on top of `stage.dblclick` handled above,
      // as mouse sometimes lands on the stage, other times on the element itself.
      const addedItem = await this.finishAddingItem()
      if (addedItem) {
        this.selectItem({ item: addedItem })
      }
    })

    shape.addEventListener('select-port', (e) => {
      if (!this.isEditable) return

      this.focus()
      const { item, port } = e.detail || {}
      if (item && port) {
        // When port has been dragged, block any other dragging
        // and initiate a connector from the selected item
        const position = this.currentPosition
        this.startConnector(item, port, position)
        PlanEvent.cancelEvent(e)
      }
    })

    shape.addEventListener('connect-to-port', (e) => {
      if (!this.isEditable) return

      const { item, port } = e.detail || {}
      const { newConnector } = this

      // Connector was dragged from one item to another, seal it now
      const canConnect = newConnector &&
        item &&
        port &&
        newConnector.start.item !== item

      if (canConnect) {
        this.endConnector(item, port)
      } else {
        // ... but if anything is missing, cancel the connector
        this.cancelConnector()
      }
    })

    shape.addEventListener('select-point', (e) => {
      if (!this.isEditable) return

      this.focus()
      this.hideContextMenus()
      const { item, index } = e.detail || {}
      if (item && index > -1) {
        this.selectItem({
          item,
          position: this.currentPosition,
          point: index,
          e
        })
      }
    })

    shape.addEventListener('deselect-point', () => {
      if (!this.isEditable) return

      // If point was created and mouse was released in the same position, remove the point
      const position = this.currentPosition
      const { item, index, position: createdAt } = this.__pointCreated || {}
      if (createdAt?.isNearby(position, 4)) {
        this.cancelPoint(item, index)
      }

      this.selectPoint()
    })

    shape.addEventListener('remove-point', async (e) => {
      if (!this.isEditable) return

      this.hideContextMenus()
      const { item, index } = e.detail || {}
      if (item && index > -1) {
        await this.removePoint({ item, point: index })
      }
    })

    shape.addEventListener('transform-start', (e) => {
      if (!this.isEditable) return

      const { item } = e.detail
      this.focus()
      this.hideContextMenus()
      this.itemTransforming(item)
    })

    shape.addEventListener('transform', (e) => {
      if (!this.isEditable) return

      const { item } = e.detail
      this.itemTransformed(item)
    })

    shape.addEventListener('transform-end', (e) => {
      if (!this.isEditable) return

      const { item } = e.detail
      this.itemTransformed(item)
      this.itemTransforming()
    })
  }
}
