<script>
import { CableType, CableName } from '@stellacontrol/planner'
import ItemPropertiesMixin from './item-properties.js'
import ItemProperties from './item-properties.vue'
import LineStyleProperties from './line-style-properties.vue'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  components: {
    'sc-item-properties': ItemProperties,
    'sc-line-style-properties': LineStyleProperties
  },

  computed: {
    defaults () {
      return this.item.defaults
    },

    // Available cable types
    cableTypes () {
      return [
        // { value: CableType.SD200, label: CableName[CableType.SD200] },
        { value: CableType.SD400, label: CableName[CableType.SD400] },
        { value: CableType.SD600, label: CableName[CableType.SD600] },
        { value: CableType.SD900, label: CableName[CableType.SD900] },
        { value: CableType.Custom, label: CableName[CableType.Custom] }
      ]
    }
  }
}

</script>

<template>
  <sc-item-properties :renderer="renderer" :items="items" @action="executeAction">
  </sc-item-properties>

  <main class="plan-item-properties">
    <section>
      <label>
        Cable Type
      </label>
      <q-select outlined square dense emit-value map-options :options="cableTypes"
        :style="styles.select.wide" :model-value="getValue('cableType', defaults.cableType)"
        @update:model-value="value => setProperties({ 'cableType': value })">
      </q-select>
    </section>
  </main>

  <sc-line-style-properties :renderer="renderer" :items="items" @action="executeAction" v-if="item.isCustom">
  </sc-line-style-properties>
</template>

<style lang="scss" scoped></style>
