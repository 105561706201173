import Konva from 'konva'
import { PlanItemType, SplitterType, PlanPortType, PlanLocation } from '@stellacontrol/planner'
import { Shape } from './shape'
import { ShapeLayout } from './shape-layout'

/**
 * Cable splitter
 * @param {PlanSplitter} item Plan item details
 */
export class SplitterShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Splitter
  }

  __layout

  /**
   * Shape layout
   * @type {ShapeLayout}
   */
  get layout () {
    const { item, item: { splitterType } } = this

    const shape = { x: 0, y: 0, width: 80, height: 40 }

    if (shape.width !== item.width || shape.height !== item.height) {
      throw new Error(`[${splitterType}] Item dimensions here are different than in the item definition`)
    }

    // Return the existing layout if unchanged
    if (this.__layout && this.__layout.splitterType === splitterType) {
      return this.__layout
    }

    // Prepare the shape layout
    let layout

    // 2-way splitter
    if (item.splitterType === SplitterType.SplitterTwoWay) {
      layout = new ShapeLayout({
        splitterType,
        shape,
        ports: [
          {
            id: PlanPortType.In,
            location: PlanLocation.Top,
            shape: { x: 40, y: -6 }
          },
          {
            id: '1',
            location: PlanLocation.Bottom,
            shape: { x: 20, y: 46 }
          },
          {
            id: '2',
            location: PlanLocation.Bottom,
            shape: { x: 60, y: 46 }
          }
        ]
      })

    } else if (item.splitterType === SplitterType.SplitterThreeWay) {
      layout = new ShapeLayout({
        splitterType,
        shape,
        ports: [
          {
            id: PlanPortType.In,
            location: PlanLocation.Top,
            shape: { x: 40, y: -6 }
          },
          {
            id: '1',
            location: PlanLocation.Bottom,
            shape: { x: 15, y: 46 }
          },
          {
            id: '2',
            location: PlanLocation.Bottom,
            shape: { x: 40, y: 46 }
          },
          {
            id: '3',
            location: PlanLocation.Bottom,
            shape: { x: 65, y: 46 }
          }
        ]
      })

    } else if (item.splitterType === SplitterType.SplitterFourWay) {
      layout = new ShapeLayout({
        splitterType,
        shape,
        ports: [
          {
            id: PlanPortType.In,
            location: PlanLocation.Top,
            shape: { x: 40, y: -6 }
          },
          {
            id: '1',
            location: PlanLocation.Left,
            shape: { x: -6, y: 20 }
          },
          {
            id: '2',
            location: PlanLocation.Bottom,
            shape: { x: 25, y: 46 }
          },
          {
            id: '3',
            location: PlanLocation.Bottom,
            shape: { x: 55, y: 46 }
          },
          {
            id: '4',
            location: PlanLocation.Right,
            shape: { x: 86, y: 20 }
          }
        ]
      })

    } else if (item.splitterType === SplitterType.TapperTwoWay) {
      layout = new ShapeLayout({
        splitterType,
        shape,
        ports: [
          {
            id: PlanPortType.In,
            location: PlanLocation.Left,
            shape: { x: -6, y: 20 }
          },
          {
            id: '1',
            location: PlanLocation.Bottom,
            shape: { x: 20, y: 46 }
          },
          {
            id: '2',
            location: PlanLocation.Right,
            shape: { x: 86, y: 20 }
          }
        ]
      })

    } else {
      throw new Error(`Unsupported splitter type [${splitterType}]`)
    }

    this.__layout = layout
    return layout
  }

  // Used to detect a change in splitter type,
  // when ports need to be re-created
  __splitterType

  get defaults () {
    return {
      ...super.defaults
    }
  }

  /**
   * Shape representing the splitter chassis
   * @type {Konva.Rect}
   */
  chassisShape

  /**
   * Main shape in the {@link content}, such as device chassis etc.
   * @type {Konva.Shape}
   */
  get main () {
    return this.chassisShape
  }

  /**
   * Creates all shapes making up the splitter
   */
  createShapes () {
    super.createShapes()

    const { item } = this
    this.chassisShape = new Konva.Rect()

    this.__splitterType = item.splitterType
    this.ports = (item.ports || []).map(port => new Konva.Rect({ id: port.id }))

    this.content.add(
      this.chassisShape,
      ...this.ports
    )
  }

  /**
   * Indicates whether shape needs to be (partially) recreated,
   * i.e. after changing the splitter type
   * @type {Boolean}
   */
  get requiresRefresh () {
    return super.requiresRefresh ||
      this.__splitterType !== this.item.splitterType
  }

  /**
   * Splitter labels need a border as they're outside
   * @type {Number}
   */
  get labelMargin () {
    return 5
  }

  /**
   * Returns coordinates of the splitter label
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Point}
   */
  getLabelPosition (renderer) {
    const { labelText, labelBorder, item } = this
    const position = super.getLabelPosition(renderer)

    // Place the label above the shape, unless the user has modified the position
    if (!item.labelPosition) {
      position.moveTo({ y: -labelText.height() - labelBorder.height() })
    }

    return position
  }

  /**
   * Renders the splitter shape
   */
  render (renderer) {
    super.render(renderer)
    const { chassisShape, item } = this

    if (chassisShape && item) {
      const { lineStyle, backgroundStyle } = item
      chassisShape.width(item.width)
      chassisShape.height(item.height)
      chassisShape.fill(backgroundStyle.color)
      chassisShape.stroke(lineStyle.color)
      chassisShape.strokeWidth(lineStyle.width)
      chassisShape.dash(lineStyle.dash)
      chassisShape.cornerRadius(lineStyle.radius)
    }
  }

  /**
   * Triggered when shape has been transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanScale} scale Scale of the shape
   * @param {Number} rotation Shape rotation
   */
  transformed ({ renderer, scale, rotation }) {
    super.transformed({ renderer, scale, rotation })

    // Change the sizes
    const { item } = this
    item.width = Math.round(item.width * scale.x)
    item.height = Math.round(item.height * scale.y)

    // Re-render the shape
    this.render(renderer)
  }
}
