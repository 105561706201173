import { Log, getId, parseDate, parseBoolean, safeParseInt, round, snapPoint, Point, Rectangle, Color, DarkColorPalette, distinctItems, sortItems, countAndPluralize } from '@stellacontrol/utilities'
import { Assignable, DeviceType, DeviceName } from '@stellacontrol/model'
import { PlanItemType } from '../items/plan-item'
import { PlanScale } from './plan-scale'
import { PlanFloor, PlanFloors } from './plan-floor'
import { PlanCrossSection } from './plan-cross-section'
import { AntennaType, AntennaName } from '../items/plan-antenna'
import { SplitterType, SplitterName } from '../items/plan-splitter'
import { CableType, CableName } from '../items/plan-cable'
import { CableLengthMode } from './cable-length-mode'
import { PlanRiser } from '../items/plan-riser'
import { PlanHierarchyBuilder } from './equipment-hierarchy'

const MAIN_FLOOR = 'Floor Plan'
const GROUND_FLOOR = 'Ground Floor'

/**
 * Plan layout - items, their coordinates and connectors
 */
export class PlanLayout extends Assignable {
  constructor (data = {}, name) {
    super(data)
    this.assign(data, {
      modifiedAt: parseDate
    })
    this.name = name || data.name
  }

  /**
   * Object defaults
   */
  get defaults () {
    return {
      smartLayout: true,
      scale: PlanScale.from(0.4),
      showGrid: true,
      gridSize: 10,
      minGridSize: 10,
      cableLengths: CableLengthMode.Actual,
      showTags: true,
      showRisers: true,
      tagColors: {},
      floors: [
        new PlanFloor({
          id: PlanFloors.Main,
          label: MAIN_FLOOR
        })
      ]
    }
  }

  normalize () {
    super.normalize()

    const { defaults } = this

    this.id = this.id || getId('plan')
    this.version = this.version || 1
    this.smartLayout = parseBoolean(this.smartLayout, defaults.smartLayout)

    // Grid size
    this.gridSize = Math.max(defaults.minGridSize, safeParseInt(this.gridSize, defaults.gridSize))
    this.showGrid = this.showGrid != null ? parseBoolean(this.showGrid) : defaults.showGrid

    // Parse items scale
    this.scale = this.cast(this.scale, PlanScale, defaults.scale)

    // Show cable lengths
    this.cableLengths = this.cableLengths || defaults.cableLengths
    if (!(this.cableLengths === CableLengthMode.Actual || this.cableLengths === CableLengthMode.Off)) {
      this.cableLengths = CableLengthMode.Actual
    }

    // Show risers and riser cables
    this.showRisers = parseBoolean(this.showRisers, defaults.showRisers)

    // Show equipment tags
    this.showTags = parseBoolean(this.showTags, defaults.showTags)
    this.tagColors = this.tagColors || defaults.tagColors

    // Parse floors
    this.floors = this.castArray(this.floors, PlanFloor, defaults.floors)

    // Parse cross-section view
    this.crossSection = this.cast(this.crossSection, PlanCrossSection, new PlanCrossSection())
    this.crossSection.layout = this

    // Associate connectors with their items
    this.itemIndex = safeParseInt(this.itemIndex, 0)
    const itemIds = {}
    for (const item of this.items) {
      itemIds[item.id] = true
      if (!item.index) {
        item.index = ++this.itemIndex
      }
      if (item.isConnector) {
        const connector = item
        if (connector.start) {
          connector.start.item = this.getItem(connector.start.itemId)
        }
        if (connector.end) {
          connector.end.item = this.getItem(connector.end.itemId)
        }
      }
    }

    // Post-process floors
    let index = 0
    for (const floor of this.floors) {
      floor.index = index++
      // Enforce the default floor height on the cross-section view (for now, while in future we might allow users to edit it)
      // If the height has changed, move the items accordingly.
      this.setFloorHeight(floor, floor.crossSection.defaults.height)
      // Filter out items which for some reason are corrupted
      floor.items = floor.items.filter(item => item != null && item.isValid)
    }

    // Recalculate floor sizes on the cross-section view,
    // adapt the canvas size of the cross-section view accordingly
    this.setCrossSectionFloorBounds({ adjustItems: true })

    // Create risers for cross-floor connections
    this.refreshRisers()
  }

  /**
   * Prepares the object for JSON serialization
   * @returns {Object}
   */
  toJSON () {
    const result = {
      ...this
    }

    // Delete defaults
    this.clearDefaults(result)

    // Delete RUNTIME properties
    delete result.isDirty
    delete result.isSaving
    delete result.saveError

    return result
  }

  /**
   * Pretty-prints the layout hierarchy
   * @returns {String}
   */
  toString () {
    const { floors, crossSection, items } = this

    const lines = []

    if (crossSection) {
      lines.push(`Floors: ${floors.length}`)
      for (const riser of crossSection.risers) {
        lines.push(`  - Riser ${riser.tag} containing ${countAndPluralize(riser.connectors, 'connector')}`)
      }
    }

    for (const floor of floors.filter(f => f.isFloor)) {
      lines.push(floor.label)

      const equipment = floor.items.filter(i => !i.isPlug && !i.goesIntoRiser && !i.riser)
      if (equipment.length === 0) {
        lines.push('  The floor is empty')

      } else {
        for (const item of equipment) {
          const label = item.toString()
          lines.push(`  - ${label}`)
        }

        const plugs = floor.items.filter(i => i.isPlug)
        const plugCables = floor.items.filter(i => i.goesIntoRiser)
        for (const plug of plugs) {
          const plugRiser = crossSection.getRiser(plug.riser)
          lines.push(`    - Riser Plug ${plugRiser.tag}`)
          for (const plugCable of plugCables.filter(c => c.goesIntoRiser === plug.riser)) {
            const from = floor.items.find(i => i.id === plugCable.start.itemId)
            const to = items.find(i => i.id === plugCable.partOf)?.getOtherItem(from)
            if (from && to) {
              const plug = floor.items.find(p => p.isPlug && p.riser === plugCable.goesIntoRiser)
              const riser = crossSection.risers.find(r => r.id === plugCable.goesIntoRiser)
              const label = `${from.toString()} => ${plugCable.toString(false)} => ${plug.toString()} =>| ${riser.toString()} |=> ${to.toString()}`
              lines.push(`      - ${label}`)
            }
          }
        }
      }
    }

    return lines.join('\n')
  }

  /**
   * Resets the layout to defaults
   */
  reset () {
    const { floors, defaults } = this

    this.scale = PlanScale.Normal
    this.gridSize = defaults.gridSize
    this.showGrid = defaults.showGrid

    for (const floor of floors) {
      floor.reset()
    }
  }

  /**
   * Serializes the item to plain object
   * @returns {Object}
   */
  serialize () {
    return this.toJSON()
  }

  /**
   * Deserializes plan layout from the specified JSON string
   * @param {String} data Serialized plan layout
   */
  static deserialize (data) {
    if (data) {
      return new PlanLayout(JSON.parse(data))
    } else {
      return new PlanLayout()
    }
  }

  /**
   * Unique identifier of the plan
   * @type {String}
   */
  id

  /**
   * Plan name
   * @type {String}
   */
  name

  /**
   * Version number,
   * increased whenever the plan is saved
   * @type {Number}
   */
  version

  /**
   * Last change date and time
   * @type {Date}
   */
  modifiedAt

  /**
   * Indicates that the layout has been changed and needs to be saved
   * @type {Boolean}
   * @description RUNTIME
   */
  isDirty

  /**
   * Indicates that the layout is being saved
   * @type {Boolean}
   * @description RUNTIME
   */
  isSaving

  /**
   * Error caught during save, indicating that save did not succeed
   * @type {Error}
   */
  saveError

  /**
   * User-friendly label
   * @type {String}
   */
  label

  /**
   * More details
   * @type {String}
   */
  details

  /**
   * If true, automatic adjustments to the layout
   * happen as user interacts with the plan,
   * for example rerouting of connectors when elements are dragged etc.
   * @type {Boolean}
   */
  smartLayout

  /**
   * Plan items scale
   * @type {PlanScale}
   */
  scale

  /**
   * Returns items scale applicable for the specified floor.
   * For some of them scaling should be disabled, for example cross-section doesn't need it.
   * @param {PlanFloor} floor Floor to get the item scaling for
   * @returns {PlanScale}
   */
  getScale (floor) {
    return floor?.isCrossSection
      ? PlanScale.Normal
      : this.scale
  }

  /**
   * Display grid lines
   * @type {Boolean}
   */
  showGrid

  /**
   * Grid size, in pixels
   * @type {Number}
   */
  gridSize

  /**
   * Dictionary of tag colors. Items with the same tag will be using the same tag color.
   * @type {Dictionary<String, String>}
   */
  tagColors

  /**
   * Cable lengths view
   * @type {CableLengthMode}
   */
  cableLengths

  /**
   * Determines whether we're displaying actual cable lengths
   * @type {Boolean}
   */
  get realCableLengths () {
    return this.cableLengths === CableLengthMode.Actual
  }

  /**
   * Determines whether we're displaying no cable lengths
   * @type {Boolean}
  */
  get noCableLengths () {
    return this.cableLengths === CableLengthMode.Off
  }

  /**
   * Show risers and riser cables
   * @type {Boolean}
   */
  showRisers

  /**
   * Show equipment tags
   * @type {Boolean}
   */
  showTags

  /**
   * If true, setting individual antenna strength per floor is allowed.
   * Otherwise the same antenna strength is applied all over the plan.
   * @type {Boolean}
   */
  allowAntennaStrengthPerFloor

  /**
   * Plan floors
   * @type {Array[PlanFloor]}
   */
  floors

  /**
   * Floors in order from top floor to the bottom floor
   * @type {Array[PlanFloor]}
   */
  get topToBottom () {
    return [...(this.floors || [])].reverse()
  }

  /**
   * Cross-section view layout
   * @type {PlanCrossSection}
   */
  crossSection

  /**
   * Item counter, increased monotonically and assigned to newly added items,
   * can be used to ensure the right item order in certain scenarios
   * @type {Number}
   */
  itemIndex

  /**
   * All items on the plan
   * @type {Array[PlanItem]}
   */
  get items () {
    const { floors } = this
    return floors.flatMap(floor => floor.items || [])
  }

  /**
   * All connectors on the plan
   * @type {Array[PlanConnector]}
   */
  get connectors () {
    return this.items.filter(i => i.isConnector)
  }

  /**
   * All cross-floor connectors
   * @type {Array[PlanConnector]}
   */
  get crossFloorConnectors () {
    return this.connectors.filter(i => i.isCrossFloor)
  }

  /**
   * Cable risers
   * @type {Array[PlanRiser]}
   */
  get risers () {
    return this.crossSection.risers
  }

  /**
   * All items on the plan which are visible on the cross-section view
   * @type {Array[PlanItem]}
   */
  get crossSectionItems () {
    return this.items.filter(i => i.showOnCrossSection)
  }

  /**
   * Returns the number of the floors
   * @returns {Number}
   */
  get floorCount () {
    return this.floors.length
  }

  /**
   * Checks whether there's only one floor on the plan
   * @returns
   */
  get hasOneFloor () {
    return this.floorCount === 1
  }

  /**
   * Returns the specified floor
   * @param {String} floorId Floor identifier
   * @returns {PlanFloor}
   */
  getFloor (id) {
    const { floors } = this
    return floors.find(f => f.id === id)
  }

  /**
   * Checks whether the specified floor exists
   * @param {String} floorId Floor identifier
   * @returns {Boolean}
   */
  hasFloor (id) {
    const { floors } = this
    return floors.some(f => f.id === id)
  }

  /**
   * Returns the index of the specified floor
   * @param {String} floorId Floor identifier
   * @returns {Number}
   */
  getFloorIndex (id) {
    const { floors } = this
    return floors.findIndex(f => f.id === id)
  }

  /**
   * Checks whether the floor is above the specified one
   * @param {PlanFloor} floor Floor to check
   * @param {PlanFloor} other Floor to check against
   * @returns {Boolean}
   */
  isFloorAbove (floor, other) {
    return floor && other && this.getFloorIndex(floor.id) < this.getFloorIndex(other.id)
  }

  /**
   * Checks whether the floor is below the specified one
   * @param {PlanFloor} floor Floor to check
   * @param {PlanFloor} other Floor to check against
   * @returns {Boolean}
   */
  isFloorBelow (floor, other) {
    return floor && other && this.getFloorIndex(floor.id) > this.getFloorIndex(other.id)
  }

  /**
   * Returns the main floor
   * @returns {Array[PlanFloor]}
   */
  getMainFloor () {
    return this.getFloor(PlanFloors.Main)
  }

  /**
   * Returns the first floor
   * @returns {Array[PlanFloor]}
   */
  getFirstFloor () {
    const { floors } = this
    return floors[0]
  }

  /**
   * Returns the last floor
   * @returns {Array[PlanFloor]}
   */
  getLastFloor () {
    const { floors } = this
    return floors[floors.length - 1]
  }

  /**
   * Checks if the specified floor is the first one
   * @param {String} floorId Floor identifier
   * @returns {Boolean}
   */
  isFirstFloor (id) {
    return id && this.getFirstFloor()?.id === id
  }

  /**
   * Checks if the specified floor is the last one
   * @param {String} floorId Floor identifier
   * @returns {Boolean}
   */
  isLastFloor (id) {
    return id && this.getLastFloor()?.id === id
  }

  /**
   * Returns the floor on which the specified items are found.
   * If items are on different floors, no floor is returned.
   * This can be used to check whether items belong to the same floor.
   * @param {Array[PlanItem]} items Plan items
   * @returns {PlanFloor}
  */
  getFloorOf (...items) {
    const { floors } = this
    const itemFloors = distinctItems(items.map(item => floors.find(f => f.hasItem(item))), 'id')
    return itemFloors.length === 1 ? itemFloors[0] : null
  }

  /**
   * Returns the floors which the specified connector connects.
   * If connector is a cross-floor connector, it will return more than one floor!
   * @param {PlanConnector} connector Connector
   * @returns {Object} Object `{ from, to, floors }` where:
   * `from` is the floor where the connector starts
   * `to` is the floor where the connection ends
   * `floors` are all the floors which the connector passes
  */
  getConnectorFloors (connector) {
    const result = {}

    if (connector?.isValid) {
      result.from = this.getFloor(connector.start.item.floorId)
      result.to = this.getFloor(connector.end.item.floorId)
      const fromIndex = this.floors.indexOf(result.from)
      const toIndex = this.floors.indexOf(result.to)
      result.floors = sortItems(
        fromIndex <= toIndex
          ? this.floors.slice(fromIndex, toIndex + 1)
          : this.floors.slice(toIndex, fromIndex + 1),
        'index'
      )
    }

    return result
  }

  /**
   * Returns items which belong to the specified floor
   * @param {String} floorId Floor identifier
   * @returns {Array[PlanItem]}
   */
  getItemsOn (floorId) {
    const floor = this.getFloor(floorId)
    return floor?.items || []
  }

  /**
   * Returns item with the specified id
   * @param {String} id Item identifier
   * @returns {PlanItem}
  */
  getItem (id) {
    return this.items.find(item => item.id === id)
  }

  /**
   * Finds an item matching the specified predicate
   * @param {Function<PlanItem, Boolean>} predicate Predicate to check
   * @param {String} floorId If specified, the search is limited only to the specified floor
   * @returns {PlanItem}
   */
  find (predicate, floorId) {
    return predicate
      ? this.items.find(item => (!floorId || item.floorId === floorId) && predicate(item))
      : null
  }

  /**
   * Finds all items matching the specified predicate
   * @param {Function<PlanItem, Boolean>} predicate Predicate to check
   * @param {String} floorId If specified, the search is limited only to the specified floor
   * @returns {Array[PlanItem]}
   */
  filter (predicate, floorId) {
    return predicate
      ? this.items.filter(item => (!floorId || item.floorId === floorId) && predicate(item))
      : []
  }

  /**
   * Determines whether the specified item should be visible on the currently displayed plan
   * @param {PlanItem} item Plan item
   * @param {Boolean} isCrossSection Indicates whether we're looking at the item on the cross-section view
   * @returns {Boolean}
   */
  // eslint-disable-next-line no-unused-vars
  isItemVisible ({ item, isCrossSection }) {
    // The item should be generally not marked as hidden...
    if (item && item.isVisible) {
      // ... then other special rules might apply:

      // Show/Hide riser plugs and riser cables
      if (!isCrossSection) {
        const { showRisers } = this
        if (!showRisers) {
          if (item.isPlug || (item.isConnector && item.partOf)) {
            return false
          }
        }
      }

      return true
    }

    return false
  }

  /**
   * Returns riser with the specified id
   * @param {String} id Riser identifier
   * @returns {PlanRiser}
   */
  getRiser (id) {
    return this.crossSection.getRiser(id)
  }

  /**
   * Returns the riser which contains the specified cross-floor connector
   * @param {PlanConnector} connector Plan connector
   * @returns {PlanRiser}
   */
  getRiserOf (connector) {
    if (connector?.partOf) {
      connector = this.getItem(connector.partOf)
    }
    if (connector) {
      return this.crossSection.risers.find(r => r.contains(connector))
    }
  }

  /**
   * Returns ending connectors related to the specified cross-floor connector,
   * representing the final connections between risers and equipment
   * @param {PlanConnector} connector Plan connector
   * @returns {Array[PlanConnector]}
   */
  getConnectorEnds (connector) {
    if (connector.isConnector && connector.isCrossFloor) {
      const ends = this.items.filter(i => i.isConnector && i.partOf === connector.id)
      return ends
    }
    return []
  }

  /**
   * Returns all connectors related to the specified one, including itself.
   * For connectors within the same floor, it returns just the connector.
   * For cross-floor connectors or parts of such a connector,
   * other connector parts will be returned.
   * @param {PlanConnector} connector Plan connector
   * @returns {Array[PlanConnector]}
   */
  getConnectorGroup (connector) {
    const items = []
    // If item is a cross-floor connector or part of such a connector, get other connector parts
    if (connector.isConnector && (connector.isCrossFloor || connector.partOf)) {
      const otherConnectors = connector.isCrossFloor
        ? this.items.filter(i => i.isConnector && i.partOf === connector.id)
        : this.items.filter(i => i.isConnector && i.partOf === connector.partOf || i.id === connector.partOf)
      items.push(...otherConnectors)
    }
    return items
  }

  /**
   * Returns all plugs related to the specified cross-floor connector
   * or its part on a specific floor
   * @param {PlanConnector} connector Plan connector
   * @returns {Array[PlanPlug]}
   */
  getConnectorPlugs (connector) {
    const items = []
    // If item is a cross-floor connector or part of such a connector, get all plugs
    // connected to this connector and all other related connector parts
    if (connector.isConnector && (connector.isCrossFloor || connector.partOf)) {
      const otherConnectors = connector.isCrossFloor
        ? this.items.filter(i => i.isConnector && i.partOf === connector.id)
        : this.items.filter(i => i.isConnector && i.partOf === connector.partOf || i.id === connector.partOf)
      for (const c of [connector, otherConnectors]) {
        if (c.start?.item?.isPlug) items.push(c.start.item)
        if (c.end?.item?.isPlug) items.push(c.end.item)
      }
    }
    return items
  }

  /**
   * Returns the item and all connectors linked to it
   * @param {PlanItem} item Plan item
   * @returns {Array[PlanItem]}
   */
  getItemAndConnectors (item) {
    // Get all items connected to the specified item with connectors
    const items = (this.items || []).filter(i => i.id === item.id || (i.isConnectedTo && i.isConnectedTo(item)))

    return items
  }

  /**
   * Returns all devices on the plan
   * @returns {Array[PlanItem]}
   */
  getDevices () {
    return this.items.filter(item => item.isDevice)
  }

  /**
   * Returns all devices on the plan floor
   * @returns {Array[PlanItem]}
   */
  getFloorDevices (floorId) {
    return this.getItemsOn(floorId).filter(item => item.isDevice)
  }

  /**
   * Returns plan item representing a device with the specified serial number
   * @param {String} serialNumber Device serial number
   * @returns {PlanDevice}
   */
  getDevice (serialNumber) {
    return this.items.find(item => item.isDevice && item.device?.serialNumber === serialNumber)
  }

  /**
   * Returns all connectors connected to the specified item
   * @param {PlanItem} item Plan item
   * @returns {Array[PlanConnector]}
   */
  getConnectorsOf (item) {
    return this.items.filter(i => i.isConnector && i.isConnectedTo(item))
  }

  /**
   * Returns the number of all connectors connected to the specified item
   * @param {PlanItem} item Plan item
   * @returns {Number}
   */
  getConnectorCount (item) {
    return this.getConnectorsOf(item).length
  }

  /**
   * Returns `true` if there any connectors connected to the specified item
   * @param {PlanItem} item Plan item
   * @returns {Array[PlanConnector]}
   */
  hasConnectors (item) {
    return this.getConnectorsOf(item).length > 0
  }

  /**
   * Finds an item to which the specified item is linked with a connector outgoing from the specified port,
   * and the connector connecting to it. Returns an object containing the connected `item` and the `connector` leading to it.
   * @param {PlanItem} item Item where connector goes out
   * @param {PlanPortType} portType Port where the connector goes out
   * @returns {Object}
   */
  getConnectedItem (item, portType) {
    const connector = this.connectors.find(c => c.isConnectedTo(item, portType))
    if (connector) {
      const other = connector.getOtherItem(item)
      return { connector, item: other }
    }
    return {}
  }

  /**
   * Checks whether there exists an item to which the specified item is linked
   * with a connector outgoing from the specified port,
   * and the connector connecting to it
   * @param {PlanItem} item Item where connector goes out
   * @param {PlanPortType} portType Port where the connector goes out
   * @returns {Boolean}
   */
  hasConnectedItem (item, portType) {
    const { item: other } = this.getConnectedItem(item, portType)
    return other != null
  }

  /**
   * Returns a device to which the specified plan item is connected with cables, directly or indirectly
   * @param {PlanItem} item Plan item
   * @returns {PlanDevice}
   */
  getRootDeviceOf (item) {
    if (!item) return
    if (item.root) return this.getItem(item.root)
    if (item.isDevice) return item
    if (item.isConnector) {
      if (item.start?.item?.root) return this.getItem(item.start?.item?.root)
      if (item.end?.item?.root) return this.getItem(item.end?.item?.root)
    }
  }

  /**
   * Returns a complete group of items connected to the same line,
   * from external antenna through repeater, lineamps, splitters and internal antennae
   * @param {PlanItem} item Plan item
   * @param {Array[PlanItem]} group Items found so far, used in recursive calls only
   * @returns {Array[PlanItem]}
   */
  getItemGroup (item, group) {
    if (!item) {
      return group
    }

    // Add the item to the group but leave if item already present - we have a circular reference!
    if (!group) group = []
    if (group.find(i => i.id === item.id)) {
      return group
    }
    group.push(item)

    if (item.isConnector) {
      // Traverse all items linked to the connector
      this.getItemGroup(item.start.item, group)
      this.getItemGroup(item.end.item, group)

    } else {
      // Traverse all connectors leading to the item,
      // skip those which were already added to the group
      // skip virtual connectors from devices to wall plugs, as they're just parts of cross-floor connectors
      const connectors = this
        .getConnectorsOf(item)
        .filter(c => !group.find(i => i.id === c.id))
        .filter(c => !c.partOf)

      for (const connector of connectors) {
        this.getItemGroup(connector, group)
      }
    }

    return group
  }

  /**
   * Creates a clone of the layout
   * @param {Object} data Optional data to insert into the cloned instance
   * @returns {PlanLayout}
   */
  clone (data) {
    return new PlanLayout(
      {
        ...this,
        ...(data || {})
      },
      this.name)
  }

  /**
   * Removes all items from the plan
   */
  clear () {
    for (const floor of this.floors) {
      floor.clear()
    }
  }

  /**
   * Checks whether the plan layout is empty
   * @type {Boolean}
   */
  get isEmpty () {
    return !(this.items.length > 0)
  }

  /**
   * Changes the scale of the plan items
   * @param {Number} value Plan items scale
   * @returns {Boolean} `true` if scale has been changed
   */
  setScale (value) {
    if (value != null) {
      const scale = Math.min(10, Math.max(0.1, value || 1))
      if (this.scale.value !== scale) {
        this.scale.value = scale
        return true
      }
    }
  }

  /**
   * Updates the {@link modifiedAt} time
   * @param {Boolean} bumpVersion If true, also the version number is bumped
   */
  changed (bumpVersion) {
    this.isDirty = true
    this.modifiedAt = new Date()
    if (bumpVersion) {
      this.version++
    }
  }

  /**
   * Indicates that we're now saving the plan
   */
  saving () {
    this.isSaving = true
  }

  /**
   * Indicates that we've saved the plan,
   * clears the {@link isDirty} flag indicating that the layout hasn't been saved yet
   * @param {Error} error Eventual error caught during save, indicating that save did not succeed
   */
  saved (error) {
    this.isDirty = false
    this.isSaving = false
    this.saveError = error
  }

  /**
   * Adds a floor to the layout
   * @param {String} label Floor label
   * @param {String} description Floor description, optional
   * @param {Number} mapScale Floor map scale, optional
   * @returns {PlanFloor}
   */
  addFloor ({ label, description, mapScale }) {
    const { floors } = this

    const floor = new PlanFloor({
      label: label || `Floor ${floors.length}`,
      description,
      isNew: true
    })

    // Change the name of the main floor if it's still at default
    const main = this.getFloor(PlanFloors.Main)
    if (main.label === MAIN_FLOOR) {
      main.label = GROUND_FLOOR
    }

    // Copy map scale from the ground floor
    floor.mapScale = mapScale == null ? main.mapScale : mapScale

    // Add the floor, reindex the floors
    this.floors = [...this.floors, floor].map((floor, index) => {
      floor.index = index
      return floor
    })

    // Recalculate bounds of each of the floor on the cross-section view
    this.setCrossSectionFloorBounds()

    // Recalculate coordinates of items on the cross-section view
    this.moveCrossSectionItems({ x: 0, y: floor.crossSection.height })

    return floor
  }

  /**
   * Removes a floor from the layout
   * @param {Floor} floor Floor to remove
   */
  removeFloor (floor) {
    if (floor?.canDelete) {
      floor.isDeleted = true
      const index = this.getFloorIndex(floor.id)

      // For all connectors going through the floor, remove turns which are on that floor
      this.clearConnectorTurns(floor)

      // Recalculate coordinates of items on floors
      // below the removed floor on the cross-section view
      this.moveCrossSectionItems({ x: 0, y: -floor.crossSection.height }, 0, index)

      // Remove the floor from the layout, reindex the remaining floors
      this.floors = this.floors
        .filter(f => f.id !== floor.id)
        .map((floor, index) => {
          floor.index = index
          return floor
        })

      // Recalculate bounds of each of the floor on the cross-section view
      this.setCrossSectionFloorBounds()

      // If there's only one floor left, rename it back to default
      if (this.floors.length === 1 && this.floors[0].label === GROUND_FLOOR) {
        this.floors[0].label = MAIN_FLOOR
      }
    }
  }

  /**
   * Moves the specified floor before the given one
   * @param {PlanFloor} floor Floor to move
   * @param {PlanFloor} to Target floor
   */
  moveFloorTo (floor, to) {
    const source = this.floors.findIndex(f => f.id === floor.id)
    const target = this.floors.findIndex(f => f.id === to.id)
    const index = target > source ? target + 1 : target
    this.floors = [
      ...this.floors.slice(0, index).filter(i => i.id !== floor.id),
      floor,
      ...this.floors.slice(index).filter(i => i.id !== floor.id)
    ]
  }

  /**
   * Moves the items on cross-section view by a specified delta
   * @param {Point} delta Movement delta
   * @param {Number} from Start index of the floors whose items to move, optional
   * @param {Number} to End index (not inclusive) of the floors whose items to move, optional
   */
  moveCrossSectionItems (delta, from, to) {
    const { floors } = this
    from = from == null ? 0 : from
    to = to == null ? floors.length : to

    for (let i = from; i < to; i++) {
      const floor = floors[i]
      const items = floor.crossSectionItems
      for (const item of items) {
        item.moveBy({ x: delta.x, y: delta.y }, true)
      }
    }
  }

  /**
   * Removes all turns on connectors which pass through the specified floor.
   * This is used for example when the floor has been deleted or moved, to sanitize
   * the connectors at least a bit, otherwise they suddenly go all over the place
   * @param {PlanFloor} floor Floor to clear
   */
  clearConnectorTurns (floor) {
    if (floor) {
      for (const connector of this.connectors) {
        const turns = connector.getTurns(floor.isCrossSection)
        const sanitized = turns.filter(t => !floor.crossSection.contains(t))
        connector.setTurns(sanitized, floor.isCrossSection)
      }
    }
  }

  /**
   * Changes floor dimensions
   * @param {Floor} floor Floor to change
   * @param {Size} size New dimensions of the floor
   */
  setFloorSize (floor, size) {
    floor.setSize(size)
  }

  /**
   * Changes floor height
   * @param {Floor} floor Floor to change
   * @param {Number} height New height of the floor
   */
  setFloorHeight (floor, height) {
    const index = this.getFloorIndex(floor.id)
    const delta = height - floor.crossSection.height

    if (delta !== 0) {
      // Recalculate coordinates of items on floors
      // below the changed floor on the cross-section view
      this.moveCrossSectionItems({ x: 0, y: delta }, 0, index)

      // Set the new height
      floor.crossSection.height = height

      // Indicate changes.
      // This method is called on loading the layout.
      // We want any eventual changes to be saved immediately, without user's action.
      this.changed()
    }
  }

  /**
   * Changes floor margins
   * @param {Floor} floor Floor to change
   * @param {Margin} margin New margins of the floor
   */
  setFloorMargin (floor, margin) {
    if (floor.isCrossSection) {
      // If margins of the cross-section floor changed, modify the coordinates of the items accordingly
      const deltaTop = margin.top != null ? margin.top - floor.margin.top : 0
      const deltaLeft = margin.left != null ? margin.left - floor.margin.left : 0

      // Recalculate coordinates of items on floors
      // below the removed floor on the cross-section view
      this.moveCrossSectionItems({ x: deltaLeft, y: deltaTop })
    }

    floor.setMargin(margin)

    // Recalculate floor bounds for all floors, from top to bottom
    if (floor.isCrossSection) {
      this.setCrossSectionFloorBounds()
    }
  }

  /**
   * Adds an item to the layout
   * @param {PlanItem} item Item to add
   * @param {PlanFloor} floor Floor to add the item to
   * @returns {PlanItem} Added item
   */
  addItem (item, floor) {
    if (floor && item) {
      item.index = ++this.itemIndex
      item.created = new Date()
      floor.addItem(item)
      if (!item.inProgress) {
        this.refreshRisers()
      }
      return item
    }
  }

  /**
   * Removes the specified plan item from the floor
   * @param {PlanItem} item Item to remove
   * @param {PlanFloor} floor Floor to remove the item from
   */
  removeItem (item, floor) {
    if (item && floor) {
      floor.removeItem(item)
      this.refreshRisers()
    }
  }

  /**
   * Snaps a position to the grid
   * @param {Point} position Position to snap
   * @param {Object} options Snap options. If not specified, snap both coordinates to nearest grid line.
   * Otherwise when:
   *   `lower` - then snap to grid line to the left/top of the point
   *   `upper` - then snap to grid line to the right/bottom of the point
   *   `onX`   - if true, we're snapping the X coordinate
   *   `onY`   - if true, we're snapping the Y coordinate
   * @returns {Point} Position snapped to the nearest grid line
   */
  snap (position, { lower, upper, onX, onY } = {}) {
    if (position) {
      const { gridSize } = this
      const newPosition = snapPoint(
        position,
        { x: onX ? gridSize : 1, y: onY ? gridSize : 1 },
        { lower, upper }
      )
      return new Point(newPosition)
    }
  }

  /**
   * Checks whether snapping to grid is now applicable
   * @returns {Boolean}
   */
  get canSnapToGrid () {
    return this.showGrid
  }

  /**
   * Snaps the specified point to the nearest lines of the layout grid
   * @param {Point} point
   * @returns {Point} New point with corrected coordinates
   */
  snapToGrid (point) {
    if (point) {
      const { gridSize } = this
      if (this.canSnapToGrid) {
        const x = Math.round(point.x / gridSize) * gridSize
        const y = Math.round(point.y / gridSize) * gridSize
        return new Point({ x, y })
      } else {
        return new Point(point)
      }
    }
  }

  /**
   * Returns a list of equipment on the plan
   * @param {Array[PlanItem]} items Plan items to include. If not specified, all the items on the plan are counted
   */
  getEquipment (items) {
    // Do not include in the equipment any transient items such as risers, plugs and into-plug cables
    items = (items || this.items).filter(i => !i.isTransient)

    const counters = {
      [DeviceType.Repeater]: { type: PlanItemType.Device, subtype: DeviceType.Repeater, label: DeviceName[DeviceType.Repeater] },
      [DeviceType.LineAmp]: { type: PlanItemType.Device, subtype: DeviceType.LineAmp, label: DeviceName[DeviceType.LineAmp] },

      [AntennaType.IndoorOmni]: { type: PlanItemType.Antenna, subtype: AntennaType.IndoorOmni, label: AntennaName[AntennaType.IndoorOmni] },
      [AntennaType.OutdoorOmni]: { type: PlanItemType.Antenna, subtype: AntennaType.OutdoorOmni, label: AntennaName[AntennaType.OutdoorOmni] },
      [AntennaType.Yagi]: { type: PlanItemType.Antenna, subtype: AntennaType.Yagi, label: AntennaName[AntennaType.Yagi] },
      [AntennaType.WallPanel]: { type: PlanItemType.Antenna, subtype: AntennaType.WallPanel, label: AntennaName[AntennaType.WallPanel] },
      [AntennaType.CeilingPanel]: { type: PlanItemType.Antenna, subtype: AntennaType.CeilingPanel, label: AntennaName[AntennaType.CeilingPanel] },
      [AntennaType.Laser]: { type: PlanItemType.Antenna, subtype: AntennaType.Laser, label: AntennaName[AntennaType.Laser] },

      [SplitterType.SplitterTwoWay]: { type: PlanItemType.Splitter, subtype: SplitterType.SplitterTwoWay, label: SplitterName[SplitterType.SplitterTwoWay] },
      [SplitterType.SplitterThreeWay]: { type: PlanItemType.Splitter, subtype: SplitterType.SplitterThreeWay, label: SplitterName[SplitterType.SplitterThreeWay] },
      [SplitterType.SplitterFourWay]: { type: PlanItemType.Splitter, subtype: SplitterType.SplitterFourWay, label: SplitterName[SplitterType.SplitterFourWay] },
      [SplitterType.TapperTwoWay]: { type: PlanItemType.Splitter, subtype: SplitterType.TapperTwoWay, label: SplitterName[SplitterType.TapperTwoWay] },

      [CableType.SD200]: { type: PlanItemType.Cable, subtype: CableType.SD200, label: `Cable ${CableName[CableType.SD200]}`, unit: 'm' },
      [CableType.SD400]: { type: PlanItemType.Cable, subtype: CableType.SD400, label: `Cable ${CableName[CableType.SD400]}`, unit: 'm' },
      [CableType.SD600]: { type: PlanItemType.Cable, subtype: CableType.SD600, label: `Cable ${CableName[CableType.SD600]}`, unit: 'm' },
      [CableType.SD900]: { type: PlanItemType.Cable, subtype: CableType.SD900, label: `Cable ${CableName[CableType.SD900]}`, unit: 'm' },
      [CableType.Custom]: { type: PlanItemType.Cable, subtype: CableType.Custom, label: `Cable ${CableName[CableType.Custom]}`, unit: 'm' },
      ['pigtail']: { type: 'pigtail', subtype: 'pigtail', label: 'Pigtail', floor: false },
      ['n-connector']: { type: 'n-connector', subtype: 'n-connector', label: 'N-Type Connector', floor: false },
    }

    for (const { type, deviceType, antennaType, splitterType, cableType, realLength, ports } of items) {
      let counter

      if (type === PlanItemType.Device) counter = counters[deviceType]
      else if (type === PlanItemType.Antenna) counter = counters[antennaType]
      else if (type === PlanItemType.Splitter) counter = counters[splitterType]
      else if (type === PlanItemType.Cable) counter = counters[cableType]

      if (counter) {
        if (type === PlanItemType.Cable) {
          // For cables, sum up cable length per type
          if (realLength > 0) {
            counter.count = round((counter.count || 0) + realLength, 2)
          }
        } else {
          // For other items sum their amount
          counter.count = counter.count ? counter.count + 1 : 1
        }
      }

      if (type === PlanItemType.Device) {
        // Count pigtails - one for every taken port on a repeater or lineamp
        const takenPorts = (ports || []).filter(port => this.isPortTaken(port))
        counters['pigtail'].count = (counters['pigtail'].count || 0) + takenPorts.length
      }

      // TODO: Temporarily disabled, until we establish a more accurate way of counting
      // if (type === PlanItemType.Device || type === PlanItemType.Splitter || (type === PlanItemType.Antenna && antennaType !== AntennaType.OutdoorOmni)) {
      //   counters['n-connector'].count = (counters['n-connector'].count || 0) + 2
      // }
    }

    return Object
      .values(counters)
      .filter(c => c.count > 0)
  }

  /**
   * Returns equipment hierarchy build around repeaters
   * @returns {Array}
   */
  getEquipmentHierarchy () {
    // Create the hierarchy
    const hierarchy = PlanHierarchyBuilder.create(this)

    // Remove unused tag colors
    for (const tag of Object.keys(this.tagColors)) {
      const tagUsed = hierarchy.find(r => r.tag === tag)
      if (!tagUsed) {
        delete this.tagColors[tag]
      }
    }

    return hierarchy
  }

  /**
   * Determines a background color for the specified tag
   * @param {String} tag Item tag
   * @returns {String} Tag background color
   */
  getTagColor (tag) {
    let color = this.tagColors[tag]
    if (!color) {
      color = Color.getRandomColor(DarkColorPalette, Object.values(this.tagColors))
      this.tagColors[tag] = color
    }
    return color
  }

  /**
   * Marks the floor as selected on the cross-section view
   * @param {PlanFloor} floor Floor to select
   */
  selectFloor ({ id } = {}) {
    for (const floor of this.floors) {
      floor.isSelected = floor.id === id
      // Disconnect floor items from any previously rendered shapes!
      for (const item of floor.items) {
        delete item.shape
      }
    }
  }

  /**
   * Currently selected floor, if on cross-section view
   * @type {PlanFloor}
   */
  get selectedFloor () {
    return this.floors.find(f => f.isSelected)
  }

  /**
   * Checks whether the specified floor is currently selected
   * @type {PlanFloor}
   * @returns {Boolean}
   */
  isFloorSelected (floor) {
    return floor && this.selectedFloor?.id === floor.id
  }

  /**
   * Checks whether the specified port is already used by some connector
   * @param {PlanPort} port Port to check
   * @returns {PlanConnector} Returns the connector attached to the port
   */
  isPortTaken (port) {
    const item = this.getItem(port.itemId)
    const connectors = this.connectors.filter(c => c.isConnectedTo(item))
    return connectors.find(({ start, end }) =>
      (start.itemId === item.id && start.id === port.id) ||
      (end.itemId === item.id && end.id === port.id))
  }

  /**
   * Checks whether the specified item is an external antenna
   * @param {PlanItem} item Item to check
   * @returns {Boolean}
   */
  isExternalAntenna (item) {
    if (item.isExternalAntenna) return true
    const connector = this.connectors.find(c => c.isConnectedTo(item))
    if (connector) {
      return Boolean(connector.connectsTo(
        PlanItemType.Device,
        (start, end, startPort, endPort) => startPort.isIn && endPort.isIn)
      )
    }
  }

  /**
   * Finds a riser through which the specified cross-floor connector passes
   * @param {PlanConnector} connector Plan connector
   * @returns {PlanRiser}
   */
  getConnectorRiser (connector) {
    return this.crossSection.getConnectorRiser(connector)
  }

  /**
   * Processes all floors and makes sure that risers are present for all cross-floor connections
   * @returns Returns the added items and items deleted in process, as `{ added: Array, deleted: Array }` object
   */
  refreshRisers () {
    const { crossSection, connectors, floors } = this

    // Create a riser for connectors for which riser doesn't yet exist,
    // remove any empty risers
    const { added, deleted } = crossSection.refreshRisers(connectors)

    // Purge empty cable plugs from all floors - those whose risers/connectors no longer exist
    for (const floor of floors) {
      floor.items = floor.items.filter(item => {
        const { isPlug, riser } = item
        if (isPlug) {
          if (!this.getRiser(riser)) {
            deleted.push(item)
            return false
          }
          if (!this.hasConnectors(item)) {
            deleted.push(item)
            return false
          }
        }
        return true
      })
    }

    // Purge invalid plug cables
    for (const floor of floors) {
      floor.items = floor.items.filter(item => {
        const { isConnector, goesIntoRiser, partOf, start, end } = item
        if (isConnector && goesIntoRiser) {
          // Purge plugs linking items to plugs which were just removed
          if (!(this.getItem(start.itemId) && this.getItem(end.itemId))) {
            deleted.push(item)
            return false
          }
          // Purge plugs related to connectors which were removed
          const connector = this.getItem(partOf)
          if (!connector) {
            deleted.push(item)
            return false
          }
          // Purge plugs if connector to which they're related
          // now connects items on the same floor
          if (!connector.isCrossFloor) {
            deleted.push(item)
            return false
          }
        }
        return true
      })
    }

    // Ensure presence of cable plugs on floors for any connectors outgoing from the floors.
    for (const connector of this.crossFloorConnectors) {
      // Assign different colors to each riser and its plugs
      const riser = this.getConnectorRiser(connector)
      const riserIndex = this.risers.findIndex(r => r.id === riser.id)
      riser.backgroundStyle.color = PlanRiser.getColor(riserIndex)
      // Ensure plugs
      const { from, to } = this.getConnectorFloors(connector)
      const fromItems = from.ensurePlug(connector, riser)
      const toItems = to.ensurePlug(connector, riser)
      added.push(...fromItems.added)
      added.push(...toItems.added)
    }

    return { added, deleted }
  }

  /**
   * Finds a position for the specified item next to the other items
   * @param {PlanItem} item Item to place
   * @param {Array[PlanItem]} others Other items to not to overlap with
   * @param {Point} adjust Additional adjustment of the position, such as margin
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
  */
  placeItemNextTo (item, others, adjust, isCrossSection) {
    if (item) {
      if (others?.length > 0) {
        const bounds = Rectangle.fromRectangles(others.map(i => i.getBounds(isCrossSection)))
        item.moveTo(bounds.rightTop, isCrossSection)
      }
      if (item.isCircular) {
        item.moveBy({ x: item.radiusX, y: item.radiusY }, isCrossSection)
      }
      if (adjust) {
        item.moveBy(adjust, isCrossSection)
      }
    }
  }

  /**
   * Finds a position for the specified item under the other items
   * @param {PlanItem} item Item to place
   * @param  {Array[PlanItem]} others Other items to not to overlap with
   * @param {Point} adjust Additional adjustment of the position, such as margin
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   */
  placeItemUnder (item, others, adjust, isCrossSection) {
    if (item) {
      if (others?.length > 0) {
        const bounds = Rectangle.fromRectangles(others.map(i => i.getBounds(isCrossSection)))
        item.moveTo(bounds.leftBottom, isCrossSection)
      }
      if (item.isCircular) {
        item.moveBy({ x: item.radiusX, y: item.radiusY }, isCrossSection)
      }
      if (adjust) {
        item.moveBy(adjust, isCrossSection)
      }
    }
  }

  /**
   * Moves the item to another floor
   * @param {PlanItem} item Item to move
   * @param {PlanFloor} floor Floor to move the item to
   */
  moveToFloor (item, floor) {
    if (item && floor && !floor.isCrossSection && item.floorId !== floor.id) {
      const current = this.getFloor(item.floorId)
      if (current) {
        // Remove from the current floor and add to the other floor
        current.removeItem(item)
        Log.debug(`${[item.type]}`, `[${current.label}] => [${floor.label}]`)
        floor.addItem(item)

        // Update any connectors related to the moved item
        for (const connector of this.getConnectorsOf(item)) {
          if (connector.start.itemId === item.id) connector.start.item = item
          if (connector.end.itemId === item.id) connector.end.item = item
        }

        // Find a position for the moved item, so it does not overlap
        // with other items on the floor
        this.placeItem(item, floor)

        // Refresh risers and connectors
        this.refreshRisers()
      }
    }
  }

  /**
   * Determines a position for the recently added item
   * @param {PlanItem} item Plan item to place on the floor view
   * @param {PlanFloor} floor Floor to place the item on, optional.
   * If not specified, item's current floor is used.
   * The only other option allowed is cross-section "floor".
   * @returns {Point} Item coordinates
   */
  placeItem (item, floor) {
    if (!item) throw new Error('Item is required')

    if (!floor) floor = this.getFloor(item.floorId)
    if (!floor) throw new Error('Floor is required')
    if (floor.id !== item.floorId && !floor.isCrossSection) throw new Error('The floor must be either item\'s own floor or cross-section')

    // If placing on cross-section, the item must be actually viewable on the cross-section
    if (floor?.isCrossSection && !item.showOnCrossSection) return

    // Point-based items retain their coordinates when moved between floors (for now)
    if (item.isPointBased) return

    // Place initially in the top-left corner
    const { isCrossSection } = floor
    const margin = 20
    item.moveTo({ x: margin, y: margin }, isCrossSection)
    if (item.isCircular) {
      item.moveBy({ x: item.radiusX, y: item.radiusY }, isCrossSection)
    }

    // On the cross-section view move the item from top-left to the corresponding floor box
    if (isCrossSection) {
      const itemFloor = this.getFloor(item.floorId)
      item.moveBy(itemFloor.crossSection.bounds, true)
      // ...plus a bit more for the floor label
      item.moveBy({ y: 25 }, true)
    }

    // As long as item overlaps with other items, keep moving it to the right,
    // and to the bottom if need be
    const initialCoordinates = item.getCoordinates(isCrossSection)
    let placed = false
    let attempts = 0
    let originalCoordinates = item.getCoordinates(isCrossSection)
    do {
      // Find items with which the newly added item overlaps, look only at coordinate-based items!
      const overlapsWith = floor
        .findOverlappingItems(item, margin)
        .filter(i => !i.isPointBased)

      // If no overlaps, the item can stay where it is
      if (overlapsWith.length === 0) {
        placed = true
      } else {
        // Otherwise move the item to the right of the rectangle containing all the overlapping items
        const overlapBounds = Rectangle.fromRectangles(overlapsWith.map(i => i.getBounds(isCrossSection)))
        item.moveTo(overlapBounds.rightTop, isCrossSection)
        item.moveBy({ x: margin }, isCrossSection)
        if (item.isCircular) {
          item.moveBy({ x: item.radiusX, y: item.radiusY }, isCrossSection)
        }

        // If right end reached, move lower and start from the left side again
        const newBounds = item.getBounds(isCrossSection)
        if (!floor.isItemFullyVisible(item)) {
          item.moveTo({ x: initialCoordinates.x, y: item.y + newBounds.height + margin }, isCrossSection)
        }

        // If item still not fully visible, just drop it back on the initial position and leave
        if (!floor.isItemFullyVisible(item)) {
          item.moveTo(initialCoordinates, isCrossSection)
          placed = true
        }

        // Break if no effect after 10 attempts
        attempts++
        if (attempts > 10) {
          placed = true
          item.setCoordinates(originalCoordinates, isCrossSection)
        }
      }
    } while (!placed)

    return item.getCoordinates(isCrossSection)
  }

  /**
   * Determines floor bounds for displaying it on the cross section view
   * @param {PlanFloor} floor Floor to set up
   * @param {Boolean} adjustItems If true, the items on all floors will be checked
   * whether they fit inside their floor's bounds. If not, they will be moved accordingly.
   */
  setCrossSectionFloorBounds ({ adjustItems } = {}) {
    const { floors, topToBottom, crossSection: { dimensions, margin } } = this

    let y = margin.top
    for (const floor of topToBottom) {
      const x = margin.left
      const width = dimensions.width
      const height = floor.crossSection.height
      floor.crossSection.bounds.setBounds({ x, y, width, height })
      y = y + floor.crossSection.height
    }

    dimensions.height = floors.reduce((height, floor) => height + floor.crossSection.height, 0)

    // Make sure that all items on the floor
    // remain within floor bounds on the cross-section view
    if (adjustItems) {
      const innerMargin = 20
      for (const floor of floors) {
        for (const item of floor.crossSectionItems) {
          const bounds = item.getBounds(true)
          if (bounds.x < floor.crossSection.bounds.leftTop.x) {
            item.moveTo({ x: floor.crossSection.bounds.x + innerMargin }, true)
          }
          if (bounds.x > floor.crossSection.bounds.rightBottom.x) {
            item.moveTo({ x: floor.crossSection.bounds.rightTop.x - bounds.width - innerMargin }, true)
          }
          if (bounds.y < floor.crossSection.bounds.leftTop.y) {
            item.moveTo({ y: floor.crossSection.bounds.y + innerMargin }, true)
          }
          if (bounds.y > floor.crossSection.bounds.rightBottom.y) {
            item.moveTo({ y: floor.crossSection.bounds.rightBottom.y - bounds.height - innerMargin }, true)
          }
        }
      }
    }
  }

  /*
   * Calculates the statistics of cables and equipment leading to the item
   * @param {PlanItem} item Plan item
   * @param {Array[PlanHierarchyItem]} hierarchy Items hierarchy built around repeaters
   * @return {Object} Object `{ length, input, gain, ratio, details }` where:
   *   `length`  Cable length in meters
   *   `input`   Input signal strength
   *   `gain`    Signal gain/loss in `dBm` on the entire route to the item
   *   `ratio`   Ratio of the signal at the item, compared to the `input`
   *   `details` Detailed explanations of gain or loss at each step
   */
  getCableStats (item, hierarchy) {
    if (!hierarchy) return
    if (!item.root) return

    const layout = this

    // Find the root repeater under which the item belongs
    const root = hierarchy.find(r => r.id === item.root)
    if (root) {
      let length = 0
      let details = []

      // Find the hierarchy item representing this item
      let hierarchyItem = root.findDescendant(i => i.id === item.id)
      while (hierarchyItem) {
        const { item, connector } = hierarchyItem

        // Determine signal gain/loss at the item
        // and calculate the output signal at the item, optionally capped
        const itemGain = item.getGain(layout)
        details.push({
          item,
          type: hierarchyItem.type,
          subtype: hierarchyItem.equipmentType,
          label: item.autoLabel,
          gain: itemGain
        })

        // Get the length and gain of the connecting cable
        if (connector) {
          const connectorLength = connector.realLength || 0
          const connectorGain = connector.getGain(layout)
          length = length + connectorLength
          details.push({
            item: connector,
            type: connector.type,
            subtype: connector.cableType,
            label: connector.autoLabel,
            length: connectorLength,
            gain: connectorGain
          })
        }

        // Walk upwards
        hierarchyItem = hierarchyItem.ancestor
      }

      // Add detail item representing the input signal
      // Determine the strength of the input signal coming into the root device
      // and gain at the device
      const rootInput = root.item.getInputSignal(layout)
      const rootOutput = root.item.capSignal(rootInput + root.item.getGain(layout))

      details.push({
        label: 'Input',
        input: rootInput,
        unit: 'dB'
      })

      // Calculate the output by walking along the path, from the repeater all the way to the item
      let output = rootInput
      details.reverse()
      for (let i = 0; i < details.length; i++) {
        const detail = details[i]
        detail.label = `${i + 1}. ${detail.label}`
        if (detail.gain != null) {
          output = round(detail.item.capSignal(output + detail.gain), 2)
          detail.output = output
        }
      }

      // Create the stats for the item
      const gain = round(output - rootInput, 2)
      const ratio = round(output / rootOutput, 2)
      output = round(output, 2)

      const stats = {
        length,
        input: rootInput,
        root: rootOutput,
        gain,
        output,
        ratio,
        details
      }

      // If antenna, calculate radiation
      if (item.isAntenna) {
        stats.radiation = {}
        if (item.isOmnidirectionalAntenna) {
          const { radius: idealRadius } = item.parameters
          // Check how much was lost/gained as related to the ideal scenario
          // when there's no cables and antenna directly amplifies the output from the repeater
          const delta = rootOutput + item.getGain(layout) - output
          // ANTENNA RADIUS FORMULA
          stats.radiation.radius = round(idealRadius * (1 / Math.pow(10, delta / 20)), 2)
        }
      }

      return stats
    }
  }
}
