<script>
import { mapActions } from 'vuex'

export default {
  props: {
    // Edited plan
    plan: {
      required: true
    },
    // Images to edit
    images: {
    },
    // Use when in development, to skip uploading of the images to S3
    development: {
    }
  },

  data () {
    return {
    }
  },

  computed: {
    // Image tooltip
    getImageTooltip () {
      return image => {
        if (image) {
          return `${image.description}`
        }
      }
    },

    // CSS style for the image thumbnail
    getImageThumbnail () {
      return (image) => {
        if (image) {
          const style = {}
          const filters = []
          const transform = []

          style['background-size'] = 'cover'
          style.backgroundImage = `url(${image.reference})`

          // Apply image transformations
          if (image.rotation !== 0) {
            transform.push(`rotate(${image.rotation}deg)`)
          }
          // Apply image filters
          if (image.bw) {
            filters.push('grayscale(100%)')
          }
          if (image.contrast !== 100) {
            filters.push(`contrast(${image.contrast}%)`)
          }
          if (image.brightness !== 100) {
            filters.push(`brightness(${image.brightness}%)`)
          }

          if (filters.length > 0) {
            style.filter = filters.join(' ')
          }

          if (transform.length > 0) {
            style.transform = transform.join(' ')
          }

          return style
        }
      }
    }
  },

  methods: {
    ...mapActions([
      'removePlanImage'
    ])
  }
}
</script>

<template>
  <span class="q-mb-md">
    Press FINISH to add {{ count(images, 'floor') }} to the plan.
  </span>
  <div class="images">
    <div class="image" v-for="image in images" :key="image.hash" :title="getImageTooltip(image)">
      <div class="title">
        <span class="image-name">
          {{ image.floorName }}
        </span>
      </div>
      <div class="thumbnail" :style="getImageThumbnail(image)">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-items: flex-start;
  gap: 10px;
  overflow: auto;

  .image {
    width: 140px;
    height: 140px;
    margin: 0px 0px 10px 0px;
    border: solid #b0b0b0 1px;
    background-color: #e8e8e8;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .title {
      flex-basis: 34px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      font-size: 11px;
      background-color: #c0c0c0;
      border-bottom: solid #808080 1px;
      padding: 4px;
      overflow: hidden;

      .image-name {
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .thumbnail {
      flex: 1;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>