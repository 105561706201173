import Konva from 'konva'
import { Rectangle } from '@stellacontrol/utilities'
import { DeviceAcronym, DeviceConnectionStatusColor } from '@stellacontrol/model'
import { PlanItemType, PlanPortType, PlanLocation } from '@stellacontrol/planner'
import { Shape } from './shape'
import { ShapeLayout } from './shape-layout'

/**
 * Device such as repeater, amplifier etc.
 * @param {PlanDevice} item Plan item details
 */
export class DeviceShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }


  static get type () {
    return PlanItemType.Device
  }

  __layout

  /**
   * Shape layout
   * @type {ShapeLayout}
   */
  get layout () {
    const { item, item: { portCount } } = this

    const { width, height } = item
    const shape = { x: 0, y: 0, width, height }

    if (shape.width !== item.width || shape.height !== item.height) {
      throw new Error(`[${item.deviceType}.${portCount}] Item dimensions here are different than in the item definition`)
    }

    // Return the existing layout if unchanged
    if (this.__layout && this.__layout.portCount === portCount + 1) {
      return this.__layout
    }

    // Prepare the shape layout
    // ATTENTION!
    // To simplify coordinate calculations, all port coordinates
    // specify CENTERS of shapes - so for circles as for rectangles!
    let layout

    if (item.portCount === 1) {
      // 1-port device
      layout = new ShapeLayout({
        shape,
        ports: [
          {
            id: PlanPortType.In,
            location: PlanLocation.Top,
            shape: { x: 50, y: -6 }
          },
          {
            id: '1',
            location: PlanLocation.Bottom,
            shape: { x: 50, y: 56 }
          }
        ]
      })

    } else if (portCount === 4) {
      // 4-port device
      layout = new ShapeLayout({
        shape,
        ports: [
          {
            id: PlanPortType.In,
            location: PlanLocation.Top,
            shape: Rectangle.from({ x: 50, y: -6 })
          },
          {
            id: '1',
            location: PlanLocation.Bottom,
            shape: Rectangle.from({ x: 20, y: 56 })
          },
          {
            id: '2',
            location: PlanLocation.Bottom,
            shape: Rectangle.from({ x: 40, y: 56 })
          },
          {
            id: '3',
            location: PlanLocation.Bottom,
            shape: Rectangle.from({ x: 60, y: 56 })
          },
          {
            id: '4',
            location: PlanLocation.Bottom,
            shape: Rectangle.from({ x: 80, y: 56 })
          }
        ]
      })

    } else {
      throw new Error(`Unsupported port count [${portCount}]`)
    }

    this.__layout = layout
    return layout
  }

  /**
   * Shape representing the device
   * @type {Konva.Rect}
   */
  chassisShape

  /**
   * Shape representing the device status
   * @type {Konva.Circle}
   */
  statusShape

  /**
   * Main shape in the {@link content}, such as device chassis etc.
   * @type {Konva.Shape}
   */
  get main () {
    return this.chassisShape
  }

  // Used to detect a change in port count,
  // when ports need to be re-created
  __portCount

  /**
   * Shape representing device's external port
   * @type {Konva.Shape}
   */
  get externalPortShape () {
    const externalPortId = this.item.defaults.externalPort.id
    return this.ports.find(s => s.id() === externalPortId)
  }

  /**
   * Shapes representing device's internal ports
   * @type {Array[Konva.Shape]}
   */
  get internalPorts () {
    const externalPortId = this.item.defaults.externalPort.id
    return this.ports.filter(s => s.id() !== externalPortId) || []
  }

  /**
   * Creates all shapes making up the device
   */
  createShapes () {
    super.createShapes()
    const { item } = this

    this.__portCount = item.portCount
    this.chassisShape = new Konva.Rect()
    this.statusShape = new Konva.Rect()
    this.ports = (item.ports || []).map(port => new Konva.Rect({ id: port.id }))
    this.content.add(
      this.chassisShape,
      this.statusShape,
      ...this.ports
    )
  }

  /**
   * Indicates whether shape needs to be (partially) recreated,
   * i.e. after changing the antenna type
   * @type {Boolean}
   */
  get requiresRefresh () {
    return super.requiresRefresh ||
      this.__portCount !== this.item?.portCount
  }

  /**
   * Returns coordinates and bounds of the label
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Point}
   */
  getLabelPosition (renderer) {
    // Auto-fit the label inside the shape
    return super.getLabelPosition(renderer, true)
  }

  /**
   * Determines the device label
   * @returns {String}
   */
  getLabelText ({ renderer }) {
    if (!renderer) throw new Error('Renderer is required')

    const { item: { deviceType, serialNumber } } = this
    const label = `${DeviceAcronym[deviceType]} ${serialNumber || ''}`.trim()
    return label
  }

  /**
   * Renders the device shape
   */
  render (renderer) {
    super.render(renderer)
    const { chassisShape, statusShape, item } = this

    if (chassisShape && item) {
      const { lineStyle, backgroundStyle } = item
      chassisShape.width(item.width)
      chassisShape.height(item.height)
      chassisShape.fill(backgroundStyle.color)
      chassisShape.stroke(lineStyle.color)
      chassisShape.strokeWidth(lineStyle.width)
      chassisShape.dash(lineStyle.dash)
      chassisShape.cornerRadius(lineStyle.radius)

      // Render status
      const connection = item.status?.connection
      if (connection && !connection.hasNeverConnected) {
        const size = 10
        const margin = 4
        const color = DeviceConnectionStatusColor[connection.status]
        statusShape.visible(true)
        statusShape.position({ x: item.width - size - margin, y: margin })
        statusShape.width(size)
        statusShape.height(size)
        statusShape.strokeWidth(1)
        statusShape.stroke('black')
        statusShape.fill(color)
      } else {
        statusShape.visible(false)
      }
    }
  }

  /**
   * Triggered when shape has been transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanScale} scale Scale of the shape
   * @param {Number} rotation Shape rotation
   */
  transformed ({ renderer, scale, rotation }) {
    super.transformed({ renderer, scale, rotation })

    // Change the sizes
    const { item } = this
    item.width = Math.round(item.width * scale.x)
    item.height = Math.round(item.height * scale.y)

    // Re-render the shape
    this.render(renderer)
  }
}
