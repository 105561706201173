import { delay, countString } from '@stellacontrol/utilities'
import { Notification } from '@stellacontrol/client-utilities'
import { PlanAction, PlanActions } from './plan-action'
import { AddItemAction } from './add-item'
import { PlanClipboard } from '../utilities/clipboard'

/**
 * Duplicates the specified items
 */
export class DuplicateItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.DuplicateItems
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    const { isBatch, count } = this
    return isBatch ? `Duplicate ${count} items` : 'Duplicate'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'content_copy'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   */
  async execute ({ renderer, items } = {}) {
    if (renderer && items) {
      const copy = new CopyItemsAction()
      await copy.execute({ renderer, items })

      const sourceBounds = renderer.selection.bounds
      const position = sourceBounds.rightTop.moveBy({ x: 20 })
      const paste = new PasteItemsAction()
      const pastedItems = await paste.execute({ renderer, position, clear: true })

      await renderer.selectItems({
        items: pastedItems,
        showMenu: true
      })
    }
  }
}

/**
 * Copies the specified items to the clipboard
 */
export class CopyItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.CopyItems
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    const { isBatch, count } = this
    return isBatch ? `Copy ${count} items` : 'Copy'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'content_copy'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   */
  execute ({ renderer, items } = {}) {
    items = items.filter(i => i.canCopyPaste)
    if (renderer && items?.length > 0) {

      // Ignore some items such as auto-created plugs and partial cables.
      let itemsToCopy = items.filter(i => i.canCopyPaste)

      // Include connectors only when both of the copied ends are included amongst the copied items.
      itemsToCopy = itemsToCopy.filter(i =>
        !i.isConnector ||
        (itemsToCopy.find(({ id }) => id === i.start.itemId) && itemsToCopy.find(({ id }) => id === i.end.itemId)))

      if (itemsToCopy.length > 0) {
        // Place the items in the clipboard, remembering their original position
        const sourcePosition = renderer.selection.position
        PlanClipboard.write(itemsToCopy, sourcePosition)
        Notification.success({
          message: `${countString(itemsToCopy, 'shape')} copied`,
          details: 'Press CTRL+V to paste them, also onto another floor'
        })
      }
    }
  }
}

/**
 * Pastes specified items from the clipboard
 */
export class PasteItemsAction extends PlanAction {
  constructor (data = {}) {
    super(data)
  }

  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.PasteItems
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return super.label || 'Paste'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'content_paste'
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Point} position Position at which the action should be executed
   * @param {Boolean} clear If `true`, the clipboard is cleared after the paste
   * @returns {Array[PlanItem]} Pasted items
   */
  async execute ({ renderer, position, clear } = {}) {
    if (renderer) {
      // Get clipboard content
      const { items, position: sourcePosition } = PlanClipboard.read() || {}
      if (!(items && position)) return

      // Get connectors - they can be added only after all other elements have been pasted
      const connectors = items.filter(item => item.isConnector)
      const equipment = items.filter(item => !item.isConnector)

      // Paste the equipment at the location where action was executed,
      // in the right sequence - first the equipment, then the connectors
      const { isCrossSection } = renderer
      for (const group of [equipment, connectors]) {
        for (const item of group) {
          item.clearTag()
          const action = new AddItemAction({ items: [item] })

          // Move the item to where the action was executed, and add a bit of a shift
          const coordinates = item.getCoordinates(isCrossSection)
          const delta = position.delta(sourcePosition || coordinates)
          item.moveBy(delta, isCrossSection)

          // Adjust item coordinates if it's outside the canvas bounds

          // Add the item
          action.execute({ renderer, items: [item] })
        }
      }

      // Mark newly added items as selected
      await delay(200)
      await renderer.refreshItems({ items })
      await renderer.selectItems({ items, showMenu: false })
      renderer.changed()

      if (clear) {
        PlanClipboard.clear()
      }

      return items
    }
  }
}
