import { createPlanItem } from '@stellacontrol/planner'
import { getId, Point } from '@stellacontrol/utilities'

/**
 * Plan clipboard.
 * Uses local storage instead of the system clipboard,
 * to enable copying and pasting between plans.
 */
export const PlanClipboard = {
  /**
   * Indicates that the clipboard contains plan items
   * @type {Boolean}
   */
  get hasItems () {
    const { items } = this.read() || {}
    return items?.length > 0
  },

  /**
   * Indicates the number of plan items in the clipboard
   * @type {Number}
   */
  get count () {
    const { items } = this.read() || {}
    return items?.length || 0
  },

  /**
   * Adds items to the clipboard
   * @param {Array[PlanItem]} items Items to add to the clipboard
   * @param {Point} position Position of the selection rectangle around the selected items
   */
  write (items = [], position) {
    const data = JSON.stringify({
      source: 'stellacontrol',
      type: 'plan-items',
      position,
      items
    })

    localStorage.setItem('stellacontrol-clipboard', data)
  },

  /**
   * Clears the clipboard
   */
  clear () {
    localStorage.removeItem('stellacontrol-clipboard')
  },

  /**
   * Retrieves previously copied items from the clipboard
   * @returns { items: Array[PlanItem], position: Point }
   */
  read () {
    try {
      const data = JSON.parse(localStorage.getItem('stellacontrol-clipboard'))
      const hasItems = data?.source === 'stellacontrol' && data.type === 'plan-items' && data.items
      if (hasItems) {
        // Assign new identifiers to items
        const position = data.position ? new Point(data.position) : null
        const items = data
          .items
          .map(item => createPlanItem(item))
          .filter(item => item)

        for (const item of items) {
          const id = getId('item')

          // Update item ports
          for (const port of item.ports || []) {
            port.itemId = id
          }

          // Update connectors related to the pasted items
          const connectors = items.filter(i => i.isConnector && i.isConnectedTo(item))
          for (const connector of connectors) {
            // Update connector references accordingly
            if (connector.start?.itemId === item.id) {
              connector.start.itemId = id
            }
            if (connector.end?.itemId === item.id) {
              connector.end.itemId = id
            }
          }
          item.id = id
        }

        return { items, position }
      }
    } catch {
      return null
    }
  }
}
