<script>
import { mapActions } from 'vuex'
import { Point } from '@stellacontrol/utilities'
import { Confirmation, Notification } from '@stellacontrol/client-utilities'
import { PlanEvent } from '../../renderer/events'

export default {
  props: {
    // Edited plan
    plan: {
      required: true
    },
    // Images to edit
    images: {
    },
    // Use when in development, to skip uploading of the images to S3
    development: {
    }
  },

  data () {
    return {
      // Edited images
      edited: [],
      // Indicates that we're now loading an image to be edited
      isLoadingImage: false,
      // Edited image
      image: null,
      // Indicates that mouse cursor is inside the edited image
      imageHover: false,
      // Properties for setting the map scale
      mapScale: {
        // Image for which the scale is being set
        image: null,
        // Start point of the map scale line
        start: null,
        // End point of the map scale line
        end: null,
        // Style for the map scale line
        style: {
          color: 'red',
          width: 3,
          dash: '6 3'
        }
      },
      // Image being dragged
      draggedImage: null
    }
  },

  computed: {
    // Image tooltip
    getImageTooltip () {
      return image => {
        if (image) {
          return `${image.description}`
        }
      }
    },

    // CSS style for the image preview
    getImagePreview () {
      return (image, thumbnail) => {
        if (image) {
          const style = {}
          const { mapScale, isLoadingImage } = this
          const filters = []
          const transform = []

          if (thumbnail) {
            // THUMBNAIL IMAGE
            style['background-size'] = 'cover'
            style.backgroundImage = `url(${image.reference})`

          } else {
            // PREVIEW IMAGE
            // Hide the image if loading another one
            if (isLoadingImage) {
              return { 'visibility': 'hidden' }
            }

            // Change cursor to crosshair when drawing map scale
            if (mapScale.image) {
              style.pointer = 'crosshair'
            }

            // Apply image panning
            style.left = `${image.x}px`
            style.top = `${image.y}px`

            // Apply image scaling
            if (image.scale !== 100) {
              style.width = `${Math.round(image.width * (image.scale / 100))}px`
            }
          }

          // Apply image transformations
          if (image.rotation !== 0) {
            transform.push(`rotate(${image.rotation}deg)`)
          }

          // Apply image filters
          if (image.bw) {
            filters.push('grayscale(100%)')
          }
          if (image.contrast !== 100) {
            filters.push(`contrast(${image.contrast}%)`)
          }
          if (image.brightness !== 100) {
            filters.push(`brightness(${image.brightness}%)`)
          }

          if (filters.length > 0) {
            style.filter = filters.join(' ')
          }

          if (transform.length > 0) {
            style.transform = transform.join(' ')
          }

          return style
        }
      }
    },

    // Checks whether the specified image is selected for editing
    isImageSelected () {
      return image => image?.hash === this.image?.hash
    },

    // Returns style for the map scale line
    mapScaleStyle () {
      return image => image.mapScale > 0
        ? { width: `${image.mapScale}px` }
        : {}
    }
  },

  methods: {
    ...mapActions([
      'removePlanImage'
    ]),

    // Notifies about the edited images
    changed () {
      this.$emit('changed', this.edited)
    },

    // Removes the specified image from the image list
    async removeImage (image) {
      if (image) {
        // Dispose of the data URL
        URL.revokeObjectURL(image.reference)

        // Remove from the list
        const { plan } = this
        const index = this.edited.findIndex(i => i.hash === image.hash)
        this.edited = this.edited.filter(i => i.hash !== image.hash)

        // If removed the currently edited image, show predecessor
        if (image === this.image) {
          this.image = this.edited[index - 1]
        }

        // Remove from the external storage
        this.removePlanImage({ image, plan })

        this.changed()
      }
    },

    // Moves the specified image before the given one
    moveImageTo (image, to) {
      const source = this.edited.findIndex(i => i.hash === image.hash)
      const target = this.edited.findIndex(i => i.hash === to.hash)
      const index = target > source ? target + 1 : target
      this.edited = [
        ...this.edited.slice(0, index).filter(i => i.hash !== image.hash),
        image,
        ...this.edited.slice(index).filter(i => i.hash !== image.hash)
      ]
      this.changed()
    },

    // Selects the specified image for editing
    selectImage (image) {
      if (this.image != image) {
        this.isLoadingImage = true
        this.image = image
      }
    },

    // Triggered when image preview has been loaded
    imageLoaded (e, image) {
      // Scale the image to fit inside the container
      const { offsetWidth: width, offsetHeight: height } = e.target
      image.width = width
      image.height = height
      this.fitImageToContainer(image)

      this.isLoadingImage = false
    },

    // Fits the image to the container width
    fitImageToContainer (image) {
      const container = this.$refs.imageContainer
      const containerWidth = container.offsetWidth
      image.scale = Math.round(100 * (containerWidth / image.width))
      image.x = 0
      image.y = 0
    },

    // Zooms in/out
    zoomImage (image, delta) {
      if (delta == null) {
        image.scale = 100
      } else {
        image.scale = Math.min(500, Math.max(10, image.scale + delta))
        // Round up to the nearest multiply of 10
        image.scale = 10 * (
          delta > 0
            ? Math.ceil(image.scale / 10)
            : Math.floor(image.scale / 10)
        )
      }
    },

    // Rotates the image
    rotateImage (image) {
      if (image) {
        // image.rotation = image.rotation + 90
        // if (image.rotation >= 360) {
        image.rotation = 0
        // }
        this.changed()
      }
    },

    // Makes the image black and white
    desaturateImage (image) {
      image.bw = !image.bw
      this.changed()
    },

    // Starts setting the map scale
    async startMapScale (image) {
      if (image) {
        this.mapScale.image = image
        this.mapScale.start = null
        this.mapScale.end = null
      }
    },

    // Finishes setting the map scale
    async finishMapScale () {
      if (this.mapScale.image) {
        // Finish setting map scale
        const { image, start, end } = this.mapScale
        if (start && end) {
          // Ask for length of the selected line
          const length = await Confirmation.prompt({
            title: 'Length in meters',
            message: 'What is the length of the line in meters?',
            number: true,
            min: 1,
            max: 1000,
            step: 0.1
          })

          if (length > 0) {
            // When calculating distance between scale points,
            // take into consideration the current image scale
            const distance = start.distance(end) / (image.scale / 100)
            const mapScale = Math.round(distance / length)

            // Ask whether to apply the same scale to all other floors
            const applyToAll = await Confirmation.ask({
              title: 'Apply to all floors?',
              message: 'Apply the same map scale to all other floors?'
            })
            if (applyToAll) {
              for (const image of this.edited) {
                image.mapScale = mapScale
              }
            } else {
              image.mapScale = mapScale
            }

            Notification.success({
              title: image.description,
              message: `Map scale has been set to 1m = ${image.mapScale}px`
            })
          }
        }

        this.stopMapScale()
        this.changed()
      }
    },

    // Cancels setting the map scale
    async stopMapScale () {
      this.mapScale.image = null
      this.mapScale.start = null
      this.mapScale.end = null
    },

    // Sets the point of the currently drawn map scale line
    async setMapScalePoint ({ image, x, y, finish }) {
      if (image) {
        if (this.mapScale.start) {
          this.mapScale.end = Point.from({ x, y })
          if (finish) {
            this.finishMapScale()
          }
        } else {
          this.mapScale.start = Point.from({ x, y })
        }
      }
    },

    // Triggered when mouse wheel is turned,
    // allows scaling the image if event happened while mouse pointer was inside the image
    mouseWheelHandler (e) {
      const { image, imageHover } = this
      if (image && imageHover) {
        e.preventDefault()
        this.zoomImage(image, e.deltaY < 0 ? 10 : -10)
        return
      }
    },

    // Triggered when mouse is moved inside the image.
    // Combined with right-mouse click, it allows panning the image.
    moveImageHandler (event, image) {
      if (PlanEvent.isRightButton(event)) {
        event.preventDefault()
        // Prevent from moving to the right which would leave an empty margin on the left
        image.x = Math.min(0, image.x + event.movementX)
        image.y = Math.min(0, image.y + event.movementY)
        return
      }

      // If drawing map scale, update the end point
      if (this.mapScale.start) {
        event.preventDefault()
        this.setMapScalePoint({ image, x: event.offsetX, y: event.offsetY })
        return
      }
    },

    // Triggered when image is clicked
    clickImageHandler (event, image) {
      if (PlanEvent.isLeftButton(event)) {
        // When in map scale mode, collect scale points
        if (image && this.mapScale.image === image) {
          event.preventDefault()
          const finish = this.mapScale.start != null
          this.setMapScalePoint({ image, x: event.offsetX, y: event.offsetY, finish })
          return
        }
      }
    },

    // Image dragging started
    imageDrag (event, image) {
      this.draggedImage = image
      if (image) {
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.setData('text/plain', image.hash)
      }
    },

    // Image is being dragged over another image
    imageDragOver (event, image) {
      const { draggedImage } = this
      if (image && draggedImage) {
        if (image && image.hash !== draggedImage.hash) {
          event.preventDefault()
        }
      }
    },

    // Image is dropped over another image, reorder
    imageDrop (event, image) {
      const { draggedImage } = this
      if (image && draggedImage) {
        if (image && image.hash !== draggedImage.hash) {
          event.preventDefault()
          this.moveImageTo(draggedImage, image)
        }
      }
    }
  },

  emits: [
    'changed'
  ],

  watch: {
    images (images = []) {
      this.edited = [...images]
    }
  },

  created () {
    this.edited = [...this.images]
    this.image = this.edited[0]
  },

  mounted () {
    window.addEventListener('wheel', this.mouseWheelHandler, { passive: false })
  },

  beforeUnmount () {
    window.removeEventListener('wheel', this.mouseWheelHandler)
  }
}
</script>

<template>
  <div class="editing">
    <aside class="images">
      <div class="image" v-for="image in edited" :key="image.hash"
        :class="{ selected: isImageSelected(image) }" :title="getImageTooltip(image)"
        @click.stop="selectImage(image)" draggable="true"
        @dragstart="event => imageDrag(event, image)"
        @dragenter="event => imageDragOver(event, image)"
        @dragover="event => imageDragOver(event, image)" @drop="event => imageDrop(event, image)">
        <div class="title">
          <span class="image-name">
            {{ image.floorName || image.description }}
          </span>
          <span class="delete-button">
            <q-btn flat round dense icon="close" color="red-7" size="sm"
              @click.stop="removeImage(image)"></q-btn>
          </span>
        </div>
        <div class="thumbnail" :style="getImagePreview(image, true)">
        </div>
      </div>
    </aside>

    <div class="preview">
      <template v-if="image">
        <div class="image-details bg-indigo-6 text-white">
          <template v-if="mapScale.image">
            <!-- Controls for setting map scale -->
            <q-icon name="straighten" color="white" class="q-ml-sm q-mr-sm" size="24px"></q-icon>
            <span>
              Click on two points to select a distance.
            </span>
            <q-btn flat dense label="Cancel" text-color="orange" class="q-ml-md" size="14px"
              @click.stop="stopMapScale()"></q-btn>
          </template>

          <template v-else>
            <!-- Controls for zooming in and out -->
            <q-icon name="info" color="white" class="q-ml-sm q-mr-sm" size="24px"></q-icon>
            <span>
              {{ image.description }}
            </span>
            <q-space></q-space>
            <q-btn round flat dense icon="zoom_out" text-color="white" class="q-ml-md" size="16px"
              @click="zoomImage(image, -10)"></q-btn>
            <span>
              <q-btn flat dense :label="`${image.scale}%`" text-color="white" size="14px"
                style="width: 50px;" @click="fitImageToContainer(image)"></q-btn>
            </span>
            <q-btn round flat dense icon="zoom_in" text-color="white" size="16px"
              @click="zoomImage(image, 10)" class="q-mr-sm"></q-btn>
          </template>
        </div>

        <div ref="imageContainer" class="image-container" @mouseenter="imageHover = true"
          @mouseleave="imageHover = false" @mousemove="event => moveImageHandler(event, image)"
          @mousedown="event => clickImageHandler(event, image)">

          <!-- image preview -->
          <img ref="imagePreview" class="image-preview" :src="image.reference"
            :style="getImagePreview(image, false)" @contextmenu="e => e.preventDefault()"
            @load="event => imageLoaded(event, image)">

          <!-- vector image of the map scale line, with applied image panning -->
          <svg xmlns="http://www.w3.org/2000/svg" v-if="mapScale.end" class="map-scale-selector"
            :style="{ left: `${image.x}px`, top: `${image.y}px` }" :height="image.height"
            :width="image.width">
            <circle :cx="mapScale.start.x" :cy="mapScale.start.y" :r="mapScale.style.width * 1.5"
              :fill="mapScale.style.color" />
            <line :x1="mapScale.start.x" :y1="mapScale.start.y" :x2="mapScale.end.x"
              :y2="mapScale.end.y" :stroke="mapScale.style.color"
              :stroke-width="mapScale.style.width" :stroke-dasharray="mapScale.style.dash" />
            <circle :cx="mapScale.end.x" :cy="mapScale.end.y" :r="mapScale.style.width * 1.5"
              :fill="mapScale.style.color" />
          </svg>

          <!-- image scale -->
          <div v-if="image.mapScale" class="map-scale" :style="mapScaleStyle(image)">
            1m
          </div>

          <!-- image loading indicator -->
          <div v-if="isLoadingImage" class="image-loading text-grey-7 text-h6">
            <sc-busy title="Loading ..." size="sm"></sc-busy>
          </div>

        </div>
      </template>
    </div>

    <div class="properties">
      <template v-if="image">
        <q-input label="Floor name" dense outlined square v-model="image.floorName"></q-input>

        <q-btn :label="image.bw ? 'Color' : 'Black/White'" icon="invert_colors" align="left" no-caps
          unelevated class="q-mt-md" @click="desaturateImage(image)"></q-btn>
        <q-btn label="Set Scale" icon="straighten" no-caps unelevated class="q-mt-sm" align="left"
          @click="mapScale.image ? stopMapScale() : startMapScale(image)"
          :color="mapScale.image ? 'indigo-8' : undefined"></q-btn>
        <q-btn label="Rotate" icon="refresh" no-caps unelevated class="q-mt-sm" align="left"
          @click="rotateImage(image)"></q-btn>

        <q-slider v-model="image.brightness" class="q-mt-sm q-mb-md" :min="0" :max="300" label
          switch-label-side :label-value="'Brightness: ' + image.brightness + '%'"
          label-always></q-slider>
        <q-slider v-model="image.contrast" class="q-mt-lg q-mb-lg" :min="0" :max="300" label
          switch-label-side :label-value="'Contrast: ' + image.contrast + '%'"
          label-always></q-slider>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.editing {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .images {
    flex-basis: 150px;
    gap: 5px;
    overflow: hidden;
    overflow-y: auto;

    .image {
      width: 140px;
      height: 140px;
      margin: 0px 0px 10px 0px;
      border: solid #23295c 1px;
      background-color: #e8e8e8;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        border-color: #3b47b6;
      }

      &.selected {
        margin: 0 0 10px 0;
        border-color: #3b47b6;
        border-width: 2px;
      }

      .title {
        flex-basis: 34px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        font-size: 11px;
        background-color: #c0c0c0;
        border-bottom: solid #808080 1px;
        padding: 4px;
        overflow: hidden;

        .image-name {
          text-wrap: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .delete-button {}
      }

      .thumbnail {
        flex: 1;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .preview {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    border: solid #23295c 1px;
    position: relative;
    overflow: hidden;

    .image-details {
      flex-basis: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .image-container {
      flex: 1;
      position: relative;
      overflow: hidden;

      .image-preview {
        position: absolute;
        z-index: 1;
      }

      .map-scale-selector {
        position: absolute;
        z-index: 2;
      }

      .map-scale {
        position: absolute;
        z-index: 3;
        left: 10px;
        top: 10px;
        height: 12px;
        border-left: solid black 2px;
        border-right: solid black 2px;
        border-bottom: solid black 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        color: black;
      }

      .image-loading {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

    }
  }

  .properties {
    flex: 0;
    flex-basis: 150px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
}
</style>