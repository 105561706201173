import { throttle } from '@stellacontrol/utilities'

/**
 * Utility class for handling mouse events on the plan
 */
export class MouseEvents {
  /**
   * Initializes the mouse events service
   * @param {PlanRenderer} renderer
   */
  constructor (renderer) {
    this.__renderer = renderer
  }

  __mouseWheelHandler
  __mouseMoveHandler
  __renderer

  /**
   * Binds keyboard event handlers
   */
  bindEvents () {
    const { __renderer: renderer } = this

    // Bind mouse scroll event to document, to handle zooming internally.
    // `passive` needs to be set to `false`, to let the browser know
    // that we're capturing this event entirely if `CTRL` is held,
    // and handling the zoom function internally.
    let isZoomingIn = false

    // Throttle the event, to mitigate the problem of variable zoom speed on some systems.
    // Here we want a simple model - one action = one step, regardless of how much the wheel has been turned.
    const zoomPlan = throttle(e => {
      if (e.deltaY != null && !isZoomingIn) {
        try {
          isZoomingIn = true
          const delta = e.deltaY < 0 ? 0.1 : -0.1
          renderer.zoomBy(delta)
        } finally {
          isZoomingIn = false
        }
      }
    }, 50)

    this.__mouseWheelHandler = async (e) => {
      if (!renderer.isFocused) {
        return
      }

      e.stopPropagation()
      e.preventDefault()
      zoomPlan(e)
    }

    window.addEventListener('wheel', this.__mouseWheelHandler, { passive: false })
  }

  /**
   * Unbinds any global event handlers.
   * Should be called before the class is destroyed
   */
  unmount () {
    window.removeEventListener('wheel', this.__mouseWheelHandler)
    this.__mouseWheelHandler = null
    this.__renderer = null
  }
}
