import { Log } from '@stellacontrol/utilities'
import { ClientRoute } from '../../router/routes'
import { isNonCriticalNavigationFailure, isRouteNotFoundNavigationFailure, getNavigationFailure } from '../../router/navigation-failure'
import { tryDispatch } from '../utilities/dispatch'

/**
 * Client actions
 */
export const actions = {
  /**
   * Initializes the UI router
   * @param router UI router
   * @param routes Routes for the UI router
   */
  initializeRouter ({ commit, dispatch }, { router, routes }) {
    commit('initializeRouter', { router, routes })

    // Just before loading the route,
    // activate the view associated with it
    router.beforeResolve(async (to, from) => {
      // Borrow metadata from registered route.
      // The first time after full reload, `to` still doesn't have metadata :-(
      const route = routes.find(r => r.name === to.name)
      if (!to.meta.view && route) {
        to.meta = { ...to.meta, ...route.meta }
      }
      const { view } = to.meta || {}
      if (view) {
        await dispatch('showView', { view, route: to, from })
      }
    })

    router.beforeEach(async (to, from) => {
      // Notify about route changing
      const { error } = await tryDispatch('navigationStarted', { to, from })
      if (error) {
        return false
      }
    })

    router.afterEach(async (to, from) => {
      // Notify about route change
      await tryDispatch('navigationEnded', { to, from })

      if (from) {
        // Remember the previous route, just in case it's needed
        commit('storePreviousRoute', { route: from })

        // Deactivate the view associated with the left route
        // so cleanup can be performed whenever necessary
        const { view } = from.meta || {}
        if (view) {
          await tryDispatch('leaveView', { view, route: from })
        }
      }
    })
  },

  /**
   * Notifies of route changing
   * @param from Start route
   * @param to Target route
   */
  async navigationStarted ({ commit }, { from, to } = {}) {
    commit('navigationStarted', { from, to })
  },

  /**
   * Notifies of route changed
   * @param {Route} from Start route
   * @param {Route} to Target route
   */
  async navigationEnded ({ commit }, { from, to } = {}) {
    commit('navigationEnded', { from, to })
    commit('hideDialog')
  },

  /**
   * Navigates to the specified route
   * @param name Route to navigate to
   * @param path Alternatively, a path to navigate to
   * @param fail Route to navigate to, if error happens
   * @param params Route parameters
   * @param query Route query parameters
   */
  async gotoRoute ({ state }, { name, path, fail = {}, params = {}, query = {} }) {
    if (!(name || path)) throw new Error('Route name or path is required')
    const { router } = state
    if (!router) throw new Error('Router is not initialized')

    try {
      if (name) {
        // either by name
        await router.push({ name, params, query })
      } else {
        // or by path
        await router.push({ path, params, query })
      }
    } catch (error) {
      // If error, navigate to the fallback route if specified
      if (fail.name || fail.path) {
        if (name) {
          await router.push({ name: fail.name })
        } else {
          await router.push({ path: fail.path })
        }
      } else {
        // If route not found, just go to main route
        if (isRouteNotFoundNavigationFailure(error)) {
          Log.warn(`ROUTER: Route ${name || path} not found`)
          await router.push({ path: '/' })
        } else {
          // Skip those pesky reduntant or cancelled navigation errors, we don't really care
          if (isNonCriticalNavigationFailure(error)) {
            const { name, from, to, type } = getNavigationFailure(error)
            Log.warn(`ROUTER: Navigation ${name || 'redirected'}`, `${from.path} => ${to.path} ${type ? `[${type}]` : ''}`)
          } else {
            throw error
          }
        }
      }
    }
  },

  /**
   * Updates current route with specified params and/or query
   * @param params Route parameters to override
   * @param query Query parameters to override
   */
  async updateRoute ({ getters }, { params: newParams = {}, query: newQuery = {} }) {
    const { router } = getters
    const { currentRoute } = router
    // Add new parameters and query values
    const params = { ...currentRoute.params, ...newParams }
    const query = { ...currentRoute.query, ...newQuery }
    // Remove parameters and query values which were set to null/undefined
    for (const [key, value] of Object.entries(newParams)) {
      if (value == null) {
        delete params[key]
      }
    }
    for (const [key, value] of Object.entries(newQuery)) {
      if (value == null) {
        delete query[key]
      }
    }
    // Update the route
    const updatedRoute = { ...currentRoute, params, query }
    await router.push(updatedRoute)
  },

  /**
   * Assigns data to the route.
   * @param from Source route
   * @param to Target route
   * @param title Application title associated with the route. Used to differentiate window title per route.
   * @param data Data to store on the route
   */
  async setRouteData ({ commit, getters }, { from, to, title, data }) {
    if (from && to) {
      // If view aliases are present, set view associated with the route
      // depending on where we came from to the route
      const { viewAlias } = to.meta
      const viewName = viewAlias
        ? (viewAlias[from.name] || viewAlias['default'] || to.meta.defaultViewName)
        : to.meta.defaultViewName || to.name
      const view = getters.getView(viewName)
      if (!view) {
        throw new Error(`View ${viewName} associated with route ${to.name} is not defined`)
      }

      commit('setRouteData', {
        from,
        to,
        title,
        data,
        view
      })

      // Put the data on route meta as well, comes handy whenever
      // all we have at our disposal is route, but no state store
      to.meta.title = title
      to.meta.data = data
      to.meta.view = view
      to.meta.viewAlias = viewAlias

    }
  },

  /**
   * Navigates to the previous route.
   * If previous route is login page, navigates
   * to the specified fallback route instead.
   */
  async goBack ({ getters, dispatch }, { fallback } = {}) {
    const { router, previousRoute } = getters
    if (router) {
      if (previousRoute.name === 'login') {
        if (fallback) {
          await dispatch('gotoRoute', fallback)
        } else {
          router.push('/')
        }
      } else {
        if (window.history.length > 1) {
          router.back()
        } else {
          router.push('/')
        }
      }
    }
  },

  /**
   * Navigates to the specified URL
   * @param url Url to navigate to
   */
  gotoUrl (_, { url }) {
    if (!url) throw new Error('URL is required')
    window.location = url
  },

  /**
   * Navigates to blank URL
   */
  gotoBlank () {
    window.location = 'about:blank'
  },

  /**
   * Navigates to home page
   */
  gotoHome ({ dispatch }) {
    return dispatch('gotoRoute', { name: ClientRoute.Home })
  },

  /**
   * Navigates to help page
   */
  gotoHelp ({ dispatch }) {
    return dispatch('gotoRoute', { name: ClientRoute.Help })
  },

  /**
   * Navigates to Loading page
   */
  gotoLoading ({ dispatch }) {
    return dispatch('gotoRoute', { name: ClientRoute.Loading })
  },

  /**
   * Navigates to Login page
   */
  gotoLogin ({ dispatch }) {
    return dispatch('gotoRoute', { name: ClientRoute.Login })
  },

  /**
   * Navigates to Error page
   */
  gotoError ({ dispatch }) {
    return dispatch('gotoRoute', { name: ClientRoute.Error })
  },

  /**
   * Navigates to Empty page
   */
  gotoEmpty ({ dispatch }) {
    return dispatch('gotoRoute', { name: ClientRoute.Empty })
  },

  /**
   * Navigates to WIP page
   */
  gotoWIP ({ dispatch }) {
    return dispatch('gotoRoute', { name: ClientRoute.WIP })
  },

  /**
   * Navigates to Not Authorized page
   */
  gotoNotAuthorized ({ dispatch }) {
    return dispatch('gotoRoute', { name: ClientRoute.NotAuthorized })
  },

  /**
   * Reloads current route
   */
  reloadRoute ({ getters }) {
    const { router } = getters
    if (router) {
      router.go(router.currentRoute)
    }
  }
}
