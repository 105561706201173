<script>
import { mapActions, mapGetters } from 'vuex'
import { Log, countString, createDataUrl } from '@stellacontrol/utilities'
import { FormMixin, DialogMixin } from '@stellacontrol/client-utilities'
import { Attachment } from '@stellacontrol/model'
import StepSelectFiles from './step-select-files.vue'
import StepUploadFiles from './step-upload-files.vue'
import StepEditImages from './step-edit-images.vue'
import StepFinish from './step-finish.vue'

const dialog = 'plan-import'

const Steps = {
  SelectImages: 1,
  UploadImages: 2,
  EditImages: 3,
  Finish: 4
}

export default {
  mixins: [
    FormMixin,
    DialogMixin
  ],

  components: {
    'sc-step-select-files': StepSelectFiles,
    'sc-step-upload-files': StepUploadFiles,
    'sc-step-edit-images': StepEditImages,
    'sc-step-finish': StepFinish,
  },

  data () {
    return {
      dialog,
      Steps,
      // Use when in development, to skip uploading of the images to S3
      development: true,
      // Edited plan
      plan: null,
      // Indicates that the dialog has been closed by the user.
      // This will break any ongoing uploads.
      isClosed: false,
      // Current step in the wizard
      step: Steps.SelectImages,
      // Selected files
      files: [],
      // Uploaded images
      images: []
    }
  },

  computed: {
    ...mapGetters([
      'isDevelopmentEnvironment',
      'isApplet'
    ]),

    // Indicates we're in the playground applet
    isPlayground () {
      return this.isDevelopmentEnvironment && this.isApplet
    },

    // Validates the input
    isValid () {
      return true
    },

    // Checks whether we're at the first step
    isFirstStep () {
      return this.step === Steps.SelectImages
    },

    // Checks whether we're at the last step
    isLastStep () {
      return this.step === Steps.Finish
    },

    // Label for the current step
    labelStep () {
      const labels = {
        [Steps.SelectImages]: 'Select Images',
        [Steps.UploadImages]: 'Uploading Images ...',
        [Steps.EditImages]: 'Edit Image',
        [Steps.Finish]: 'Finish'
      }
      return labels[this.step]
    },

    // Label for NEXT button
    labelNext () {
      const labels = {
        [Steps.SelectImages]: 'Next',
        [Steps.UploadImages]: 'Next',
        [Steps.EditImages]: 'Next',
        [Steps.Finish]: 'Finish'
      }
      return labels[this.step]
    },

    // Label for BACK button
    labelBack () {
      return 'Back'
    },

    // Indicates whether any files have been selected
    hasFiles () {
      return this.files.length > 0
    },

    // Check whether we can step back
    canGoBack () {
      return !this.isFirstStep && this.step !== Steps.UploadImages
    },

    // Check whether we can move forward
    canGoForward () {
      return this.hasFiles && this.step !== Steps.UploadImages
    },

    // Check whether we can reset
    canReset () {
      return this.hasFiles ||
        (this.step !== Steps.SelectImages && this.step !== Steps.UploadImages)
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      // Pass on initial data
      this.reset()
      const { data: { plan } } = this
      this.plan = plan
    },

    // Called when dialog is hidden
    dialogHidden () {
      this.reset()
      this.isClosed = true
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
      this.reset()
    },

    // Resets the dialog
    reset () {
      // Dispose of data URLs
      for (const file in this.files) {
        URL.revokeObjectURL(file.imageUrl)
      }

      for (const image in this.images) {
        URL.revokeObjectURL(image.reference)
      }

      this.isClosed = false
      this.files = []
      this.images = []
      this.step = Steps.SelectImages
    },

    // Proceeds to the next step
    async goForward () {
      if (this.isLastStep) {
        this.finish()
      } else {
        const index = this.step + 1
        const step = Object.entries(Steps).find(entry => entry[1] === index)
        this.step = step ? step[1] : this.step
      }
    },

    // Proceeds to the previous step
    async goBack () {
      const index = this.step - 1
      const step = Object.entries(Steps).find(entry => entry[1] === index)
      this.step = step ? step[1] : this.step

      // Cannot re-upload, has to go back to SELECT!
      if (this.step === Steps.UploadImages) {
        await this.goBack()
      }
    },

    // Triggered when files to upload have been selected
    onFilesSelected (files = []) {
      this.files = [...files]
      Log.debug(`[${this.plan.name}] ${countString(this.files, 'file')} selected`, this.files)
    },

    // Triggered when files have been uploaded
    onFilesUploaded (images = []) {
      this.images = [...images]
      Log.debug(`[${this.plan.name}] ${countString(images, 'image')} uploaded`, images)

      if (this.images.length > 0) {
        // Proceed to editing, once the images were uploaded
        this.goForward()
      } else {
        // Go back to selection if uploading failed
        this.goBack()
      }
    },

    // Triggered when images have been edited
    onImagesEdited (images) {
      Log.debug(`[${this.plan.name}] ${countString(images, 'image')} edited`, images)
      // Go back to start if no images left
      if (images.length === 0) {
        this.reset()
      }
    },

    // Triggered when user presses FINISH button
    finish () {
      const { plan } = this

      // Remove runtime data from the images
      const { isPlayground } = this
      const images = this.images.map(i => {
        // Create a clean image
        const image = new Attachment(i)
        image.description = i.floorName
        image.mapScale = i.mapScale

        // Set the image download URL and data URL
        image.downloadUrl = i.reference
        if (isPlayground && i.content) {
          image.content = createDataUrl(i.content, i.mimeType)
          image.mimeType = 'application/base64'
        }
        return image
      })

      // Close the dialog and return the uploaded images
      const data = { plan, images }
      this.dialogOk({ dialog, data })
      this.reset()

      Log.debug(`[${plan.name}] ${countString(images, 'image')} ready`, images)
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" persistent @dialogShown="dialogShown()"
    @dialogHidden="dialogHidden()">
    <q-form class="form" ref="form" v-if="plan">
      <header>
        <q-banner class="bg-indigo-6">
          <span class="text-white title">
            {{ plan.name }} - {{ labelStep }}
          </span>
        </q-banner>
      </header>

      <main class="q-pa-lg">
        <!-- IMAGE SELECTION -->
        <section v-if="step === Steps.SelectImages" class="selecting">
          <sc-step-select-files :plan="plan" :files="files" :development="development"
            @selected="files => onFilesSelected(files)"></sc-step-select-files>
        </section>

        <!-- IMAGE UPLOAD -->
        <section v-if="step === Steps.UploadImages" class="uploading">
          <sc-step-upload-files :plan="plan" :files="files" :development="development"
            @uploaded="images => onFilesUploaded(images)"></sc-step-upload-files>
        </section>

        <!-- IMAGE EDITING -->
        <section v-if="step === Steps.EditImages" class="editing">
          <sc-step-edit-images :plan="plan" :images="images" :development="development"
            @changed="images => onImagesEdited(images)"></sc-step-edit-images>
        </section>

        <!-- FINISH -->
        <section v-if="step === Steps.Finish" class="finish">
          <sc-step-finish :plan="plan" :images="images" :development="development"></sc-step-finish>
        </section>

      </main>

      <footer class="q-pa-lg">
        <div class="row">
          <q-btn v-if="canReset" label="Reset" unelevated class="warning" @click="reset()">
            <sc-tooltip>
              Click to start the import again
            </sc-tooltip>
          </q-btn>
          <q-space></q-space>
          <q-btn label="Cancel" unelevated class="q-mr-md" @click="cancel()" />
          <q-btn :label="labelBack" :disabled="!canGoBack" class="primary q-mr-md" unelevated
            icon="arrow_left" @click="goBack()" />
          <q-btn :label="labelNext" unelevated class="primary" :disabled="!canGoForward"
            :icon-right="isLastStep ? 'check' : 'arrow_right'" @click="goForward()" />
        </div>
      </footer>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.title {
  font-size: 16px;
}

.form {
  width: 90vw;
  height: 90vh;
  max-width: 1400px;
  max-height: 1200px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  >header {
    flex: 0;
  }

  >main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    >section {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }

  >footer {
    flex: 0;
  }

}
</style>